import { FC, createContext, useEffect, useRef, useState, Context } from "react";
import { SyncClient, SyncDocument, SyncMap, SyncMapItem } from "twilio-sync";
import { usePlayerContext } from "../../hooks/usePlayerContext/usePlayerContext";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { ReactElementProps } from "../../types";
import { SyncContextType } from "../../types/twilio-sync";
import { DEBUG_LEVEL } from "../../constants";
import { ConferenceSettings } from "../../core/appReducer";
import { Role } from "@mmc/conferencing-booking-client";

export const SyncContext: Context<SyncContextType> = createContext<SyncContextType>(null!);

export const SyncProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const { appDispatch, appState } = useAppState();
	const { player } = usePlayerContext();
	const [viewersMap, setViewersMap] = useState<SyncMap>();
	const [speakersMap, setSpeakersMap] = useState<SyncMap>();
	const [specialMap, setSpecialMap] = useState<SyncMap>();
	const [viewerDocument, setViewerDocument] = useState<SyncDocument>();
	const [streamDocument, setStreamDocument] = useState<SyncDocument>();
	const [conferenceSettingsDocument, setConferenceSettingsDocument] = useState<SyncDocument>();

	const syncClientRef = useRef<SyncClient>();

	const connect = (token: string): void => {
		syncClientRef.current = new SyncClient(token, { loglevel: DEBUG_LEVEL });
	}

	const disconnect = () => {
		if (syncClientRef.current) {
			setViewersMap(undefined);
			setSpeakersMap(undefined);
			setSpecialMap(undefined);
			setViewerDocument(undefined);
			setStreamDocument(undefined);
			setConferenceSettingsDocument(undefined);

			syncClientRef.current.shutdown();
		}
	}

	const registerViewerDocument = async (viewerDocumentName: string) => {
		try {
			const document = await syncClientRef.current!.document(viewerDocumentName);
			setViewerDocument(document);
		} catch (error: any) {
			console.error(error);
		}
	}

	const registerStreamDocument = async (streamDocumentName: string) => {
		try {
			const document = await syncClientRef.current!.document(streamDocumentName);
			setStreamDocument(document);
		} catch (error: any) {
			console.error(error);
		}
	}

	const registerConferenceSettingsDocument = async (conferenceSettingsDocumentName: string) => {
		try {
			const document = await syncClientRef.current!.document(conferenceSettingsDocumentName);
			setConferenceSettingsDocument(document);
		} catch (error: any) {
			console.error(error);
		}
	}

	const registerViewersMap = async (viewersMapName: string) => {
		try {
			const map = await syncClientRef.current!.map(viewersMapName);
			setViewersMap(map);
		} catch (error: any) {
			console.error(error);
		}
	}

	const registerSpeakersMap = async (speakersMapName: string) => {
		try {
			const map = await syncClientRef.current!.map(speakersMapName);
			setSpeakersMap(map);
		} catch (error: any) {
			console.error(error);
		}
	}

	const registerSpecialMap = async (specialMapName: string) => {
		try {
			const map = await syncClientRef.current!.map(specialMapName);
			setSpecialMap(map);
		} catch (error: any) {
			console.error(error);
		}
	}

	const updateViewersMap = async (item: SyncMapItem, sortOrder: number) => {
		if (viewersMap) {
			await viewersMap.update(item.key, { ...item.data, sortOrder, timestamp: new Date().getTime() });
		}
	}

	useEffect(() => {
		// Set Stream Status
		if (streamDocument && appState.participantType !== Role.Viewer) {
			const handleUpdate = (update: any) => {
				if (typeof update.data.streamStatus !== "undefined") {
					appDispatch({ type: "set-is-stream-streaming", isStreamStreaming: update.data.streamStatus });
				}

				if (typeof update.data.openStatus !== "undefined") {
					appDispatch({ type: "set-is-room-open", isRoomOpen: update.data.openStatus });
				}
			};

			const current: any = streamDocument.data;

			appDispatch({ type: "set-is-stream-streaming", isStreamStreaming: current.streamStatus });
			appDispatch({ type: "set-is-room-open", isRoomOpen: current.openStatus });

			streamDocument.on("updated", handleUpdate);

			return () => {
				streamDocument.off("updated", handleUpdate);
			};
		}
	}, [streamDocument, appState.participantType, appDispatch]);

	useEffect(() => {
		// The user can only accept a speaker invite when they are a viewer (when there is a player)
		if (viewerDocument && player) {
			const handleUpdate = (update: any) => {
				console.log("Speaker Invite Recieved", update);
				if (typeof update.data.speakerInvite !== "undefined") {
					appDispatch({ type: "set-has-speaker-invite", hasSpeakerInvite: update.data.speakerInvite });
				}
			};

			viewerDocument.on("updated", handleUpdate);
			return () => {
				viewerDocument.off("updated", handleUpdate);
			};
		}
	}, [viewerDocument, player, appDispatch]);

	useEffect(() => {
		if (!conferenceSettingsDocument) {
			return;
		}

		const handleUpdate = (update: any) => {
			appDispatch({ type: "set-conference-settings", conferenceSettings: update.data as ConferenceSettings });
		};

		handleUpdate(conferenceSettingsDocument);

		conferenceSettingsDocument.on("updated", handleUpdate);

		return () => {
			conferenceSettingsDocument.off("updated", handleUpdate);
		};
	}, [conferenceSettingsDocument, appDispatch]);

	return (
		<SyncContext.Provider value={{
			connect,
			disconnect,
			updateViewersMap,
			registerViewerDocument,
			viewersMap,
			registerViewersMap,
			registerStreamDocument,
			registerConferenceSettingsDocument,
			conferenceSettingsDocument,
			registerSpeakersMap,
			speakersMap,
			registerSpecialMap,
			specialMap
		}}>
			{children}
		</SyncContext.Provider>
	);
};
