import { styled } from "@twilio-paste/core/styling-library";

export const RemoteParticipant = styled("div")`
    border-radius: 16px;
    display: inline-flex;
    align-items: center;
    padding: 0.5em 0.8em 0.6em;
    margin: 0.3em 0 0;
    word-break: break-word;
    background-color: #E1E3EA;
    hyphens: auto;
    white-space: pre-wrap;
`;

export const LocalParticipant = styled("div")`
    border-radius: 16px;
    display: inline-flex;
    align-items: center;
    padding: 0.5em 0.8em 0.6em;
    margin: 0.3em 0 0;
    word-break: break-word;
    background-color: #CCE4FF;
    hyphens: auto;
    white-space: pre-wrap;
`;