import { FC } from "react";
import { Button } from "@twilio-paste/button";
import { MicrophoneOffIcon } from "@twilio-paste/icons/esm/MicrophoneOffIcon";
import { MicrophoneOnIcon } from "@twilio-paste/icons/esm/MicrophoneOnIcon";
import { useLocalAudioToggle } from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { ButtonProps } from "../../../types";
import { useTranslation } from "react-i18next";

export const ToggleAudioButton: FC<ButtonProps> = (props: ButtonProps) => {
	const { t } = useTranslation();
	const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
	const { localTracks } = useVideoContext();
	const hasAudioTrack = localTracks.some(track => track.kind === "audio");

	return (
		<Button
			variant={props.menuButton ? "reset" : "secondary"}
			onClick={toggleAudioEnabled}
			disabled={!hasAudioTrack || props.disabled}
			fullWidth
			role="alert"
			aria-live="polite"
		>
			{isAudioEnabled ? <MicrophoneOnIcon decorative={true} /> : <MicrophoneOffIcon decorative={true}/>} {!hasAudioTrack ? "No Audio" : isAudioEnabled ? t("conference-ui-bottom-bar.mute-button") : t("conference-ui-bottom-bar.unmute-button")}
		</Button>
	);
}
