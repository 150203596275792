import { useContext } from "react";
import { PhoneNumberProviderContext } from "../../components/PhoneNumberProvider/PhoneNumberProvider";
import { PhoneNumberProviderContextType } from "../../types/ciptex-sdk";

export const usePhoneNumberContext = (): PhoneNumberProviderContextType => {
	const context = useContext(PhoneNumberProviderContext);
	if (!context) {
		throw new Error("usePhoneNumberContext must be used within a PhoneNumberProvider");
	}
	return context;
}