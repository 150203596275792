import { Spinner } from "@twilio-paste/spinner";
import { Box } from "@twilio-paste/box"
import { Text } from "@twilio-paste/core/text";
import { appStateTypes } from "../../../core/appReducer";
import { FC } from "react";
import { Flex } from "@twilio-paste/core/flex";
import { useTranslation } from "react-i18next";
import { Role } from "@mmc/conferencing-booking-client";

interface LoadingScreenProps {
	state: appStateTypes
}

export const LoadingScreen: FC<LoadingScreenProps> = ({ state }: LoadingScreenProps) => {
	const { t } = useTranslation();

	return (
		<Box position="relative" display="flex" flexDirection="column" flex={1} minHeight="300px">
			<Flex hAlignContent="center" vAlignContent="center" height="300px">
				<Spinner size="sizeIcon50" decorative={false} title={t("general.loading")} />
				<Text role="alert" aria-live="polite" as="h1" fontSize="fontSize80" marginLeft="space30">
					{(state.participantType === Role.Admin || state.participantType === Role.Host) ?
						t("choose-your-adventure-page.loading-live")
						:
						t("choose-your-adventure-page.loading-joining")}
				</Text>
			</Flex>
		</Box>
	);
}
