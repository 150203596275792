import { FC } from "react";

export const NoneIcon: FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73">
			<g fill="none" fillRule="evenodd">
				<circle cx="36.5" cy="36.5" r="36.5" fill="#FFF" />
				<path
					fill="#282A2B"
					d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"
				/>
			</g>
		</svg>
	);
}
