import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Button } from "@twilio-paste/button";
import { Input } from "@twilio-paste/input";
import { Label } from "@twilio-paste/label";
import { HelpText } from "@twilio-paste/help-text";
import { Heading } from "@twilio-paste/heading";
import { Box } from "@twilio-paste/box";
import { IntroContainer } from "../IntroContainer/IntroContainer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ACCOUNT_SID, ADMIN_URL, REGISTRATION_URL } from "../../constants";
import { Alert } from "@twilio-paste/core/alert";
import { Anchor } from "@twilio-paste/anchor";
import { Text } from "@twilio-paste/core/text"
import { NotifiedUser } from "../../types";
import { Buffer } from "buffer";
import { usePhoneNumberContext } from "../../hooks/usePhoneNumberContext/usePhoneNumberContext";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { Loading } from "../Loading/Loading";
import { Flex } from "@twilio-paste/core";
import { AuthError } from "../../hooks/useCiptexAuth/useCiptexAuth";
import { useSanitizedId } from "../../hooks/useSanitizedId/useSanitizedId";

export const LoginPage: FC = () => {
	const { authHost, authViewer, authNotified, user, host, notified, appDispatch } = useAppState();
	const history = useNavigate();
	const { connect: phoneNumberConnect } = usePhoneNumberContext();
	const { connect: notifiedConnect } = useBookingContext();
	const { participantId, conferenceId, bookingId } = useParams();
	const [searchParams] = useSearchParams();
	const [passcode, setPasscode] = useState("");
	const [authError, setAuthError] = useState<AuthError|false>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const authViewerFunction = async (pwd: string) => {
		if (pwd) {
			try {
				await authViewer(pwd);
			} catch (error: any) {
				console.error(error);
				setAuthError(error.message);
			}
		}
		else {
			setAuthError(AuthError.PasscodeMissing);
			console.error("No Room!");
		}
	}

	const authNotifiedFunction = async (notifiedId: string, token: string) => {
		try {
			if (token && notifiedId) {
				const [ jwt, data ] = token.split(":");
				const b64decode = Buffer.from(data, "base64").toString();
				const urlParams = new URLSearchParams(b64decode);
				const params: NotifiedUser = Object.fromEntries(urlParams) as unknown as NotifiedUser;

				await authNotified(notifiedId, jwt, params);
			}
			else {
				window.location.replace(ADMIN_URL);
			}
		} catch (error: any) {
			console.error(error);
			window.location.replace(ADMIN_URL);
		}
	}

	const authHostFunction = async (hostId: string, token: string) => {
		try {
			if (token && hostId) {
				await authHost(hostId, token);
			}
			else {
				window.location.replace(ADMIN_URL);
			}
		} catch (error: any) {
			window.location.replace(ADMIN_URL);
		}
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		setAuthError(false);
		await authViewerFunction(passcode);
		setLoading(false);
	};

	// VIEWER QUERY PASSCODE AUTHENTICATION
	useEffect(() => {
		(async () => {
			const pwd = searchParams.get("pwd");
			if (pwd && pwd !== null && participantId) {
				await authViewerFunction(pwd);
			}
		})();
		// TODO: Fix linting - authViewerFunction() should be wrapped in useCallback and added to the list of dependencies, or other solution should be found to fix the linter issue
		// eslint-disable-next-line react-hooks/exhaustive-deps -- to include authViewerFunction() in the list of dependencies first it should be wrapped in useCallback
	}, [searchParams, participantId]);

	// HOST / SPEAKER AUTH WITH TOKEN IN QUERYSTRING
	useEffect(() => {
		(async () => {
			const token = searchParams.get("t");
			const hostId = searchParams.get("h");

			if (!token || (token && token == null)) {
				return;
			}

			const isHost = hostId && hostId.startsWith("HI");
			const isExternalHost = hostId && hostId.startsWith("XI");

			if (isHost) {
				await authHostFunction(hostId, token);
			} else if (isExternalHost || hostId !== null) {
				await authNotifiedFunction(hostId, token);
			}
		})();
		// TODO: Fix linting - authNotifiedFunction() and authHostFunction() should be wrapped in useCallback and added to the list of dependencies, or other solution should be found to fix the linter issue
		// eslint-disable-next-line react-hooks/exhaustive-deps -- to include authNotifiedFunction(), authHostFunction() in the list of dependencies first it should be wrapped in useCallback
	}, [searchParams, conferenceId]);

	useEffect(() => {
		if (user && user.role && user.identity && user.token) {
			history(searchParams.get("redirect") || "/conference", { replace: true });
			phoneNumberConnect(user.token);
			notifiedConnect(user.token);
			appDispatch({ type: "set-verify-token", verifyToken: user.token });
			appDispatch({ type: "set-account-sid", accountSid: user.accountSid });
			appDispatch({ type: "set-booking-id", bookingId: user.bookingId });
			appDispatch({ type: "set-conference-id", conferenceId: user.conferenceId });
			appDispatch({ type: "set-participant-id", participantId: user.participantId });
			appDispatch({ type: "set-participant-type", participantType: user.role });
			appDispatch({ type: "set-participant-pin", participantPin: user.pwd });
			appDispatch({ type: "set-participant-identity", participantIdentity: user.identity });
		}
	}, [searchParams, user, appDispatch, history, phoneNumberConnect, notifiedConnect]);

	useEffect(() => {
		if (host && host.role && host.firstName && host.lastName && host.token && bookingId && conferenceId) {
			history(searchParams.get("redirect") || "/conference", { replace: true });
			phoneNumberConnect(host.token);
			notifiedConnect(host.token);
			appDispatch({ type: "set-verify-token", verifyToken: host.token });
			appDispatch({ type: "set-account-sid", accountSid: host.accountSid });
			appDispatch({ type: "set-booking-id", bookingId: bookingId });
			appDispatch({ type: "set-conference-id", conferenceId: conferenceId });
			appDispatch({ type: "set-participant-id", participantId: host.hostId });
			appDispatch({ type: "set-participant-type", participantType: host.role });
			appDispatch({ type: "set-participant-identity", participantIdentity: `${host.firstName} ${host.lastName}` });
		}
	}, [searchParams, conferenceId, bookingId, host, appDispatch, history, phoneNumberConnect, notifiedConnect]);

	useEffect(() => {
		if (notified && notified.role && notified.firstName && notified.lastName && notified.token && bookingId && conferenceId) {
			history(searchParams.get("redirect") || "/conference", { replace: true });
			phoneNumberConnect(notified.token);
			notifiedConnect(notified.token);
			appDispatch({ type: "set-verify-token", verifyToken: notified.token });
			appDispatch({ type: "set-account-sid", accountSid: ACCOUNT_SID });
			appDispatch({ type: "set-booking-id", bookingId: bookingId });
			appDispatch({ type: "set-conference-id", conferenceId: conferenceId });
			appDispatch({ type: "set-participant-id", participantId: notified.notifiedId });
			appDispatch({ type: "set-participant-type", participantType: notified.role });
			appDispatch({ type: "set-participant-identity", participantIdentity: `${notified.firstName} ${notified.lastName}` });
		}
	}, [searchParams, conferenceId, bookingId, notified, appDispatch, history, phoneNumberConnect, notifiedConnect]);

	useEffect(() => {
		if (user) {
			history("/conference", { replace: true });
		}
	}, [history, user]);

	const hasPasscodeError = authError && (authError === AuthError.PasscodeIncorrect || authError === AuthError.PasscodeMissing || authError === AuthError.PasscodeExpired);
	const showPinEntryForm = (user || bookingId) && (!conferenceId || hasPasscodeError);
	const showConferenceFinishedError = authError && authError === AuthError.ConferenceFinished;
	const sanitizedBookingId = useSanitizedId(bookingId, "booking");

	return (
		<IntroContainer>
			{showPinEntryForm ?
				<>
					{hasPasscodeError && <Alert variant="error" onDismiss={() => setAuthError(false)}>
						{bookingId && sanitizedBookingId && <Text as="span"><Text as="strong">Incorrect Dial-in PIN</Text> <Anchor href={`${REGISTRATION_URL}/register/${sanitizedBookingId}`}>You can re-register here</Anchor></Text>}
						{!bookingId && <Text as="strong">Incorrect Dial-in PIN</Text>}
					</Alert>}

					<form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", minHeight: "300px", justifyContent: "center" }}>
						<Box position="relative" display="flex" flexDirection="column" >
							<Heading as="h1" variant="heading10">
								Enter Dial-in PIN
							</Heading>
							<Box display="flex" flex={1} flexDirection="column">
								<Label htmlFor="input-passcode" required>Dial-in PIN</Label>
								<Input
									aria-describedby="email_help_text"
									id="input-passcode"
									name="input-passcode"
									onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
									type="password"
									placeholder="123456"
									required
									minLength={6}
									maxLength={10}
									value={passcode}
								/>
								<HelpText id="email_help_text">Enter your Dial-in PIN</HelpText>
							</Box>
							{bookingId && sanitizedBookingId && <Box display="flex" flexDirection="row" flex={1} marginY="space80">
								<Anchor target="_top" href={`${REGISTRATION_URL}/register/${sanitizedBookingId}`}>Not registered or do not remember your Dial-in PIN?</Anchor>
							</Box>}
							<Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end" alignItems="flex-end" marginTop="space60">
								<Button variant="primary" type="submit" loading={loading}>Submit</Button>
							</Box>
						</Box>
					</form>
				</>
				: showConferenceFinishedError ? <Alert variant="error">
						<Text as="strong">Could not find the specified event. The event may have already finished.</Text>
					</Alert>
					:
					<Flex vAlignContent="center" hAlignContent="center">
						<Loading />
					</Flex>}
		</IntroContainer>
	);
}
