import { Box } from "@twilio-paste/core/box";
import { FC } from "react";
import { PopOverMaker } from "./PopOverMaker";
import { useTranslation } from "react-i18next";
import { Booking } from "@mmc/conferencing-booking-client";

interface AccountType {
  booking: Booking | undefined;
}

export const AccountInfo: FC<AccountType> = ({ booking }) => {
	const { t } = useTranslation();

	return (
		<Box as="section" aria-labelledby="account-info-heading">
			<Box
				display="flex"
				flexDirection="row"
				flexWrap="wrap"
				flex="9"
				columnGap="space30"
				rowGap="space30"
				alignItems="center"
				role="region"
				aria-label={t("conference-ui.conf-tab.account")}
			>
				{booking?.account?.id && (
					<PopOverMaker
						title={booking?.account?.id}
						description={t("conference-ui.conf-tab.account-id-pop")}
					/>
				)}
				{booking?.account?.name && (
					<PopOverMaker
						title={booking?.account?.name}
						description={t("conference-ui.conf-tab.account-name-pop")}
					/>
				)}
				{booking?.account?.region && (
					<PopOverMaker
						title={booking?.account?.region}
						description={t("conference-ui.conf-tab.account-region-pop")}
					/>
				)}
				{booking?.account?.type && (
					<PopOverMaker
						title={booking?.account?.type}
						description={t("conference-ui.conf-tab.account-type-pop")}
					/>
				)}
				{booking?.account?.resellerName && (
					<PopOverMaker
						title={booking?.account?.resellerName}
						description={t("conference-ui.conf-tab.account-reseller-pop")}
					/>
				)}
			</Box>
		</Box>
	);
};