import { Box } from "@twilio-paste/core/dist/box";
import { FC, useEffect, useRef, useState } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { LeftPanel } from "../LeftPanel/LeftPanel";
import { RightPanel } from "../RightPanel/RightPanel";
import { SplitterContainer } from "../SplitterContainer/SplitterContainer";
import { Room as IRoom, TwilioError } from "twilio-video";
import { usePlayerContext } from "../../hooks/usePlayerContext/usePlayerContext";
import { useConferenceControlContext } from "../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useSyncContext } from "../../hooks/useSyncContext/useSyncContext";
import { ConferenceMenuBar } from "../LeftPanel/ConferenceMenuBar/ConferenceMenuBar";
import { LogoContainer } from "./LogoContainer.styles";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { Switch } from "@twilio-paste/core";
import { useVideoWindow } from "../../hooks/useVideoWindow/useVideoWindow";
import { ConferenceAlert } from "../LeftPanel/ConferenceAlert/ConferenceAlert";
import { useWindowSize } from "../../hooks/useWindowSize/useWindowSize";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { useTranslation } from "react-i18next";
import { Role } from "@mmc/conferencing-booking-client";

export const Room: FC = () => {
	const { t } = useTranslation();
	const { appState, appDispatch } = useAppState();
	const { room } = useVideoContext();
	const setPreventAutomaticJoinStreamAsViewerRef = useRef(false);
	const { connect: playerConnect } = usePlayerContext();
	const { switchToViewer } = useConferenceControlContext();
	const { connect: syncConnect, registerViewerDocument } = useSyncContext();
	const { booking } = useBookingContext();
	const { setVideoWindowVisible, videoWindowVisible } = useVideoWindow();
	const [roomContainerWidth, setRoomContainerWidth] = useState("1000px");
	const { width } = useWindowSize();
	const { toaster } = useToasterContext();
	const [toastTrack, setToastTrack] = useState(false);

	const WINDOW_SIZE_WARNING_TOAST_ID = "windowTracker";
	const MIN_WINDOW_SIZE_AUDIO_ONLY = 900;
	const MIN_WINDOW_SIZE_WITH_VIDEO = 1400;

	/**
	 * Here we listen for a custom event "setPreventAutomaticJoinStreamAsViewer" which is emitted
	 * whenever a speaker clicks on the "Leave Event" button or the "Leave and View Event" button.
	 * This is needed because the speaker can also leave the event if they are removed by the host.
	 * All of these scenarios result in identical events ("disconnected"), so we needed a way to
	 * prevent speakers from automatically re-joining the stream as a viewer whenever they click on
	 * the "Leave Event" button.
	 */

	useEffect(() => {
		if (room) {
			const handleSetPreventAutomaticJoinStreamAsViewer = () => (setPreventAutomaticJoinStreamAsViewerRef.current = true);
			room.on("setPreventAutomaticJoinStreamAsViewer", handleSetPreventAutomaticJoinStreamAsViewer);

			return () => {
				room.off("setPreventAutomaticJoinStreamAsViewer", handleSetPreventAutomaticJoinStreamAsViewer);
			};
		}
	}, [room]);


	useEffect(() => {
		if (room) {
			setPreventAutomaticJoinStreamAsViewerRef.current = false;

			const handleConnectToPlayer = async (_: IRoom, error: TwilioError) => {
				if (!error && !setPreventAutomaticJoinStreamAsViewerRef.current) {
					appDispatch({ type: "set-is-loading", isLoading: true });
					try {
						const { token } = await switchToViewer();
						await playerConnect(token);
						syncConnect(token);
						registerViewerDocument(`user-${appState.participantId}`);

						appDispatch({ type: "set-is-loading", isLoading: false });
					} catch (err: any) {
						console.log(`Error moving to stream: ${err.message}`, err);
						appDispatch({ type: "set-is-loading", isLoading: false });
					}
				}
			};
			room.on("disconnected", handleConnectToPlayer);

			return () => {
				room.off("disconnected", handleConnectToPlayer);
			};
		}
	}, [
		room,
		playerConnect,
		registerViewerDocument,
		appDispatch,
		appState.participantIdentity,
		appState.participantId,
		switchToViewer,
		syncConnect
	]);

	// Code below is used to track the window size and display a warning if the window is too small and the user has video panel open or not

	useEffect(() => {
		videoWindowVisible ? setRoomContainerWidth("1200px") : setRoomContainerWidth("600px");
	}, [videoWindowVisible]);

	useEffect(() => {
		if (videoWindowVisible && width < MIN_WINDOW_SIZE_WITH_VIDEO) {
			setToastTrack(true);
		} else if (!videoWindowVisible && width < MIN_WINDOW_SIZE_AUDIO_ONLY) {
			setToastTrack(true);
		} else {
			setToastTrack(false);
			toaster.pop(WINDOW_SIZE_WARNING_TOAST_ID);
		}
	}, [width, videoWindowVisible, toaster]);

	useEffect(() => {
		if (videoWindowVisible && toastTrack) {
			toaster.push({
				message: t("conference-ui.toasters.window-size-warning", { optimalWindowSize: MIN_WINDOW_SIZE_WITH_VIDEO }),
				variant: "warning",
				id: WINDOW_SIZE_WARNING_TOAST_ID,
				dismissAfter: 5000
			});
		} else if (!videoWindowVisible && toastTrack) {
			toaster.push({
				message: t("conference-ui.toasters.window-size-warning", { optimalWindowSize: MIN_WINDOW_SIZE_WITH_VIDEO }),
				variant: "warning",
				id: WINDOW_SIZE_WARNING_TOAST_ID,
				dismissAfter: 5000
			});
		}
	}, [toastTrack, videoWindowVisible, toaster, t]);

	const shouldShowConferenceStatus = appState.participantType === Role.Admin || appState.participantType === Role.Host || appState.participantType === Role.Speaker;

	return (
		<Box height="calc(100vh - 120px)" minWidth={roomContainerWidth}>
			<Box backgroundColor="colorBackgroundBody" borderBottomColor="colorBorderDecorative10Weaker" borderBottomWidth="borderWidth20" borderBottomStyle="solid" height="60px" display="flex" alignItems="center" justifyContent="space-between" boxShadow="shadowCard" columnGap="space20">
				{booking?.branding?.logoUrl ? <LogoContainer alt="Customer Logo" height="50px" src={`${booking.branding.logoUrl}`} /> : <Box></Box>}
				<Box>
					{shouldShowConferenceStatus && <ConferenceAlert />}
				</Box>
				<Box paddingRight="space40">
					<Switch checked={videoWindowVisible} onChange={() => setVideoWindowVisible(!videoWindowVisible)}>
						{t("conference-ui-top-bar.video-panel-toggle")}
					</Switch>
				</Box>
			</Box>
			<SplitterContainer videoWindowState={videoWindowVisible} >
				<LeftPanel />
				{appState.participantType === Role.Viewer ? <div /> : <RightPanel />}
			</SplitterContainer>

			<Box backgroundColor="colorBackgroundBody" borderTopColor="colorBorderDecorative10Weaker" borderTopWidth="borderWidth20" borderTopStyle="solid" height="60px" boxShadow="shadow">
				<ConferenceMenuBar />
			</Box>
		</Box>
	);
};
