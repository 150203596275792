import { styled } from "@twilio-paste/core/styling-library";

export const OuterContainer = styled("div")`
    width: 2em;
    height: 2em;
    padding: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
`;

export const InnerContainer = styled("div")`
    display: flex;
    align-items: flex-end;
    & div {
        width: 2px;
        margin-right: 1px;
        &:not(:last-child) {
            border-right: none;
        }
    }
`;

