import { useEffect, useState } from "react";
import { LocalAudioTrack, LocalVideoTrack, RemoteAudioTrack, RemoteVideoTrack } from "twilio-video";
import { useAppState } from "../useAppState/useAppState";

type TrackType = LocalAudioTrack | LocalVideoTrack | RemoteAudioTrack | RemoteVideoTrack | undefined;

export const useIsTrackEnabled = (track: TrackType): boolean => {
	const [isEnabled, setIsEnabled] = useState(track ? track.isEnabled : false);
	const { appDispatch } = useAppState();

	useEffect(() => {
		setIsEnabled(track ? track.isEnabled : false);

		if (track) {
			const setEnabled = () => setIsEnabled(true);
			const setDisabled = () => setIsEnabled(false);
			track.on("enabled", setEnabled);
			track.on("disabled", setDisabled);
			return () => {
				track.off("enabled", setEnabled);
				track.off("disabled", setDisabled);
			};
		}
	}, [track]);

	useEffect(() => {
		appDispatch({ type: "set-is-muted", isMuted: !isEnabled });
	}, [isEnabled, appDispatch]);

	return isEnabled;
}
