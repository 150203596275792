import { FC, useCallback, useRef, useState } from "react";

import { useAppState } from "../../../hooks/useAppState/useAppState";
import { useConferenceControlContext } from "../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { Text } from "@twilio-paste/text";
import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { LowerHandIcon } from "@twilio-paste/icons/esm/LowerHandIcon";
import { RaiseHandIcon } from "@twilio-paste/icons/esm/RaiseHandIcon";

interface PlayerMenuBarProps {
	roomName?: string;
	disconnect: () => void;
}

export const PlayerMenuBar: FC<PlayerMenuBarProps> = ({ roomName, disconnect }: PlayerMenuBarProps) => {
	const { appDispatch, signOut } = useAppState();
	const [isHandRaised, setIsHandRaised] = useState(false);
	const lastClickTimeRef = useRef(0);
	const conferenceControl = useConferenceControlContext();

	const handleRaiseHand = useCallback(() => {
		if (Date.now() - lastClickTimeRef.current > 500) {
			lastClickTimeRef.current = Date.now();
			conferenceControl.raiseHand(!isHandRaised);
			setIsHandRaised(!isHandRaised);
		}
	}, [isHandRaised, conferenceControl]);

	return (
		<Box
			backgroundColor="colorBackground"
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="row"
			paddingY="space60"
			position="fixed"
			bottom="0"
			right="0"
			width="100%"
		>
			<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" columnGap="space60">
				<Box>
					<Text as="p">{roomName}</Text>
				</Box>

				<Button variant="secondary" onClick={handleRaiseHand}>
					{isHandRaised ? "Lower Hand" : "Raise Hand"}
					{isHandRaised ? <LowerHandIcon decorative/> : <RaiseHandIcon decorative />}
				</Button>

				<Button
					variant="destructive_secondary"
					onClick={async () => {
						disconnect();
						appDispatch({ type: "reset-state" });
						await signOut();
					}}
				>
          Leave Stream <CloseIcon decorative={true} />
				</Button>
			</Box>
		</Box>
	);
}
