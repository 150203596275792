import { FC } from "react";
import { LocalAudioTrack } from "twilio-video";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { AudioLevelIndicator } from "../AudioLevelIndicator/AudioLevelIndicator";

export const LocalAudioLevelIndicator: FC = () => {
	const { localTracks } = useVideoContext();
	const audioTrack = localTracks.find(track => track.kind === "audio") as LocalAudioTrack;

	return <AudioLevelIndicator audioTrack={audioTrack} />;
}