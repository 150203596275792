import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/box";
import { useConferenceControlContext } from "../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { PlayIcon } from "@twilio-paste/icons/esm/PlayIcon";
import { PauseIcon } from "@twilio-paste/icons/esm/PauseIcon";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { ButtonProps } from "../../../types";
import { Modal, ModalHeader, ModalHeading, ModalBody, Paragraph, ModalFooter, ModalFooterActions } from "@twilio-paste/core";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { useToasterContext } from "../../../hooks/useToasterContext/useToasterContext";
import { useTranslation } from "react-i18next";

export const ToggleStreamButton: FC<ButtonProps> = (props: { menuButton?: boolean }) => {
	const { t } = useTranslation();
	const { appState, appDispatch } = useAppState();
	const [isStreamStreaming, setIsStreamStreaming] = useState(appState.isRoomOpen);
	// const [canStopStream, setCanStopStream] = useState(true);
	const { toggleOpen } = useConferenceControlContext();
	const lastClickTimeRef = useRef(0);
	const [isLoading, setIsLoading] = useState(false);
	const modalHeadingID = useUID();
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);
	const { toaster } = useToasterContext();

	const handleStreamToggle = useCallback(async () => {
		setIsOpen(false);
		if (Date.now() - lastClickTimeRef.current > 500) {
			lastClickTimeRef.current = Date.now();
			try {
				setIsLoading(true);
				await toggleOpen(!appState.isRoomOpen);
			} catch (error) {
				console.error(`Failed ${appState.isRoomOpen ? "stopping" : "starting"} conference`, error);
				setIsLoading(false);

				toaster.push({
					message: appState.isRoomOpen
						? t("conference-ui.toasters.start-conference-error")
						: t("conference-ui.toasters.stop-conference-error"),
					variant: "error",
					dismissAfter: 3500
				});

				if (appState.isRoomOpen) {
					appDispatch({ type: "set-is-room-open", isRoomOpen: false });
					appDispatch({ type: "set-is-stream-streaming", isStreamStreaming: false });
				}
			}

		}
	}, [ appState.isRoomOpen, toggleOpen, appDispatch, toaster, t]);

 	useEffect(() => {
		setIsLoading(false);
		setIsStreamStreaming(appState.isRoomOpen);
	}, [ appState.isRoomOpen ]);

	// This is for disabling the button if there are viewers on stage.
	// useEffect(() => {
	// 	const stageHasPromotedViewer = participants.some(participant => Boolean(participant.identity.match(/(.+)-PI/)));
	// 	const disableStopStreamButton = appState.isRoomOpen && stageHasPromotedViewer;

	// 	// If we're about to disable the stop stream button and the "stop stream" modal was open, close it
	// 	if (canStopStream && isOpen) {
	// 		setIsOpen(false);
	// 	}

	// 	setCanStopStream(!disableStopStreamButton);
	// }, [appState.isRoomOpen, participants.length]);

	return (
		<>
			<Button
				variant={props.menuButton ? (isStreamStreaming ? "destructive_secondary" : "primary") : (isStreamStreaming ? "destructive_secondary" : "primary")}
				onClick={handleOpen}
				loading={isLoading}
				fullWidth
			><Box display="flex" alignItems="center" columnGap="space30">
					{isStreamStreaming ? <PauseIcon decorative={true} /> : <PlayIcon decorative={true} />} {isStreamStreaming ?  t("conference-ui-bottom-bar.stop-conf-button") :  t("conference-ui-bottom-bar.start-conf-button")}
				</Box>
			</Button>
			<Modal ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={handleClose} size="default">
				<ModalHeader>
					<ModalHeading as="h3" id={modalHeadingID}>
						{isStreamStreaming ? t("conference-ui-bottom-bar.stop-conf-button") : t("conference-ui-bottom-bar.start-conf-button")}
					</ModalHeading>
				</ModalHeader>
				<ModalBody>
					<Paragraph>{isStreamStreaming ? t("conference-ui-bottom-bar.stop-conf-modal-text") : t("conference-ui-bottom-bar.start-conf-modal-text")}</Paragraph>
				</ModalBody>
				<ModalFooter>
					<ModalFooterActions>
						<Button variant="secondary" onClick={handleClose}>
							{t("conference-ui-bottom-bar.cancel-button")}
						</Button>
						<Button variant="primary" onClick={handleStreamToggle} >{isStreamStreaming ? t("conference-ui-bottom-bar.stop-conf-button") : t("conference-ui-bottom-bar.start-conf-button")}</Button>
					</ModalFooterActions>
				</ModalFooter>
			</Modal>
		</>
	);
}
