import { FC, useState } from "react";
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from "../../../constants";
import { LocalVideoTrack } from "twilio-video";
import { VideoTrack } from "../../VideoTrack/VideoTrack";
import { useDevices } from "../../../hooks/useDevices/useDevices";
import { useMediaStreamTrack } from "../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { Box } from "@twilio-paste/box";
import { Option, Select } from "@twilio-paste/core/select";
import { Text } from "@twilio-paste/text";

export const VideoInputList:FC = () => {
	const { videoInputDevices } = useDevices();
	const { localTracks } = useVideoContext();

	const localVideoTrack = localTracks.find(track => track.kind === "video") as LocalVideoTrack | undefined;
	const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
	const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
		window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
	);
	const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

	const replaceTrack = (newDeviceId: string): void => {
		// Here we store the device ID in the component state. This is so we can re-render this component display
		// to display the name of the selected device when it is changed while the users camera is off.
		setStoredLocalVideoDeviceId(newDeviceId);
		window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
		localVideoTrack?.restart({
			// eslint-disable-next-line @typescript-eslint/ban-types
			...(DEFAULT_VIDEO_CONSTRAINTS as {}),
			deviceId: { exact: newDeviceId }
		});
	}

	return (
		<div>
			{localVideoTrack && (
				<Box style={{ width:"300px", maxHeight:"200px", margin:"0.5em auto" }}>
					<VideoTrack isLocal track={localVideoTrack} />
				</Box>
			)}
			{videoInputDevices.length > 1 ? (
				<Box >
					<Text as="p" variant="subtitle2" marginBottom="space40" >
            Video Input
					</Text>
					<Select
						onChange={e => replaceTrack(e.target.value as string)}
						value={localVideoInputDeviceId || ""}
					>
						{videoInputDevices.map(device => (
							<Option value={device.deviceId} key={device.deviceId}>
								{device.label}
							</Option>
						))}
					</Select>
				</Box>
			) : (
				<>
					<Text as="p" variant="subtitle2" marginBottom="space40">
            Video Input
					</Text>
					<Text as="p">{localVideoTrack?.mediaStreamTrack.label || "No Local Video"}</Text>
				</>
			)}
		</div>
	);
}
