import { FC } from "react";
import { Box, Label, Select, Option } from "@twilio-paste/core";
import { useTranslation } from "react-i18next";

interface LanguageSelectorProps {
	showLabel?: boolean;
}

const LanguageSelector: FC<LanguageSelectorProps> = ({ showLabel = true }) => {
	const { i18n, t } = useTranslation();

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	return (
		<Box>
			{showLabel &&
			<Label htmlFor="Language" id="Language">
				{t("choose-your-adventure-page.select-language")}
			</Label>}

			<Select
				id="Language"
				onChange={(e) => changeLanguage(e.target.value)}
				name="test"
				value={i18n.language}
			>
				<Option value="en">🇺🇸 English</Option>
				<Option value="es">🇪🇸 Español</Option>
				<Option value="de">🇩🇪 Deutsch</Option>
				<Option value="fr">🇫🇷 Français</Option>
				<Option value="it">🇮🇹 Italiano</Option>
				<Option value="sv">🇸🇪 Svenska</Option>
			</Select>
		</Box>
	);
};

export default LanguageSelector;
