import { FC, useCallback, useRef } from "react";
import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/box";
import { VideoOnIcon } from "@twilio-paste/icons/esm/VideoOnIcon";
import { VideoOffIcon } from "@twilio-paste/icons/esm/VideoOffIcon";
import { useDevices } from "../../../hooks/useDevices/useDevices";
import { useLocalVideoToggle } from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import { ButtonProps } from "../../../types";

export const ToggleVideoButton: FC<ButtonProps> = (props: ButtonProps) => {
	const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
	const lastClickTimeRef = useRef(0);
	const { hasVideoInputDevices } = useDevices();

	const toggleVideo = useCallback(() => {
		if (Date.now() - lastClickTimeRef.current > 500) {
			lastClickTimeRef.current = Date.now();
			toggleVideoEnabled();
		}
	}, [toggleVideoEnabled]);

	return (
		<Button
			variant={props.menuButton ? "reset" : "secondary"}
			onClick={toggleVideo}
			disabled={!hasVideoInputDevices || props.disabled}
			fullWidth
		><Box display="flex" alignItems="center" columnGap="space30">
				{isVideoEnabled ? <VideoOnIcon decorative={true} /> : <VideoOffIcon decorative={true} />} {!hasVideoInputDevices ? "No Video" : isVideoEnabled ? "Stop Video" : "Start Video"}
			</Box>
		</Button>
	);
}
