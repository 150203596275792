import { Box } from "@twilio-paste/box";
import { FC, useEffect, useState } from "react";
import { Button } from "@twilio-paste/button";
import { useConferencePlaybackContext } from "../../../hooks/useConferencePlaybackContext/ConferencePlaybackContext";
import { Table, THead, Tr, Th, TBody, Td } from "@twilio-paste/core/table";
import { PlayIcon } from "@twilio-paste/icons/esm/PlayIcon";
import { ProcessNeutralIcon } from "@twilio-paste/icons/esm/ProcessNeutralIcon";
import { Text } from "@twilio-paste/core/text";
import { Asset } from "@mmc/conferencing-booking-client";
import { useBookingContext } from "../../../hooks/useBookingContext/useBookingContext";
import { useUID } from "@twilio-paste/core/dist/uid-library";
// import { secondsToTimestamp } from "../../../core/utils";
import { useConferenceControlContext } from "../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useTranslation } from "react-i18next";

export const MediaPlayer: FC = () => {
	const { t } = useTranslation();
	const { conference } = useBookingContext();
	const uid = useUID();
	const { playUrl, stop } = useConferencePlaybackContext();
	const [currentlyPlaying, setCurrentlyPlaying] = useState<string>("");
	// const [duration, setDuration] = useState<any>(0);
	// Hooks updating the playback bot state and playback timer.
	// const { playbackTimer, setPlaybackTimer, isPlaybackBotPlaying, setIsPlaybackBotPlaying } = useConferenceControlContext();
	const { isPlaybackBotPlaying } = useConferenceControlContext();

	// const getAudioDuration = (url: string) => {
	// 	const audio = new Audio(url);
	// 	audio.addEventListener("loadedmetadata", () => {
	// 		setDuration((Math.floor(audio.duration)));
	// 	});
	// }

	const handleStop = () => {
		stop();
		setCurrentlyPlaying("");
		// Functions to update the playback bot state and playback timer.
		// setIsPlaybackBotPlaying(false);
		// setPlaybackTimer(0);
		// setDuration(0);
	}

	const handlePlayUrl = ({ url, title }: Asset) => {
		playUrl(url);
		setCurrentlyPlaying(title);
		// Functions to update the playback bot state and playback timer.
		// setIsPlaybackBotPlaying(true);
		// setPlaybackTimer(0);
		// getAudioDuration(url);
	}

	// const handleLoopUrl = ({ url, title }: Asset) => {
	// 	playUrl(url, true);
	// 	setCurrentlyPlaying(title);
	// 	// Functions to update the playback bot state and playback timer.
	// 	// setPlaybackTimer(0);
	// 	// setIsPlaybackBotPlaying(true);
	// 	// getAudioDuration(url);
	// }

	useEffect(() => {
		if (!isPlaybackBotPlaying) {
			setCurrentlyPlaying("");
		}
	}, [isPlaybackBotPlaying]);


	// This useEffect is used to reset the playback bot state and playback timer when the playback bot is stopped.
	// useEffect(() => {
	// 	if (!isPlaybackBotPlaying) {
	// 		setDuration(0);
	// 		setCurrentlyPlaying("");
	// 		setIsPlaybackBotPlaying(false);
	// 		setPlaybackTimer(0);
	// 	}
	// }, [isPlaybackBotPlaying]);

	// const canShowDuration = Boolean(duration && duration !== Infinity);

	return (
		conference ? <Box>
			<Table tableLayout="auto" variant="default" striped>
				<THead>
					<Tr verticalAlign="middle">
						<Th textAlign="left">{t("conference-ui.pre-records-tab.table-head-title")}</Th>
						<Th textAlign="right">{t("conference-ui.pre-records-tab.table-head-actions")}</Th>
					</Tr>
				</THead>
				<TBody>
					{conference.assets?.map((asset: Asset, index: number) => (
						<Tr key={`${uid}-${index}`}>
							<Td>{asset.title}</Td>
							<Td textAlign="right">
								<Box display="flex" flexDirection="row" columnGap="space30" justifyContent="flex-end">
									{/* <Button variant="secondary" data-cy-playback-loop size="icon_small" onClick={() => handleLoopUrl(asset)} >
										<LoadingIcon decorative={false} title="Play looped audio" size="sizeIcon60" />
									</Button> */}
									<Button variant="primary" data-cy-playback-single size="icon_small" onClick={() => handlePlayUrl(asset)} >
										<PlayIcon decorative={false} title={t("conference-ui.pre-records-tab.play-audio")} size="sizeIcon60" />
									</Button>
								</Box>
							</Td>
						</Tr>
					)) || <Box></Box>}
				</TBody>
			</Table>
			<Box marginY="space30" display="flex" justifyContent="space-between" alignItems="center" backgroundColor="colorBackground" padding="space30" borderRadius="borderRadius20" minHeight="53px">
				<Text as="p" fontSize="fontSize30" zIndex="zIndex20" color="colorText">
					{isPlaybackBotPlaying ?
						currentlyPlaying
							? `${t("conference-ui.pre-records-tab.playing")}: ${currentlyPlaying}`
							: t("conference-ui.pre-records-tab.currently-playing")
						: t("conference-ui.pre-records-tab.nothing-is-playing")}
				</Text>

				<Box display="flex" flexDirection="row" columnGap="space30" justifyContent="space-around" alignItems='center'>
					{/* {canShowDuration && <progress value={playbackTimer} max={duration} />} */}
					{/* {canShowDuration && <span style={{ backgroundColor: "#D3D3D3", padding: "9px 12px", borderRadius: "7px" }}>{secondsToTimestamp(playbackTimer)} - {secondsToTimestamp(duration)}</span>} */}
					<Button variant="destructive" data-cy-playback-stop onClick={handleStop}><ProcessNeutralIcon decorative={false} title={t("conference-ui.pre-records-tab.stop-audio")} size="sizeIcon30" /></Button>
				</Box>
			</Box>
		</Box> : null);
};