import { FC, useCallback, useRef, useState } from "react";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { useConferenceControlContext } from "../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { Button } from "@twilio-paste/button";
import { LowerHandIcon } from "@twilio-paste/icons/esm/LowerHandIcon";
import { RaiseHandIcon } from "@twilio-paste/icons/esm/RaiseHandIcon";
import { Box } from "@twilio-paste/box";
import { ButtonProps } from "../../../types";

export const ToggleRaiseHandButton: FC<ButtonProps> = (props: ButtonProps) => {
	const { appState, appDispatch } = useAppState();
	const [isHandRaised, setIsHandRaised] = useState(appState.isHandRaised);
	const { raiseHand } = useConferenceControlContext();
	const lastClickTimeRef = useRef(0);

	const handleRaiseHand = useCallback(() => {
		if (Date.now() - lastClickTimeRef.current > 500) {
			lastClickTimeRef.current = Date.now();
			raiseHand(!isHandRaised);
			setIsHandRaised(!isHandRaised);
			appDispatch({ type: "set-is-raise-hand", isHandRaised: !isHandRaised });
		}
	}, [isHandRaised, appDispatch, raiseHand]);

	return (
		<Button
			variant={props.menuButton ? "reset" : "secondary"}
			onClick={handleRaiseHand}
			data-cy-raisehands-toggle
		><Box display="flex" alignItems="center" columnGap="space30">
				{isHandRaised ? <LowerHandIcon decorative={true} /> : <RaiseHandIcon decorative={true} />} {isHandRaised ? "Lower Hand" : "Raise Hand"}
			</Box>
		</Button>
	);
}
