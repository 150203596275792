import { Box } from "@twilio-paste/core/dist/box";
import { createRef, FC, ReactElement, useEffect } from "react";

interface PanelContainerProps {
	children: ReactElement;
	width: number | undefined;
	setWidth: (value: number) => void;
	display?: string;
}
export const PanelContainer: FC<PanelContainerProps> = ({ children, width, setWidth, display }: PanelContainerProps) => {
	const ref = createRef<HTMLDivElement>();

	useEffect(() => {
		if (!ref.current) {
			return;
		}

		if (!width) {
			setWidth(ref.current.clientWidth);
			return;
		}

		ref.current.style.width = `${width}px`;
	}, [ref, width, setWidth]);

	return <Box ref={ref} display={display} height="100%">{children}</Box>;
}