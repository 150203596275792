import { useContext } from "react";
import { IVideoContext, VideoContext } from "../../components/VideoProvider";

export const useVideoContext = (): IVideoContext => {
	const context = useContext(VideoContext);
	if (!context) {
		throw new Error("useVideoContext must be used within a VideoProvider");
	}
	return context;
}
