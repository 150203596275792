import { FC, createContext, useCallback, useEffect, useState } from "react";
import { Client, Conversation, Message } from "@twilio/conversations";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { ReactElementProps } from "../../types";
import { ChatContextType } from "../../types/twilio-conversations";
import { DEBUG_LEVEL } from "../../constants";
import { useAppState } from "../../hooks/useAppState/useAppState";

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const { appDispatch } = useAppState();
	const { onError } = useVideoContext();
	const [isChatPanelFocus, setIsChatPanelFocus] = useState(false);
	const [conversation, setConversation] = useState<Conversation | null>(null);
	const [messages, setMessages] = useState<Message[]>([]);
	const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
	const [chatClient, setChatClient] = useState<Client>();
	const [chatChannelSid, setChatChannelSid] = useState<string>();

	useEffect(() => {
		if (!conversation) {
			return;
		}

		appDispatch({ type: "set-conversation-sid", conversationSid: conversation?.sid });
	}, [conversation, appDispatch]);

	const connect = useCallback((token: string, chatChannelSid: string) => {
		if (!chatClient) {
			try {
				const client = new Client(token, { logLevel: DEBUG_LEVEL });
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				window.chatClient = client;
				setChatClient(client);
				setChatChannelSid(chatChannelSid);
			} catch (error) {
				console.error(error);
				onError(new Error("There was a problem connecting to Twilio's conversation service."));
			}
		}
	}, [onError, chatClient]);

	const disconnect = useCallback(() => {
		setChatClient(undefined);
		setChatChannelSid(undefined);
		chatClient?.shutdown();
	}, [chatClient]);

	useEffect(() => {
		if (conversation) {
			const handleMessageAdded = (message: Message) =>
				setMessages((oldMessages) => [...oldMessages, message]);
			conversation
				.getMessages()
				.then((newMessages) => setMessages(newMessages.items));
			conversation.on("messageAdded", handleMessageAdded);
			return () => {
				conversation.off("messageAdded", handleMessageAdded);
			};
		}
	}, [conversation]);

	useEffect(() => {
		if (!isChatPanelFocus && messages.length) {
			setHasUnreadMessages(true);
		}
	}, [messages, isChatPanelFocus]);

	useEffect(() => {
		if (isChatPanelFocus) setHasUnreadMessages(false);
	}, [isChatPanelFocus]);

	useEffect(() => {
		if (chatClient && chatChannelSid) {
			chatClient.getConversationBySid(chatChannelSid)
				.then((newConversation) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					window.chatConversation = newConversation;
					setConversation(newConversation);
				})
				.catch((error) => {
					console.error(error);
					onError(
						new Error(
							"There was a problem getting the Conversation associated with this room."
						)
					);
				});
		}
	}, [chatChannelSid, chatClient, onError]);

	return (
		<ChatContext.Provider
			value={{
				isChatPanelFocus,
				setIsChatPanelFocus,
				disconnect,
				connect,
				hasUnreadMessages,
				messages,
				conversation
			}}
		>
			{children}
		</ChatContext.Provider>
	);
};
