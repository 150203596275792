import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";
import { FC, useState, useEffect } from "react";
import { SyncMapItem } from "twilio-sync";
import { Button, Modal, ModalHeader, ModalHeading, ModalBody, Paragraph, ModalFooter, ModalFooterActions, Spinner, PopoverButton, Alert } from "@twilio-paste/core";
import { useConferenceControlContext } from "../../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useParticipants } from "../../../../hooks/useParticipants/useParticipants";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { Popover, PopoverContainer } from "@twilio-paste/core/popover";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { useBookingContext } from "../../../../hooks/useBookingContext/useBookingContext";
import { useAppState } from "../../../../hooks/useAppState/useAppState";
import { isPhone, splitIdentity, stripPhone } from "../../../../core/utils";
import { IDENTITY_SEPERATOR } from "../../../../constants";
import { RegistrationFieldType } from "@mmc/conferencing-booking-client";
import { useToasterContext } from "../../../../hooks/useToasterContext/useToasterContext";
import { UploadIcon } from "@twilio-paste/icons/esm/UploadIcon";
import { PriorityCounter } from "../PriorityCounter";
import { Priority } from "../../../../types";
import { useRemoteParticipantCounter } from "../RemoteParticipantCounter";
import { Trans, useTranslation } from "react-i18next";

export const ViewerListItem: FC<{ item: SyncMapItem, draggable: boolean }> = ({ item, draggable }: { item: SyncMapItem, draggable: boolean }) => {
	const { t } = useTranslation();
	const data = item.data as any;
	const { speakerInvite } = useConferenceControlContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { participants } = useParticipants();
	const acceptInviteModalHeadingID = useUID();
	const bringToStageModalHeadingId = useUID();
	const [isAcceptInviteModalOpen, setIsAcceptInviteModalOpen] = useState(false);
	const [isBringToStageModalOpen, setIsBringToStageModalOpen] = useState(false);
	const openAcceptInviteModal = () => setIsAcceptInviteModalOpen(true);
	const openBringToStageModal = () => setIsBringToStageModalOpen(true);
	const closeAcceptInviteModal = () => setIsAcceptInviteModalOpen(false);
	const closeBringToStageModal = () => setIsBringToStageModalOpen(false);
	const { getParticipant, booking } = useBookingContext();
	const { appState } = useAppState();
	const [viewerInfo, setViewerInfo] = useState<{ label: string, value: string }[] | undefined>(undefined);
	const { toaster } = useToasterContext();
	const [priority, setPriority] = useState<number>(0);
	const { viewersCount } = useRemoteParticipantCounter();

	const acceptInvite = async () =>{
		setIsAcceptInviteModalOpen(false);
		console.log(`Accepting Invite for ${item.key}`);

		try {
			setIsLoading(true);
			await speakerInvite(`${item.key}${IDENTITY_SEPERATOR}${data.userIdentity}`, true);
			// Note: Request has succeeded, but we wait until viewer has appeared in participants list before removing the loading state
		} catch (err) {
			console.error(`Failed accepting speaker invite for "${data.userIdentity}"`, err);
			setIsLoading(false);
			toaster.push({
				message: t("conference-ui.toasters.bring-to-stage-error", { user: data.userIdentity }),
				variant: "error",
				dismissAfter: 5000
			});
		}
	}

	// bring participant to stage
	const bringToStage = async () => {
		setIsBringToStageModalOpen(false);
		try {
			setIsLoading(true);
			await speakerInvite(`${item.key}${IDENTITY_SEPERATOR}${data.userIdentity}`, true);
		}
		catch (err) {
			console.error(`Failed inviting viewer to stage "${data.userIdentity}"`, err);
			setIsLoading(false);
			toaster.push({
				message: t("conference-ui.toasters.bring-to-stage-error", { user: data.userIdentity }),
				variant: "error",
				dismissAfter: 5000
			});
		}

	}

	useEffect(() => {
		const speaker = participants.filter(p => isPhone(p.identity) ? splitIdentity(stripPhone(p.identity)) : p.identity === item.key);

		if (speaker.length > 0) {
			setIsLoading(false);
		}

	}, [participants.length, item.key, participants]);

	const getViewerInfo = (key: string) => {
		getParticipant(appState.bookingId, key).then((participant) => {
			const viewerInfo: { label: string, value: string }[] = [];
			const registrationFields = booking?.registrationFields || [];
			const ignoredFields = ["userLocale", "userTimeZone", "selectedLanguage"];

			// For every item of participant info we have, look up the corresponding field config so we can update the label / display value
			// If no config for the field is found, we'll show the key/value data exactly as stored as a safe fall-back.

			Object.entries(participant.registrationData).forEach(([fieldName, value]) => {
				// Skip ignored fields
				if (ignoredFields.includes(fieldName)) {
					return;
				}

				// Get config for field name
				const fieldConfig = registrationFields.find(field => field.name === fieldName);

				if (!fieldConfig) {
					// We can't enhance the displayed data as no config was found, so display it as it's stored
					viewerInfo.push({
						label: fieldName,
						value
					});
					return;
				}

				const { type, label, options } = fieldConfig;

				switch (type) {
				case RegistrationFieldType.Select:
					// Stored options for select input will be the values (e.g. "1", "2") and will differ to the display value shown to the user.
					// Lookup the display value from the registration field config.
					viewerInfo.push({
						label: label,
						value: options?.find(option => option.value === value)?.label || value
					});
					break;

				case RegistrationFieldType.Text:
				default:
					viewerInfo.push({
						label,
						value
					});
					break;
				}
			});

			setViewerInfo(viewerInfo);
		});
	}

	return (
		<>
			<Box display="flex" cursor={draggable ? "grab" : ""} flexDirection="row" flexWrap="nowrap" paddingX="space40" paddingY="space20" columnGap="space60" alignItems="center"  borderStyle="solid" borderWidth="borderWidth10" borderColor="colorBorderDecorative10Weaker" >
				<Box display="flex" flexDirection="row" flex={1} flexWrap="nowrap" columnGap="space30" alignItems="center"  width="100%">
					<Box width="25%">
						{data.name ? <Text as="p" fontSize="fontSize20">{data.name}</Text> : "N/A"}
					</Box>
					<Box alignItems="start" height="100%" display="flex"  alignContent="center" justifyContent="left"  borderRadius="borderRadius30" paddingY="space30" width="25%">
						<Text as="p" fontSize="fontSize20">{data.userIdentity}</Text>
					</Box>
					<Box alignItems="start" height="100%" display="flex"  alignContent="center" justifyContent="left"  borderRadius="borderRadius30" paddingY="space30" width="25%">
						<Text as="p" fontSize="fontSize20">{data.institution || "N/A"}</Text>
					</Box>
					<Box width="25%" display="flex" justifyContent="right" columnGap="space30" alignItems="center">
						<Box paddingBottom="space10" minWidth="120px">
							<PriorityCounter item={item} setPriority={setPriority} />
						</Box>
						<PopoverContainer baseId="popover-example" placement="left-end">
							<PopoverButton variant="secondary" size="icon_small" onClick={() => getViewerInfo(item.key)}>
								<>
									<UserIcon decorative={false} title={t("conference-ui.paticipants-tab.user-information")} />
								</>
							</PopoverButton>
							<Popover aria-label="Popover">
								{viewerInfo !== undefined ? viewerInfo.map((field, index) => (
									<Box display="flex" width="size40" columnGap="space30" rowGap="space60" paddingY="space30" key={index} borderBottomStyle="solid" borderBottomWidth="borderWidth10" borderBottomColor="colorBorderDecorative10Weaker">
										<Box width="150px" overflowWrap={"anywhere"}>
											<Text as="h1">{field.label}:</Text>
										</Box><Box>
											<Text as="p">{field.value}</Text>
										</Box>
									</Box>
								)
								): <Spinner size="sizeIcon60" decorative={false} title={t("general.loading")} />}
							</Popover>
						</PopoverContainer>
						{/* Can be added back for showing the connection type */}
						{/* <Badge variant="default" as="span">{data.voiceCallSid ? <ProductVoiceIcon decorative={false} title="Voice Participant"/> : <ProductLiveIcon decorative={false} title="Live Participant" />}</Badge> */}
						{!data.raiseHand &&  priority !== Priority.NoQA ?
							<Button onClick={openBringToStageModal} variant="primary" size="icon_small" loading={isLoading}><UploadIcon decorative={false} title={t("conference-ui.paticipants-tab.bring-to-stage-title")} /></Button> : null}
					</Box>
					{data.raiseHand && priority !== Priority.NoQA ?
						<Button onClick={openAcceptInviteModal} variant="primary" size="icon_small" loading={isLoading}><UploadIcon decorative={false} title={`Hand for ${item.key} is raised`} /></Button> : null}
				</Box>
			</Box>

			<Modal ariaLabelledby={acceptInviteModalHeadingID} isOpen={isAcceptInviteModalOpen} onDismiss={closeAcceptInviteModal} size="default">
				<ModalHeader>
					<ModalHeading as="h3" id={acceptInviteModalHeadingID}>
						{t("conference-ui.paticipants-tab.bring-to-stage-title")}
					</ModalHeading>
				</ModalHeader>
				<ModalBody>
					{viewersCount >= 1 && <Alert variant="warning">{t("conference-ui.paticipants-tab.bring-to-stage-duplicate")}</Alert>}

					<Box style={{ marginTop: "2em" }}>
						<Paragraph>
							<Trans i18nKey="conference-ui.paticipants-tab.bring-to-stage-text-confirmation">
								You are about to bring <strong>{{ user: data.name } as any}</strong> to the stage.
							</Trans>
						</Paragraph>
						<Paragraph>{t("conference-ui.paticipants-tab.bring-to-stage-info")}</Paragraph>
					</Box>
				</ModalBody>
				<ModalFooter>
					<ModalFooterActions>
						<Button variant="secondary" onClick={closeAcceptInviteModal}>
							{t("conference-ui.paticipants-tab.cancel-button")}
						</Button>
						<Button variant="primary" onClick={acceptInvite}>{t("conference-ui.paticipants-tab.bring-to-stage-button")}</Button>
					</ModalFooterActions>
				</ModalFooter>
			</Modal>

			<Modal ariaLabelledby={bringToStageModalHeadingId} isOpen={isBringToStageModalOpen} onDismiss={closeBringToStageModal} size="default">
				<ModalHeader>
					<ModalHeading as="h3" id={bringToStageModalHeadingId}>
						{t("conference-ui.paticipants-tab.bring-to-stage-title")}
					</ModalHeading>
				</ModalHeader>
				<ModalBody>
					{viewersCount >= 1 && <Alert variant="warning">{t("conference-ui.paticipants-tab.bring-to-stage-duplicate")}</Alert>}

					<Box style={{ marginTop: "2em" }}>
						<Paragraph>
							<Trans i18nKey="conference-ui.paticipants-tab.bring-to-stage-text-confirmation">
								You are about to bring <strong>{{ user: data.name } as any}</strong> to the stage.
							</Trans>
						</Paragraph>

						<Paragraph>{t("conference-ui.paticipants-tab.bring-to-stage-info")}</Paragraph>
					</Box>
				</ModalBody>
				<ModalFooter>
					<ModalFooterActions>
						<Button variant="secondary" onClick={closeBringToStageModal}>
							{t("conference-ui.paticipants-tab.cancel-button")}
						</Button>
						<Button variant="primary" onClick={bringToStage}>{t("conference-ui.paticipants-tab.bring-to-stage-button")}</Button>
					</ModalFooterActions>
				</ModalFooter>
			</Modal>
		</>
	);
}
