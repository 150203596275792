import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useParticipants } from "../../../hooks/useParticipants/useParticipants";

interface RemoteParticipantCounterType {
	totalCount: number;
	viewersCount: number;
	speakersCount: number;
	hostsCount: number;
}

const RemoteParticipantCounter = createContext<RemoteParticipantCounterType>(null!);

export const useRemoteParticipantCounter = () => useContext(RemoteParticipantCounter);

export const RemoteParticipantCounterProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [totalCount, setTotalCount] = useState(0);
	const [viewersCount, setViewersCount] = useState(0);
	const [speakersCount, setSpeakersCount] = useState(0);
	const [hostsCount, setHostsCount] = useState(0);

	const { participants } = useParticipants();

	useEffect(() => {
		setTotalCount(participants.length);

		const viewers = participants.filter(p => p.identity.match(/-PI/));
		const hostsAndAdmins = participants.filter(p => p.identity.startsWith("HI") || p.identity.startsWith("XI"));
		const speakers = participants.filter(p => !viewers.includes(p) && !hostsAndAdmins.includes(p));

		setViewersCount(viewers.length);
		setSpeakersCount(speakers.length);
		setHostsCount(hostsAndAdmins.length);
	}, [participants?.length, participants]);

	return (
		<RemoteParticipantCounter.Provider value={{ totalCount, viewersCount, speakersCount, hostsCount }}>
			{children}
		</RemoteParticipantCounter.Provider>
	);
};
