import {FC, useCallback, useEffect} from "react";
import { ActivePreJoinScreen, ConnectionType } from "../../core/appReducer";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useChatContext } from "../../hooks/useChatContext/useChatContext";
import { usePlayerContext } from "../../hooks/usePlayerContext/usePlayerContext";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { useSyncContext } from "../../hooks/useSyncContext/useSyncContext";
import { IntroContainer } from "../IntroContainer/IntroContainer";
import { LoadingScreen } from "./LoadingScreen/LoadingScreen";
import { ParticipantNameScreen } from "./ParticipantNameScreen/ParticipantNameScreen";
import { DeviceSelectionScreen } from "./DeviceSelectionScreen/DeviceSelectionScreen";
import { useConferenceControlContext } from "../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useConferencePlaybackContext } from "../../hooks/useConferencePlaybackContext/ConferencePlaybackContext";

export const PreJoinScreens: FC = () => {
	const { connect: chatConnect } = useChatContext();
	const { connect: videoConnect, localTracks } = useVideoContext();
	const { connect: playerConnect, disconnect: playerDisconnect } = usePlayerContext();
	const { connect: conferencePlaybackConnect } = useConferencePlaybackContext();
	const { connect: syncConnect, registerViewersMap, registerStreamDocument, registerViewerDocument, registerSpeakersMap, registerSpecialMap, registerConferenceSettingsDocument } = useSyncContext();
	const { joinStreamAsSpeaker, joinStreamAsViewer, startRoom } = useConferenceControlContext();
	const { appState, appDispatch } = useAppState();

	const connect = async (webrtc = true): Promise<void> => {
		window.addEventListener("beforeunload", (event) => {
			// Cancel the event as stated by the standard.
			event.preventDefault();
			// Chrome requires returnValue to be set.
			event.returnValue = "";
		});

		appDispatch({ type: "set-is-loading", isLoading: true });
		if (appState.participantId && appState.participantId !== null && appState.verifyToken && appState.verifyToken !== null) {
			try {
				if (appState.hasSpeakerInvite) {
					const { token } = await joinStreamAsSpeaker(appState.verifyToken, true);
					await videoConnect(token);
					playerDisconnect();
					appDispatch({ type: "set-connection-type", connectionType: ConnectionType.WebRTC });
					appDispatch({ type: "set-is-loading", isLoading: false });
					appDispatch({ type: "set-has-speaker-invite", hasSpeakerInvite: false });
					appDispatch({ type: "set-is-temp-speaker", isTempSpeaker: true });
					return;
				}

				if (appState.participantId && appState.participantId !== null) {
					switch (appState.participantType) {
					case "notified_admin":
					case "notified_host": {
						const { token, syncObjectNames: { viewersMap, speakersMap, streamDocument, specialMap, conferenceSettingsDocument }, chatChannelSid } = await startRoom(appState.verifyToken);
						syncConnect(token);
						conferencePlaybackConnect(token);

						const connectionType = webrtc ? ConnectionType.WebRTC : ConnectionType.DialIn;

						appDispatch({ type: "set-connection-type", connectionType });
						await videoConnect(token);

						registerViewersMap(viewersMap);
						registerSpeakersMap(speakersMap);
						registerSpecialMap(specialMap);
						registerStreamDocument(streamDocument);
						registerConferenceSettingsDocument(conferenceSettingsDocument);

						if (chatChannelSid) {
							chatConnect(token, chatChannelSid);
						}

						appDispatch({ type: "set-is-temp-speaker", isTempSpeaker: false });
						break;
					}

					case "notified_speaker": {
						const { token, syncObjectNames: { viewersMap, speakersMap, streamDocument, specialMap, conferenceSettingsDocument }, chatChannelSid } = await joinStreamAsSpeaker(appState.verifyToken);
						syncConnect(token);

						const connectionType = webrtc ? ConnectionType.WebRTC : ConnectionType.DialIn;

						appDispatch({ type: "set-connection-type", connectionType });
						await videoConnect(token);

						registerViewersMap(viewersMap);
						registerSpeakersMap(speakersMap);
						registerSpecialMap(specialMap);
						registerStreamDocument(streamDocument);
						registerConferenceSettingsDocument(conferenceSettingsDocument);

						if (chatChannelSid) {
							chatConnect(token, chatChannelSid);
						}

						appDispatch({ type: "set-is-temp-speaker", isTempSpeaker: false });
						break;
					}

					case "notified_viewer": {
						const { token } = await joinStreamAsViewer(appState.verifyToken);
						syncConnect(token);
						await playerConnect(token);
						registerViewerDocument(`user-${appState.participantId}`);
						appDispatch({ type: "set-is-temp-speaker", isTempSpeaker: false });
						appDispatch({ type: "set-is-participant-window-open", isParticipantWindowOpen: false });
						break;
					}
					default: {
						console.error("Invalid User Role", appState.participantType);
						break;
					}
					}
				}
				appDispatch({ type: "set-is-loading", isLoading: false });
			} catch (error: any) {
				console.log(error);
				if (error?.body?.code === 20404) {
					console.log("Conference is not open yet");
					setTimeout(connect, 10000);
				} else if (error?.body?.code === 53113) {
					console.error(error);
				} else if (error.response?.data?.error?.message === "error finding room") {
					console.error({
						headline: "Error",
						message: "Event cannot be found. Please check the event name and try again.",
						variant: "error"
					});
					appDispatch({ type: "set-is-loading", isLoading: false });
				} else {
					console.error({
						headline: "Error",
						message: "There was an error while connecting to the event.",
						variant: "error"
					});
					appDispatch({ type: "set-is-loading", isLoading: false });
				}
			}
		}
	}

	const rejoinConferenceOnRefresh = useCallback( async() => {
		// If we have a room SID, the user was already in the conference and we're here because they refreshed the page.
		// However, we can't take them directly back into the conference as to connect to the Twilio Video APIs outside
		// of a user event (like a click) would result in access to user media being blocked. Mute/unmute would not work,
		// and the user won't be heard.
		//
		// Instead, we ensure they're taken back to the device selection screen so they can manually choose if and when
		// they will rejoin.
		if (appState?.roomSid && localTracks?.length) {
			console.log(`[PreJoinScreens] User has room SID (${appState.roomSid}) - Taking them back to device selection screen to re-join...`);
			appDispatch({ type: "set-active-prejoin-screen", activePreJoinScreen: ActivePreJoinScreen.DeviceSelectionScreen });
		}
	}, [appState.roomSid, localTracks, appDispatch]);

	useEffect(() => {
		rejoinConferenceOnRefresh();
	}, [localTracks.length, rejoinConferenceOnRefresh]);

	return (
		<IntroContainer>
			{appState.isLoading ? (
				<LoadingScreen state={appState} />
			) : (
				{
					[ActivePreJoinScreen.ParticipantNameScreen]: <ParticipantNameScreen state={appState} dispatch={appDispatch} connect={connect} />,
					[ActivePreJoinScreen.DeviceSelectionScreen]: <DeviceSelectionScreen state={appState} dispatch={appDispatch} connect={connect} />
				}[appState.activePreJoinScreen]
			)}
		</IntroContainer>);
}
