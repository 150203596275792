import { FC } from "react";
import { Box } from "@twilio-paste/box";
import { useTranslation } from "react-i18next";
import { Alert, Heading, SkeletonLoader, Text } from "@twilio-paste/core";
import LanguageSelector from "../PreJoinScreens/LanguageSelector";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { IntroContainer } from "../IntroContainer/IntroContainer";

export const NoAccessPage: FC = () => {
	const { t } = useTranslation();
	const { conference, booking } = useBookingContext();

	return (
		<IntroContainer>
			<Box display="flex" flexDirection="row" justifyContent="space-between">
				<Box>
					{booking ? (
						<>
							<Heading as="h4" variant="heading30" marginBottom="space0">
								{booking?.event.name}
							</Heading>
							<Text as="p">
								{conference &&
								new Date(conference?.startDate).toLocaleString(undefined, {
									timeZoneName: "short"
								})}
							</Text>
						</>
					) : (
						<SkeletonLoader height="70px" width="200px" />
					)}
				</Box>
				<LanguageSelector />
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				borderTopStyle="solid"
				borderTopWidth="borderWidth10"
				borderTopColor="colorBorderDecorative10Weaker"
				paddingY="space60"
				marginTop="space40"
			>
				<Alert variant="warning">
					<Text as="strong">
						{t("login-page.registration-closed")}
					</Text>
				</Alert>
			</Box>
		</IntroContainer>
	);
};
