import { Box } from "@twilio-paste/core/box";
import { FC } from "react";
import { PopOverMaker } from "./PopOverMaker";
import { useTranslation } from "react-i18next";
import { Booking } from "@mmc/conferencing-booking-client";

interface EventType {
  booking: Booking | undefined;
}

export const EventInfo: FC<EventType> = ({ booking }) => {
	const { t } = useTranslation();

	return (
		<Box as="section" aria-labelledby="event-info-heading">
			<Box
				display="flex"
				flexDirection="row"
				flexWrap="wrap"
				flex="9"
				columnGap="space30"
				rowGap="space30"
				role="region"
				aria-label={t("conference-ui.conf-tab.event")}

			>
				{booking?.event.usage && (
					<PopOverMaker
						title={booking?.event.usage}
						description={t("conference-ui.conf-tab.event-usage-pop")}
					/>
				)}
				{booking?.event?.serviceLevel && (
					<PopOverMaker
						title={booking?.event.serviceLevel}
						description={t("conference-ui.conf-tab.event-service-level-pop")}
					/>
				)}
				{booking?.event?.id && (
					<PopOverMaker
						title={booking?.event.id}
						description={t("conference-ui.conf-tab.event-id-pop")}
					/>
				)}
			</Box>
		</Box>
	);
};