import { Dispatch, PropsWithChildren, createContext, useReducer, useState, Context, FC } from "react";
import { appActionTypes, appReducer, appStateTypes, initialAppState } from "../../core/appReducer";
import { Settings, SettingsAction, initialSettings, settingsReducer } from "../../core/settingsReducer";
import { RoomType } from "../../types/twilio-video";
import { TwilioError } from "twilio-video";
import { useActiveSinkId } from "../../hooks/useActiveSinkId/useActiveSinkId";
import { useCiptexAuth } from "../../hooks/useCiptexAuth/useCiptexAuth";
import { ParticipantVerify } from "@mmc/conferencing-booking-client";
import { HostAuth } from "../../types/ciptex-sdk";
import { NotifiedToken, NotifiedUser } from "../../types";

export interface StateContextType {
	error: TwilioError | Error | null;
	setError(error: TwilioError | Error | null): void;
	authHost(hostId: string, token: string): Promise<void>;
	authNotified(notifiedId: string, token: string, params: NotifiedUser): Promise<void>;
	authViewer(pwd: string): Promise<void>;
	signOut(): Promise<void>;
	isAuthReady?: boolean;
	activeSinkId: string;
	setActiveSinkId(sinkId: string): void;
	settings: Settings;
	dispatchSetting: Dispatch<SettingsAction>;
	roomType?: RoomType;
	appState: appStateTypes;
	appDispatch: Dispatch<appActionTypes>;
	user?: null | ParticipantVerify & { pwd: string } ;
	host?: null | HostAuth;
	notified?: null | NotifiedToken;
}

export const StateContext: Context<StateContextType> = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
// eslint-disable-next-line @typescript-eslint/ban-types
export const AppStateProvider: FC<PropsWithChildren> = (props: PropsWithChildren<{}>) => {
	const [error, setError] = useState<TwilioError | null>(null);
	const [activeSinkId, setActiveSinkId] = useActiveSinkId();
	const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
	const [appState, appDispatch] = useReducer(appReducer, initialAppState);

	const contextValue = {
		error,
		setError,
		activeSinkId,
		setActiveSinkId,
		settings,
		dispatchSetting,
		appState,
		appDispatch,
		...useCiptexAuth()
	} as StateContextType;

	return (
		<StateContext.Provider value={{ ...contextValue }}>
			{props.children}
		</StateContext.Provider>
	);
}
