import { LeaderInfo } from "./LeaderInfo";
import { FC } from "react";
import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/core/text";
import { useBookingContext } from "../../../hooks/useBookingContext/useBookingContext";
import { OrganizerInfo } from "./OrganizerInfo";
import { AccountInfo } from "./AccountInfo";
import { OptionsInfo } from "./OptionsInfo";
import { EventInfo } from "./EventInfo";
import { useTranslation } from "react-i18next";

export const EventInformation: FC = () => {
	const { booking } = useBookingContext();
	const { t } = useTranslation();

	return (
		<Box display="flex" flexDirection="column" marginY="space60">
			<Box display="flex" flexDirection="row" columnGap="space30" rowGap="space30" marginY="space30" flexWrap="wrap">
				<Box display="flex" minWidth="100px">
					<Text id="options-info-heading" as="h3">{t("conference-ui.conf-tab.options")}:</Text>
				</Box>
				<OptionsInfo booking={booking} />
			</Box>
			<Box display="flex" flexDirection="row" columnGap="space30" rowGap="space30" marginY="space30" flexWrap="wrap" alignItems="center">
				<Box display="flex" minWidth="100px">
					<Text id="event-info-heading" as="h3">{t("conference-ui.conf-tab.event")}:</Text>
				</Box>
				<EventInfo booking={booking} />
			</Box>
			<Box display="flex" flexDirection="row" columnGap="space30" rowGap="space30" marginY="space30" flexWrap="wrap" alignItems="center">
				<Box display="flex" minWidth="100px">
					<Text id="account-info-heading" as="h3">{t("conference-ui.conf-tab.account")}:</Text>
				</Box>
				<AccountInfo booking={booking} />
			</Box>
			<Box display="flex" flexDirection="row" columnGap="space30" rowGap="space30" marginY="space30" flexWrap="wrap" alignItems="center">
				<Box display="flex" minWidth="100px">
					<Text id="leader-info-heading" as="h3">{t("conference-ui.conf-tab.leader")}:</Text>
				</Box>
				<LeaderInfo booking={booking} />
			</Box>

			<Box display="flex" flexDirection="row" columnGap="space30" rowGap="space30" marginY="space30" flexWrap="wrap" alignItems="center">
				<Box display="flex" minWidth="100px">
					<Text id="organizer-info-heading"  as="h3">{t("conference-ui.conf-tab.organizer")}:</Text>
				</Box>
				<OrganizerInfo booking={booking} />
			</Box>
			<Box display="flex" flexDirection="row" columnGap="space30" rowGap="space30" marginY="space30" flexWrap="wrap" alignItems="center">
				<Box display="flex" minWidth="100px">
					<Text as="h3">{t("conference-ui.conf-tab.notes")}:</Text>
				</Box>
				<Box display="flex" flexDirection="row" flexWrap="wrap" flex="9" columnGap="space30" rowGap="space30" alignItems="center">
					{booking?.notes ? <Text as="p">{booking?.notes}</Text> : <Text as="p">N/A</Text>}
				</Box>
			</Box>

		</Box>
	)
};