import { FC, PropsWithChildren } from "react";
import { BackgroundThumbnail } from "../BackgroundThumbnail/BackgroundThumbnail";
import { backgroundConfig } from "../VideoProvider/useBackgroundSettings/useBackgroundSettings";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalFooterActions,
	ModalHeader,
	ModalHeading
} from "@twilio-paste/modal";
import { Box } from "@twilio-paste/core";
import { Button } from "@twilio-paste/core/button";
import { DialogProps } from "../../types";
export const BackgroundSelectionDialog: FC<DialogProps> = ({ open, onClose }: PropsWithChildren<DialogProps>) => {
	const { isBackgroundSelectionOpen, setIsBackgroundSelectionOpen } = useVideoContext();
	const { imageNames } = backgroundConfig;
	const { images } = backgroundConfig;

	return (
		<Modal ariaLabelledby="" isOpen={isBackgroundSelectionOpen || open} onDismiss={() => { setIsBackgroundSelectionOpen(false); onClose(); }} size="wide">
			<ModalHeader>
				<ModalHeading>Background selection</ModalHeading>
			</ModalHeader>
			<ModalBody>
				<Box display="flex" flexDirection="row" overflowX="scroll">
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between">
						<Box>
							<BackgroundThumbnail thumbnail={"none"} name={"None"} />
						</Box>
						<Box>
							<BackgroundThumbnail thumbnail={"blur"} name={"Blur"} />
						</Box>
					</Box>

					{images.map((image, index) => (
						<BackgroundThumbnail
							thumbnail={"image"}
							name={imageNames[index]}
							index={index}
							imagePath={image}
							key={image}
						/>
					))}
				</Box>
			</ModalBody>
			<ModalFooter>
				<ModalFooterActions>
					<Button  onClick={() => { setIsBackgroundSelectionOpen(false); onClose();} } variant="primary">Save</Button>
				</ModalFooterActions>
			</ModalFooter>
		</Modal>
	);
}
