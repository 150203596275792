import { AudioLevelIndicator } from "../../AudioLevelIndicator/AudioLevelIndicator";
import { LocalAudioTrack } from "twilio-video";
import { SELECTED_AUDIO_INPUT_KEY } from "../../../constants";
import { useDevices } from "../../../hooks/useDevices/useDevices";
import { useMediaStreamTrack } from "../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { Text } from "@twilio-paste/text";
import { Box } from "@twilio-paste/box";
import { Option, Select } from "@twilio-paste/core/select";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "@twilio-paste/core";

export const AudioInputList: FC = () => {
	const { t } = useTranslation();
	const { audioInputDevices } = useDevices();
	const { localTracks } = useVideoContext();

	const localAudioTrack = localTracks.find(track => track.kind === "audio") as LocalAudioTrack;
	const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
	const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

	const replaceTrack = (newDeviceId: string): void => {
		window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
		localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
	}

	return (
		<div>
			<Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="space60">
				<div className="inputSelect" style={{ flex: "11 1 auto" }}>
					{audioInputDevices.length > 1 ? (
						<Box >
							<Label htmlFor="audioInput" aria-labelledby={t("choose-your-adventure-page.tab1.audio-settings-modal.audio-input")} >
								{t("choose-your-adventure-page.tab1.audio-settings-modal.audio-input")}
							</Label>
							<Select
								id="audioInput"
								onChange={e => replaceTrack(e.target.value as string)}
								value={localAudioInputDeviceId || ""}
							>
								{audioInputDevices.map(device => (
									<Option value={device.deviceId} key={device.deviceId}>
										{device.label}
									</Option>
								))}
							</Select>
						</Box>
					) : (
						<Text as="p">{localAudioTrack?.mediaStreamTrack.label || "No Local Audio"}</Text>
					)}
				</div>
				<Box flex="1 1 auto" display="flex" justifyContent="end">
					<AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
				</Box>
			</Box>
		</div>
	);
}
