import { FC, PropsWithChildren } from "react";
import { enhanceMessage } from "./enhanceMessage";
import { TwilioError } from "twilio-video";
import { useUID } from "@twilio-paste/core/uid-library";
import { Button } from "@twilio-paste/core/button";
import {
	Modal,
	ModalBody,
	ModalDialogContent,
	ModalFooter,
	ModalFooterActions,
	ModalHeader,
	ModalHeading
} from "@twilio-paste/core/modal";

interface ErrorDialogProps {
  dismissError: () => void;
  error: TwilioError | Error | null;
}

export const ErrorDialog: FC<ErrorDialogProps> = ({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) => {
	const { message, code } = error || {};
	const enhancedMessage = enhanceMessage(message, code);
	const modalHeadingID = useUID();

	return (
		<Modal
			ariaLabelledby={modalHeadingID}
			isOpen={error !== null}
			onDismiss={() => dismissError()}
			size="default"
		>
			<ModalHeader>
				<ModalHeading as="h3" id={modalHeadingID}>
          ERROR
				</ModalHeading>
			</ModalHeader>
			<ModalBody>
				<ModalDialogContent>{enhancedMessage}</ModalDialogContent>
				{Boolean(code) && (
					<pre>
						<code>Error Code: {code}</code>
					</pre>
				)}
			</ModalBody>
			<ModalFooter>
				<ModalFooterActions>
					<Button onClick={() => dismissError()} variant="primary">
            OK
					</Button>
				</ModalFooterActions>
			</ModalFooter>
		</Modal>
	);
}
