import { produce } from "immer";
import { APP_STATE_SESSION_STORAGE_KEY } from "../constants";
import { Role } from "@mmc/conferencing-booking-client";

export enum ActivePreJoinScreen {
	ParticipantNameScreen,
	DeviceSelectionScreen,
}

export enum ConnectionType {
	WebRTC,
	DialIn,
	DialOut
}

export interface ConferenceSettings {
	automaticallySwapParticipantsOnStage: boolean;
}

export interface appStateTypes {
	activePreJoinScreen: ActivePreJoinScreen;
	participantType: Role | null;
	accountSid: string;
	bookingId: string;
	conferenceId: string;
	participantId: string;
	participantPin: string;
	participantIdentity: string;
	mediaError: Error | null;
	isLoading: boolean;
	isMuted: boolean;
	hasSpeakerInvite: boolean;
	isParticipantWindowOpen: boolean;
	isHandRaised: boolean;
	isRoomOpen: boolean;
	isStreamStreaming: boolean;
	roomSid: string;
	verifyToken: string | null;
	isTempSpeaker: boolean;
	connectionType: ConnectionType | null;
	conversationSid: string;
	conferenceSettings: ConferenceSettings |  null;
}

export type appActionTypes =
	| { type: "set-active-prejoin-screen"; activePreJoinScreen: ActivePreJoinScreen }
	| { type: "set-account-sid"; accountSid: string }
	| { type: "set-booking-id"; bookingId: string }
	| { type: "set-conference-id"; conferenceId: string }
	| { type: "set-participant-id"; participantId: string }
	| { type: "set-participant-pin"; participantPin: string }
	| { type: "set-participant-identity"; participantIdentity: string }
	| { type: "set-participant-type"; participantType: appStateTypes["participantType"] }
	| { type: "set-verify-token"; verifyToken: string }
	| { type: "set-is-loading"; isLoading: boolean }
	| { type: "set-is-muted"; isMuted: boolean }
	| { type: "set-is-raise-hand"; isHandRaised: boolean }
	| { type: "set-has-speaker-invite"; hasSpeakerInvite: boolean }
	| { type: "reset-state" }
	| { type: "set-is-participant-window-open"; isParticipantWindowOpen: boolean }
	| { type: "set-is-room-open"; isRoomOpen: boolean }
	| { type: "set-is-stream-streaming"; isStreamStreaming: boolean }
	| { type: "set-is-temp-speaker"; isTempSpeaker: boolean }
	| { type: "set-connection-type"; connectionType: ConnectionType | null }
	| { type: "set-room-sid"; roomSid: string }
	| { type: "set-conversation-sid"; conversationSid: string }
	| { type: "set-conference-settings"; conferenceSettings: ConferenceSettings |  null };

let savedAppState: appStateTypes|null = null;

try {
	console.log("[appReducer] Fetching state form session storage");

	const savedAppStateString = sessionStorage.getItem(APP_STATE_SESSION_STORAGE_KEY);

	if (savedAppStateString) {
		savedAppState = JSON.parse(savedAppStateString);

		console.log("[appReducer] App state initialised with saved state", savedAppState);
	} else {
		console.log("[appReducer] No app state found in session storage");
	}
} catch (err) {
	console.error("[appReducer] Failed to parse app state from session storage", err);
}

const defaultAppState: appStateTypes = {
	activePreJoinScreen: ActivePreJoinScreen.ParticipantNameScreen,
	participantType: null,
	accountSid: "",
	bookingId: "",
	conferenceId: "",
	participantId: "",
	participantIdentity: "",
	participantPin: savedAppState?.participantPin ?? "",
	mediaError: null,
	isLoading: false,
	isMuted: false,
	hasSpeakerInvite: false,
	isParticipantWindowOpen: true,
	isHandRaised: false,
	isStreamStreaming: false,
	isRoomOpen: false,
	roomSid: "",
	verifyToken: "",
	isTempSpeaker: false,
	connectionType: null,
	conversationSid: "",
	conferenceSettings: null
};

export const initialAppState: appStateTypes = {
	activePreJoinScreen: savedAppState?.activePreJoinScreen ?? defaultAppState.activePreJoinScreen,
	participantType: savedAppState?.participantType ?? defaultAppState.participantType,
	accountSid: savedAppState?.accountSid ?? defaultAppState.accountSid,
	bookingId: savedAppState?.bookingId ?? defaultAppState.bookingId,
	conferenceId: savedAppState?.conferenceId ?? defaultAppState.conferenceId,
	participantId: savedAppState?.participantId ?? defaultAppState.participantId,
	participantIdentity: savedAppState?.participantIdentity ?? defaultAppState.participantIdentity,
	participantPin: savedAppState?.participantPin ?? defaultAppState.participantPin,
	mediaError: defaultAppState.mediaError, // purposefully not loaded from state as error should be transient
	isLoading: defaultAppState.isLoading, // purposefully set to false as set by the app as it triggers loading, we don't want to use saved state
	isMuted: savedAppState?.isMuted ?? defaultAppState.isMuted,
	hasSpeakerInvite: savedAppState?.hasSpeakerInvite ?? defaultAppState.hasSpeakerInvite,
	isParticipantWindowOpen: savedAppState?.isParticipantWindowOpen ?? defaultAppState.isParticipantWindowOpen,
	isHandRaised: savedAppState?.isHandRaised ?? defaultAppState.isHandRaised,
	isStreamStreaming: savedAppState?.isStreamStreaming ?? defaultAppState.isStreamStreaming,
	isRoomOpen: savedAppState?.isRoomOpen ?? defaultAppState.isRoomOpen,
	roomSid: savedAppState?.roomSid ?? defaultAppState.roomSid,
	verifyToken: savedAppState?.verifyToken ?? defaultAppState.verifyToken,
	isTempSpeaker: savedAppState?.isTempSpeaker ?? defaultAppState.isTempSpeaker,
	connectionType: savedAppState?.connectionType ?? defaultAppState.connectionType,
	conversationSid: savedAppState?.conversationSid ?? defaultAppState.conversationSid,
	conferenceSettings: savedAppState?.conferenceSettings ?? defaultAppState.conferenceSettings
};

export const appReducer = produce((draft: appStateTypes, action: appActionTypes) => {
	switch (action.type) {
	case "set-account-sid":
		draft.accountSid = action.accountSid;
		break;
	case "set-booking-id":
		draft.bookingId = action.bookingId;
		break;

	case "set-conference-id":
		draft.conferenceId = action.conferenceId;
		break;

	case "set-participant-id":
		draft.participantId = action.participantId;
		break;

	case "set-participant-identity":
		draft.participantIdentity = action.participantIdentity;
		break;

	case "set-participant-pin":
		draft.participantPin = action.participantPin;
		break;

	case "set-active-prejoin-screen":
		draft.activePreJoinScreen = action.activePreJoinScreen;
		break;

	case "set-is-loading":
		draft.isLoading = action.isLoading;
		break;

	case "set-is-muted":
		draft.isMuted = action.isMuted;
		break;

	case "set-is-raise-hand":
		draft.isHandRaised = action.isHandRaised;
		break;

	case "set-participant-type":
		draft.participantType = action.participantType;
		break;

	case "set-verify-token":
		draft.verifyToken = action.verifyToken;
		break;

	case "set-has-speaker-invite":
		// Ignore this action when connecting to a room
		if (!draft.isLoading) {
			draft.hasSpeakerInvite = action.hasSpeakerInvite;
			if (action.hasSpeakerInvite) {
				draft.activePreJoinScreen = ActivePreJoinScreen.DeviceSelectionScreen;
			}
		}
		break;

	case "reset-state":
		// Don't reset state while transitioning to a room
		if (!draft.hasSpeakerInvite) {
			const resetSate = {
				...initialAppState,
				// Reset state, but remove room SID so on leaving the conference we don't reconnect to the room
				roomSid: ""
			};

			sessionStorage.setItem(APP_STATE_SESSION_STORAGE_KEY, JSON.stringify(resetSate));

			return resetSate;
		}
		break;

	case "set-is-participant-window-open":
		draft.isParticipantWindowOpen = action.isParticipantWindowOpen;
		break;

	case "set-is-stream-streaming":
		draft.isStreamStreaming = action.isStreamStreaming;
		break;

	case "set-is-room-open":
		draft.isRoomOpen = action.isRoomOpen;
		break;

	case "set-is-temp-speaker":
		draft.isTempSpeaker = action.isTempSpeaker;
		break;

	case "set-connection-type":
		draft.connectionType = action.connectionType;
		break;

	case "set-room-sid":
		draft.roomSid = action.roomSid;
		break;

	case "set-conversation-sid":
		draft.conversationSid = action.conversationSid;
		break;

	case "set-conference-settings":
		draft.conferenceSettings = action.conferenceSettings;
		break;

	default:
		console.error("Invalid Reducer");
		break;
	}

	sessionStorage.setItem(APP_STATE_SESSION_STORAGE_KEY, JSON.stringify(draft));
});