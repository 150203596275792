import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { Filename, IconContainer, MediaInfo, MessageContainer, Size } from "./MediaMessage.styles";
import { Media } from "@twilio/conversations";
import { FC } from "react";

interface MediaMessageProps {
  media: Media;
}

export const FormatFileSize: any = (bytes: number, suffixIndex = 0) => {
	const suffixes = ["bytes", "KB", "MB", "GB"];
	if (bytes < 1000) return +bytes.toFixed(2) + " " + suffixes[suffixIndex];
	return FormatFileSize(bytes / 1024, suffixIndex + 1);
}

export const MediaMessage: FC<MediaMessageProps> = ({ media }: MediaMessageProps) => {

	const handleClick = () => {
		media.getContentTemporaryUrl().then(url => {
			if(url)
			{
				const anchorEl = document.createElement("a");

				anchorEl.href = url;
				anchorEl.target = "_blank";
				anchorEl.rel = "noopener";

				// setTimeout is needed in order to open files in iOS Safari.
				setTimeout(() => {
					anchorEl.click();
				});
			}
		});
	};

	return (
		<MessageContainer onClick={handleClick}>
			<IconContainer>
				<DownloadIcon decorative={true} />
			</IconContainer>
			<MediaInfo>
				<Filename>{media.filename}</Filename>
				<Size>{FormatFileSize(media.size)} - Click to open</Size>
			</MediaInfo>
		</MessageContainer>
	);
}
