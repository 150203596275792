import { FC, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Loading } from "../components/Loading/Loading";
import { AppStateProvider } from "../components/AppStateProvider/AppStateProvider";
import { PrivateRoute } from "../components/PrivateRoute/PrivateRoute";
import { ConferenceProvider } from "../components/ConferenceProvider/ConferenceProvider";
import { LoginPage } from "../components/LoginPage/LoginPage";
import { ConferenceContainer } from "../components/ConferenceContainer/ConferenceContainer";
import { ToasterProvider } from "../components/ToasterProvider/ToasterProvider";
import { PhoneNumberProvider } from "../components/PhoneNumberProvider/PhoneNumberProvider";
import { BookingProvider } from "../components/BookingProvider/BookingProvider";
import { RouteContainer } from "../components/RouteContainer/RouteContainer";
import { SentryProvider } from "../components/SentryProvider/SentryProvider";
import { ConferenceEndedPage } from "../components/ConferenceEndedPage/ConferenceEndedPage";
import { NoAccessPage } from "../components/NoAccessPage/NoAccessPage";

export const Router: FC = () => {
	return (<Suspense fallback={<Loading />}>
		<BrowserRouter basename="/">
			<AppStateProvider>
				<SentryProvider>
					<ToasterProvider>
						<BookingProvider>
							<PhoneNumberProvider>
								<Routes>
									<Route path="/" element={<RouteContainer />}>
										<Route index element={<LoginPage />} />
										<Route path="conference" element={
											<PrivateRoute>
												<ConferenceProvider>
													<ConferenceContainer />
												</ConferenceProvider>
											</PrivateRoute>
										} />
										<Route path="join/:participantId" element={<LoginPage />} />
										<Route path="enter/:bookingId" element={<LoginPage />} />
										<Route path="host/:bookingId/:conferenceId" element={<LoginPage />} />
										<Route path="ended" element={<ConferenceEndedPage />} />
										<Route path="no-access" element={<NoAccessPage />} />
										<Route path="*" element={<Navigate to={"/"} />} />
									</Route>
								</Routes>
							</PhoneNumberProvider>
						</BookingProvider>
					</ToasterProvider>
				</SentryProvider>
			</AppStateProvider>
		</BrowserRouter>
	</Suspense>)
};