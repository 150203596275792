import { useAppState } from "../../../hooks/useAppState/useAppState";
import { useDevices } from "../../../hooks/useDevices/useDevices";
import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";
import { Option, Select } from "@twilio-paste/core/select";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "@twilio-paste/core";

export const AudioOutputList: FC = () => {
	const { t } = useTranslation();
	const { audioOutputDevices } = useDevices();
	const { activeSinkId, setActiveSinkId } = useAppState();
	const activeOutputLabel = audioOutputDevices.find(
		(device) => device.deviceId === activeSinkId
	)?.label;

	return (
		<div className="inputSelect">
			{audioOutputDevices.length > 1 ? (
				<Box>
					<Label aria-labelledby={t("choose-your-adventure-page.tab1.audio-settings-modal.audio-output")} htmlFor="audioOutput">{t("choose-your-adventure-page.tab1.audio-settings-modal.audio-output")}</Label>
					<Select
						id="audioOutput"
						onChange={(e) => setActiveSinkId(e.target.value as string)}
						value={activeSinkId}
					>
						{audioOutputDevices.map((device) => (
							<Option value={device.deviceId} key={device.deviceId}>
								{device.label}
							</Option>
						))}
					</Select>
				</Box>
			) : (
				<>
					<Text as="p">{t("choose-your-adventure-page.tab1.audio-settings-modal.audio-output")}</Text>
					<Text as="p">
						{activeOutputLabel || "System Default Audio Output"}
					</Text>
				</>
			)}
		</div>
	);
}
