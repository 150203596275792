import { FC } from "react"
import { BlurIcon } from "../../icons/BlurOnOutlined";
import { NoneIcon } from "../../icons/NoneIconOutlined";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/core/text";

export type Thumbnail = "none" | "blur" | "image";

interface BackgroundThumbnailProps {
  thumbnail: Thumbnail;
  imagePath?: string;
  name?: string;
  index?: number;
}

export const BackgroundThumbnail: FC<BackgroundThumbnailProps> = ({ thumbnail, imagePath, name, index }: BackgroundThumbnailProps) => {
	const { backgroundSettings, setBackgroundSettings } = useVideoContext();
	const isImage = thumbnail === "image";
	const thumbnailSelected = isImage
		? backgroundSettings.index === index && backgroundSettings.type === "image"
		: backgroundSettings.type === thumbnail;
	const icons = {
		none: NoneIcon,
		blur: BlurIcon,
		image: null
	};
	const ThumbnailIcon = icons[thumbnail];

	const onClick = () => {
		setBackgroundSettings({ type: thumbnail, index });
	};

	return (
		<Box display="flex" padding="space40" flexDirection="column" justifyContent="space-between" alignItems="center" onClick={onClick}>
			{ThumbnailIcon ? (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					borderStyle="solid"
					borderRadius="borderRadius30"
					borderWidth="borderWidth20"
					borderColor={thumbnailSelected ? "colorBorderPrimary" : "colorBorder"}>
					<ThumbnailIcon />
					<Text textAlign="center" as="h2">{name}</Text>
				</Box>
			) : (
				<Box display="flex" flexDirection="column" padding="space40" alignItems="center" borderStyle="solid" borderRadius="borderRadius30" borderWidth="borderWidth20" borderColor={thumbnailSelected ? "colorBorderPrimary" : "colorBorder"}>
					<img src={imagePath} alt={name} />
					<Text as="h2" marginTop="space40">{name}</Text>
				</Box>
			)}
		</Box>
	);
}
