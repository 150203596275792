import { useEffect } from "react";
import { DataTrack as IDataTrack } from "twilio-video";
import { useLocalAudioToggle } from "../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { useTranslation } from "react-i18next";

export const DataTrack = ({ track }: { track: IDataTrack }): null => {
	const { t } = useTranslation();
	const { room } = useVideoContext();
	const [isAudioEnabled, toggleAudio] = useLocalAudioToggle();
	const { toaster } = useToasterContext();

	useEffect(() => {
		const handleMessage = (message: string | ArrayBuffer) => {
			if (message instanceof ArrayBuffer) {
				try {
					// Here we convert the message to stringified JSON from ArrayBuffer. Sending/receiving ArrayBuffers
					// in the DataTracks helps with interoperability with the iOS Twilio Live App.
					const messageString = new TextDecoder().decode(message);
					const JSONMessage = JSON.parse(messageString);
					if (JSONMessage.message_type === "mute" && JSONMessage.to_participant_identity === room!.localParticipant.identity) {
						if (isAudioEnabled !== null && isAudioEnabled !== undefined) {
							toggleAudio();
							toaster.push({
								id: "mute-unmute-audio",
								message: isAudioEnabled
									? t("conference-ui.toasters.muted-by-host")
									: t("conference-ui.toasters.unmuted-by-host"),
								variant: "neutral",
								dismissAfter: 2000
							});
							setTimeout(() => {
								toaster.pop("mute-unmute-audio");
							}, 2000);
						}
					}
				} catch (e) {
					console.error("Error parsing data track message: ", e);
				}
			}
		};
		track.on("message", handleMessage);
		return () => {
			track.off("message", handleMessage);
		};
		// TODO: Make sure it is not affects current flow - Not Sure about toaster and t, make sure adding them to useEffect deps will not cause any issues, if so, add eslint ignore and remove them from deps
	}, [track, isAudioEnabled, room, toggleAudio, toaster, t]);

	return null; // This component does not return any HTML, so we will return 'null' instead.
}
