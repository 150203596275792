import { Box } from "@twilio-paste/box";
import { Badge } from "@twilio-paste/badge";
import { Text } from "@twilio-paste/text";
import { Participant as IParticipant } from "twilio-video";
import { FC, useState } from "react";
import { Button } from "@twilio-paste/core/button";
import { useConferenceControlContext } from "../../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { ProductLiveIcon } from "@twilio-paste/icons/esm/ProductLiveIcon";
import { ProductVoiceIcon } from "@twilio-paste/icons/esm/ProductVoiceIcon";
import { splitIdentity, stripPhone } from "../../../../core/utils";
import { useAppState } from "../../../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../../../hooks/useToasterContext/useToasterContext";
import { PLAYBACKBOT_IDENTITY } from "../../../../constants";
import { ParticipantIndicators } from "../ParticipantIndicators/ParticipantIndicators";
import { Modal, ModalHeader, ModalHeading, ModalBody, Paragraph, ModalFooter, ModalFooterActions } from "@twilio-paste/core";
import { CallFailedIcon } from "@twilio-paste/icons/esm/CallFailedIcon";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { Trans, useTranslation } from "react-i18next";
import { Role } from "@mmc/conferencing-booking-client";


interface ParticipantListItemProps {
    participant: IParticipant;
    isLocal: boolean;
	isPhone: boolean;
	displayName: string;
	institution?: string;
	counter?: number;
}

export const ParticipantListItem: FC<ParticipantListItemProps> = ({ participant, isLocal, isPhone, displayName, institution }: ParticipantListItemProps) => {
	const { t } = useTranslation();
	const { removeSpeaker } = useConferenceControlContext();
	const kickModalHeadingID = useUID();
	const { appState } = useAppState();
	const [isKickFromStageModalOpen, setIsKickFromStageModalOpen] = useState(false);
	const openKickFromStageModal = () => setIsKickFromStageModalOpen(true);
	const closeKickFromStageModal = () => setIsKickFromStageModalOpen(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { toaster } = useToasterContext();

	const kickSpeaker = async () => {
		if (!participant) {
			return;
		}

		setIsLoading(true);

		try {
			await removeSpeaker(participant?.identity);
			setIsLoading(false);
		} catch (err) {
			console.error(`Unable to remove speaker "${participant.identity}"`, err);
			setIsLoading(false);

			toaster.push({
				message: t("conference-ui.toasters.remove-from-stage-error", { user: splitIdentity(stripPhone(participant.identity)) }),
				variant: "error",
				dismissAfter: 5000
			});
		}
	};

	const isViewerPromotedToStage = Boolean(participant.identity.match(/(.+)-PI/));
	const isHostOrAdmin = participant.identity.startsWith("HI") || participant.identity.startsWith("XI");
	const isPlaybackBot = participant.identity.startsWith(PLAYBACKBOT_IDENTITY);
	const roleLabel = isViewerPromotedToStage ? t("conference-ui.paticipants-tab.tag-participant") : isHostOrAdmin ? t("conference-ui.paticipants-tab.tag-host") : t("conference-ui.paticipants-tab.tag-speaker");

	const hasPermissionToKickSpeaker = appState.participantType === Role.Admin || appState.participantType === Role.Host;
	const canKickSpeaker = !isLocal && !isPlaybackBot && hasPermissionToKickSpeaker;

	return (
		<><Box display="flex" flex={1} flexDirection="row" flexWrap="nowrap" maxHeight="45px" paddingX="space40" columnGap="space30" borderStyle="solid" borderWidth="borderWidth10" borderColor="colorBorderDecorative10Weaker" alignItems="center">
			<Box width="25%" display="flex" flexDirection="row" flexWrap="nowrap" columnGap="space30" paddingY="space30" alignItems="center">
				<Box alignItems="center" height="100%" display="flex" alignContent="center">
					<Text as="p" fontSize="fontSize20">{splitIdentity(stripPhone(displayName))}</Text>
				</Box>
				<Box>
					{isLocal
						? <Badge variant="new" as="span">
							{isPhone
								? <ProductVoiceIcon decorative={false} title={t("conference-ui.paticipants-tab.voice-participant")} />
								: <ProductLiveIcon decorative={false} title={t("conference-ui.paticipants-tab.live-participant")} />} {t("conference-ui.paticipants-tab.tag-you")}</Badge>
						: <Badge variant="info" as="span">
							{isPhone
								? <ProductVoiceIcon decorative={false} title={t("conference-ui.paticipants-tab.voice-participant")} />
								: <ProductLiveIcon decorative={false} title={t("conference-ui.paticipants-tab.live-participant")} />} {roleLabel}</Badge>}
				</Box>
			</Box>

			<Box width="25%" display="flex" flexDirection="row" flexWrap="nowrap" columnGap="space30" paddingY="space30" alignItems="center" />

			<Box width="25%" display="flex" flexDirection="row" flexWrap="nowrap" columnGap="space30" paddingY="space30" alignItems="center">
				<Box alignItems="center" height="100%" display="flex" alignContent="center">
					<Text as="p" fontSize="fontSize20">{institution || "N/A"}</Text>
				</Box>
			</Box>

			<Box width="25%" display="flex" flexDirection="row" columnGap="space30" alignItems="center" justifyContent="flex-end">
				{canKickSpeaker && <Button onClick={openKickFromStageModal} variant={isViewerPromotedToStage ? "primary":"destructive"} size="icon_small" loading={isLoading}>
					{isViewerPromotedToStage
						? <DownloadIcon decorative={false} title={t("conference-ui.paticipants-tab.remove-from-stage-button")} />
						: <CallFailedIcon decorative={false} title={t("conference-ui.paticipants-tab.remove-from-conference-button")} />}
				</Button>}

				{participant && <ParticipantIndicators participant={participant} isLocal={isLocal} isPhone={isPhone} />}
			</Box>
		</Box>

		<Modal ariaLabelledby={kickModalHeadingID} isOpen={isKickFromStageModalOpen} onDismiss={closeKickFromStageModal} size="default">
			<ModalHeader>
				<ModalHeading as="h3" id={kickModalHeadingID}>
					{isViewerPromotedToStage
						? t("conference-ui.paticipants-tab.remove-participant-from-stage-title")
						: isHostOrAdmin
							? t("conference-ui.paticipants-tab.remove-host-from-conference-title")
							: t("conference-ui.paticipants-tab.remove-speaker-from-conference-title")
					}
				</ModalHeading>
			</ModalHeader>
			<ModalBody>
				<Paragraph>
					{isViewerPromotedToStage
						? <Trans i18nKey="conference-ui.paticipants-tab.remove-from-stage">You are about to remove <strong>{{ user: splitIdentity(stripPhone(displayName)) } as any}</strong> from Stage.</Trans>
						: <Trans i18nKey="conference-ui.paticipants-tab.remove-from-conference">You are about to remove <strong>{{ user: splitIdentity(stripPhone(displayName)) } as any}</strong> from Conference.</Trans>
					}
				</Paragraph>
			</ModalBody>
			<ModalFooter>
				<ModalFooterActions>
					<Button variant="secondary" onClick={closeKickFromStageModal}>
						{t("conference-ui.paticipants-tab.cancel-button")}
					</Button>
					<Button variant="destructive" onClick={kickSpeaker}>
						{isViewerPromotedToStage
							? t("conference-ui.paticipants-tab.remove-from-stage-button")
							: t("conference-ui.paticipants-tab.remove-from-conference-button")
						}
					</Button>
				</ModalFooterActions>
			</ModalFooter>
		</Modal>
		</>
	);
}