import { FC, ReactNode } from "react";
import { Box } from "@twilio-paste/box";

interface IntroContainerProps {
  children: ReactNode;
  transparentBackground?: boolean;
}

export const IntroContainer: FC<IntroContainerProps> = (props: IntroContainerProps) => {


	return (
		<Box height="100vh" style={{ background: "#EBECF0" }}>
			<Box width="100%" height="fit-content" position="relative" display="flex" alignContent="center" justifyContent="center" alignItems="start" justifyItems="center" style={{ background: "#EBECF0" }}>
				<Box position="relative" display="flex" flexDirection={["column", "column", "row"]} width={["100%", "90%", "800px"]} height={["100vh", "auto", "auto"]} minHeight="200px"  borderRadius={["borderRadius0", "borderRadius30", "borderRadius30"]}  marginY={["space0", "space120", "space120"]} backgroundColor="colorBackgroundBody" boxShadow="shadow">
					<Box padding={"space50"} display="flex" justifyContent="start" flex={1} flexDirection="column">
						{props.children}
					</Box>
				</Box>
			</Box>
		</Box>);
};
