import { useContext } from "react";
import { SyncContext } from "../../components/SyncProvider/SyncProvider";
import { SyncContextType } from "../../types/twilio-sync";

export const useSyncContext = (): SyncContextType => {
	const context = useContext(SyncContext);
	if (!context) {
		throw new Error("useSyncContext must be used within a SyncProvider");
	}
	return context;
}
