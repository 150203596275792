import { FC, memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Player as TwilioPlayer } from "@twilio/live-player-sdk";
import { PlayerMenuBar } from "./PlayerMenuBar/PlayerMenuBar";
import { usePlayerContext } from "../../hooks/usePlayerContext/usePlayerContext";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { usePlayerState } from "../../hooks/usePlayerState/usePlayerState";
import { Box } from "@twilio-paste/box";
import { ENABLE_VIDEO } from "../../constants";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { useTranslation } from "react-i18next";

TwilioPlayer.telemetry.subscribe((data) => {
	const method = data.name === "error" ? "error" : "log";
	console[method](`[${data.type}.${data.name}] => ${JSON.stringify(data)}`);
});

const PlayerFunction: FC = () => {
	const { t } = useTranslation();
	const videoElRef = useRef<HTMLVideoElement>(null!);
	const { player, disconnect } = usePlayerContext();
	const state = usePlayerState();
	const { appState, appDispatch } = useAppState();
	const [welcomeMessageDisplayed, setWelcomeMessageDisplayed] = useState(false);
	const { toaster } = useToasterContext();

	useLayoutEffect(() => {
		if (player && state === TwilioPlayer.State.Ready) {
			appDispatch({ type: "set-is-loading", isLoading: false });

			player.attach(videoElRef.current);
			player.play();
		}
	}, [player, appDispatch, state]);

	useEffect(() => {
		if (!welcomeMessageDisplayed) {
			setWelcomeMessageDisplayed(true);
			toaster.push({
				message: t("conference-ui.toasters.raise-your-hand"),
				variant: "neutral",
				dismissAfter: 4000
			});
		}
		// TODO: Make sure it is not affects current flow - Not Sure about toaster and t, make sure adding them to useEffect deps will not cause any issues, if so, add eslint ignore and remove them from deps
	}, [welcomeMessageDisplayed, toaster, t]);

	return (
		<Box height="100vh">
			<Box backgroundColor="colorBackgroundBodyInverse" display={!ENABLE_VIDEO ? "none" : undefined}>
				<video style={{ height: "100%", width: "100%" }} ref={videoElRef} playsInline></video>
			</Box>
			<PlayerMenuBar roomName={appState.conferenceId} disconnect={disconnect} />
		</Box>
	);
}

export const Player = memo(PlayerFunction);
