import { useEffect, useRef, useState, ChangeEvent, KeyboardEvent, FC } from "react";
import { Conversation } from "@twilio/conversations";
import { isMobile } from "../../../../core/utils";
import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/core/box";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { CheckboxCheckIcon } from "@twilio-paste/icons/esm/CheckboxCheckIcon";
import { ALLOWED_FILE_TYPES } from "../../../../constants";
import { useToasterContext } from "../../../../hooks/useToasterContext/useToasterContext";
import { useTranslation } from "react-i18next";


interface ChatInputProps {
  conversation: Conversation;
  isChatPanelFocus: boolean;
}

export const ChatInput: FC<ChatInputProps> = ({ conversation, isChatPanelFocus }: ChatInputProps) => {
	const { t } = useTranslation();
	const [messageBody, setMessageBody] = useState("");
	const [isSendingFile, setIsSendingFile] = useState(false);
	const isValidMessage = /\S/.test(messageBody);
	const textInputRef = useRef<HTMLTextAreaElement>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [, setIsTextareaFocused] = useState(false);
	const { toaster } = useToasterContext();

	useEffect(() => {
		if (isChatPanelFocus) {
			// When the chat window is opened, we will focus on the text input.
			// This is so the user doesn't have to click on it to begin typing a message.
			textInputRef.current?.focus();
		}
	}, [isChatPanelFocus]);

	const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setMessageBody(event.target.value);
	};

	const handleSendMessage = (message: string) => {
		if (isValidMessage) {
			conversation.sendMessage(message.trim());
			setMessageBody("");
		}
	};

	// ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
	const handleReturnKeyPress = (event: KeyboardEvent) => {
		if (!isMobile && event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			handleSendMessage(messageBody);
		}
	};

	const handleSendFile = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const formData = new FormData();
			formData.append("userfile", file);
			setIsSendingFile(true);
			conversation
				.sendMessage(formData)
				.catch((e) => {
					if (e.code === 413) {
						toaster.push({
							message: t("conference-ui.toasters.large-file-size"),
							variant: "error",
							dismissAfter: 4000
						});
					} else {
						toaster.push({
							message:
							t("conference-ui.toasters.unable-to-upload"),
							variant: "error",
							dismissAfter: 4000
						});
					}
					console.error("Problem sending file: ", e);
				})
				.finally(() => {
					setIsSendingFile(false);
				});
		}
	};

	return (
		<Box>
			<Box display="flex" alignItems="center" columnGap="space40" boxSizing="content-box">
				{/*
        Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
        This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
        component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
        */}
				<textarea
					style={{ borderRadius: "4px", background: "transparent", border: "none", boxShadow: "rgb(136 145 170) 0px 0px 0px 1px", fontFamily: "inherit", display: "block", width: "100%", height: "76px", padding: "0.5rem 0.75rem", resize: "none" }}
					onKeyPress={handleReturnKeyPress}
					onChange={handleChange}
					aria-label="chat input"
					placeholder={t("conference-ui.chat-tab.write-a-message")}
					id="message"
					name="message"
					required
					value={messageBody}
					data-cy-chat-input
					ref={textInputRef}
					onFocus={() => setIsTextareaFocused(true)}
					onBlur={() => setIsTextareaFocused(false)}
				/>
				<Box display="flex" flexDirection="column" rowGap="space40">
					{/* Since the file input element is invisible, we can hardcode an empty string as its value.
        This allows users to upload the same file multiple times. */}
					<input
						ref={fileInputRef}
						type="file"
						style={{ display: "none" }}
						onChange={handleSendFile}
						value={""}
						accept={ALLOWED_FILE_TYPES}
					/>
					<Button
						type="button"
						variant="secondary"
						onClick={() => fileInputRef.current?.click()}
						// disabled={isSendingFile}
						loading={isSendingFile}
						size="icon_small"
					>
						<AttachIcon decorative={true} />
					</Button>

					{/* {isSendingFile && (
            <Spinner size="sizeIcon40" decorative={false} title={t("general.loading")} />
          )} */}

					<Button
						onClick={() => handleSendMessage(messageBody)}
						variant="primary"
						disabled={!isValidMessage}
						data-cy-send-message-button
						size="icon"
					>

						<CheckboxCheckIcon decorative={true} />
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
