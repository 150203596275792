import { CallMeTab } from "./CallMeTab";
import { FC, useEffect, useState } from "react";
import { Heading } from "@twilio-paste/heading";
import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { ENABLE_VIDEO } from "../../../constants";
import { JoinScreenProps } from "../../../types";
import { usePhoneNumberContext } from "../../../hooks/usePhoneNumberContext/usePhoneNumberContext";
import { useBookingContext } from "../../../hooks/useBookingContext/useBookingContext";
import { Tab, TabList, TabPanel, TabPanels, Tabs, useTabState } from "@twilio-paste/tabs";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { DeviceSelectionDialog } from "../../DeviceSelectionDialog/DeviceSelectionDialog";
import { ActivePreJoinScreen } from "../../../core/appReducer";
import { Separator } from "@twilio-paste/core/separator";
import { DeviceAudioTab } from "./DeviceAudioTab";
import { DialInTab } from "./DialInTab";
import { Alert, SkeletonLoader } from "@twilio-paste/core";
import { Trans, useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import { PhoneNumber } from "@mmc/conferencing-phonenumber-client";
import { Role } from "@mmc/conferencing-booking-client";

const useButtonClickTabState = () => {
	const tab = useTabState();
	return {
		...tab,
		baseId: "device-selection-tabs"
	};
};

export const DeviceSelectionScreen: FC<JoinScreenProps> = ({ state, connect }: JoinScreenProps) => {

	const { isAcquiringLocalTracks } = useVideoContext();
	const { getAudioAndVideoTracks } = useVideoContext();
	const { appState } = useAppState();
	const [mediaError, setMediaError] = useState<Error>();
	const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
	const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
	const { list } = usePhoneNumberContext();
	const { conference, booking, isLoading: isBookingContextLoading } = useBookingContext();
	const selectedId = useUID();
	const tab1 = useUID();
	const tab2 = useUID();
	const tab3 = useUID();
	const { ...tab } = useButtonClickTabState();
	const { t } = useTranslation();

	useEffect(() => {
		if (appState.activePreJoinScreen === ActivePreJoinScreen.DeviceSelectionScreen && !mediaError && appState.participantType !== Role.Viewer) {
			getAudioAndVideoTracks().catch(error => {
				console.error("Error acquiring local media:");
				console.dir(error);
				setMediaError(error);
				tab.select(tab2);
			});
		}
	}, [getAudioAndVideoTracks, appState.activePreJoinScreen, appState.participantType, tab, tab2, mediaError]);


	useEffect(() => {
		(async () => {
			if(booking?.dialInGroups)
			{
				const numbers = await list(booking.dialInGroups.join(","));
				setPhoneNumbers(numbers);
			}
		})()
	}, [ booking?.dialInGroups, list ]);


	return (
		<Box position="relative" display="flex" flex={1} flexDirection="column">
			{appState.participantType !== Role.Viewer && booking?.status === "completed" && (
				<Box marginBottom="space60">
					<Alert variant="error">
						<Text as="span">
							<Trans i18nKey="choose-your-adventure-page.booking-status">The status of this booking is set to <strong>Completed</strong>. Please create a new booking or reset the status of this booking to <strong>Accepted</strong> and try again.</Trans>
						</Text>
					</Alert>
				</Box>
			)}
			<Box display="flex" flexDirection="row" justifyContent="space-between">
				<Box>
					{booking ? <>
						<Heading as="h4" variant="heading30" marginBottom="space0">
							{booking?.event.name}
						</Heading>
						<Text as="p">{conference && new Date(conference?.startDate).toLocaleString(undefined, { timeZoneName: "short" })}</Text>
					</>
						:
						<SkeletonLoader height="70px" width="200px"/>
					}
				</Box>
				<LanguageSelector />
			</Box>
			<Separator orientation="horizontal" verticalSpacing="space60" />
			{ appState.participantType === Role.Viewer && <Text as="p" role="status" aria-live="polite" fontWeight={"fontWeightSemibold"}>{t("choose-your-adventure-page.loading-message-register")} {appState.participantIdentity.split("_").join(" ")}</Text>}
			{ appState.participantType !== Role.Viewer && <Text as="p" role="status" aria-live="polite" fontWeight={"fontWeightSemibold"}>{t("choose-your-adventure-page.loading-message-joining")} {appState.participantIdentity.split("_").join(" ")}</Text>}
			<Text role="status" aria-live="polite"  as="p" marginBottom={"space60"}><Trans i18nKey="choose-your-adventure-page.sub-title">You can choose one of the following options to join this Conference Call:</Trans></Text>
			<Box height="100%" display="flex" flex="1 1 auto" flexDirection="column" flexWrap="nowrap">
				<Tabs selectedId={selectedId} state={tab} element="CIPTEX-TABS">
					<TabList aria-label="Device Selection">
						{appState.participantType !== Role.Viewer && <Tab id={tab1} disabled={isAcquiringLocalTracks || mediaError !== undefined}><Trans i18nKey="choose-your-adventure-page.tab1.title">Device Audio</Trans></Tab>}
						<Tab id={tab2}><Trans i18nKey="choose-your-adventure-page.tab2.title">Dial-In</Trans></Tab>
						<Tab id={tab3}><Trans i18nKey="choose-your-adventure-page.tab3.title">Call Me</Trans></Tab>
					</TabList>
					<TabPanels element="CIPTEX-TAB-PANEL">
						{appState.participantType !== Role.Viewer &&
						<TabPanel tabId={tab1} element="CIPTEX-TAB">
							{!mediaError && <DeviceAudioTab state={state} isAcquiringLocalTracks={isAcquiringLocalTracks} ENABLE_VIDEO={ENABLE_VIDEO} setDeviceSettingsOpen={setDeviceSettingsOpen} connect={connect} isLoadingConferenceDetails={isBookingContextLoading} />}
							<DeviceSelectionDialog open={deviceSettingsOpen} onClose={() => { setDeviceSettingsOpen(false) }} />
						</TabPanel>}
						<TabPanel tabId={tab2} element="CIPTEX-TAB">
							<DialInTab phoneNumbers={phoneNumbers} connect={connect} state={state} isLoadingConferenceDetails={isBookingContextLoading} />
						</TabPanel>
						<TabPanel element="CIPTEX-TAB" tabId={tab3}>
							<CallMeTab connect={connect} state={state} isLoadingConferenceDetails={isBookingContextLoading} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);

}
