import { FC } from "react";
import { Heading } from "@twilio-paste/heading";
import { Box } from "@twilio-paste/box";
import { useTranslation } from "react-i18next";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Loading } from "../Loading/Loading";

export const ConferenceEndedPage: FC = () => {
	const { t } = useTranslation();
	const { appState } = useAppState();

	return (
		<Box height="100vh" style={{ background: "#EBECF0" }}>
			<Box width="100%" height="fit-content" position="relative" display="flex" alignContent="center" justifyContent="center" alignItems="start" justifyItems="center" style={{ background: "#EBECF0" }}>
				<Box position="relative" display="flex" flexDirection={["column", "column", "row"]} width={["100%", "90%", "800px"]} height={["100vh", "auto", "auto"]} borderRadius={["borderRadius0", "borderRadius30", "borderRadius30"]}  marginY={["space0", "space120", "space120"]} backgroundColor="colorBackgroundBody" boxShadow="shadow">
					<Box padding={"space50"} display="flex" justifyContent="start" flex={1} flexDirection="column">
						<Box position="relative" display="flex" flexDirection="column">
							{appState.isLoading && <Loading />}

							{!appState.isLoading &&
								<Heading as="h1" variant="heading10" marginBottom="space0">
									{t("conference-ended-screen.heading")}
								</Heading>
							}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
