import { Anchor } from "@twilio-paste/core/anchor";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { FC } from "react";
import { Booking } from "@mmc/conferencing-booking-client";

interface OrganizerType {
  booking: Booking | undefined;
}

export const OrganizerInfo: FC<OrganizerType> = ({ booking }) => {
	return (
		<Box as="section" aria-labelledby="organizer-info-heading">
			<Box
				display="flex"
				flexDirection="row"
				flexWrap="wrap"
				flex="9"
				columnGap="space30"
				rowGap="space30"
				alignItems="center"
				role="region"
				aria-label="Organizer Details"
			>
				{booking?.organizer?.firstName && (
					<Text as="p" role="definition" aria-label="First Name">
						{booking?.organizer?.firstName}
					</Text>
				)}
				{booking?.organizer?.lastName && (
					<Text as="p" role="definition" aria-label="Last Name">
						{booking?.organizer?.lastName}
					</Text>
				)}
				{booking?.organizer?.phone && (
					<Text as="p" role="definition" aria-label="Phone Number">
						{booking?.organizer?.phone}
					</Text>
				)}
				{booking?.organizer?.company && (
					<Text as="p" role="definition" aria-label="Company">
						{booking?.organizer?.company}
					</Text>
				)}
				{booking?.organizer?.email && (
					<Anchor
						target="_blank"
						href={`mailto:${booking?.organizer?.email}`}
						aria-label="Email Address"
					>
						{booking?.organizer?.email}
					</Anchor>
				)}
			</Box>
		</Box>
	);
};