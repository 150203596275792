import { Box } from "@twilio-paste/box";
import { Heading } from "@twilio-paste/heading";
import { Text } from "@twilio-paste/text";
import { FC } from "react";
import { useBookingContext } from "../../../hooks/useBookingContext/useBookingContext";
import ReactMarkdown from "react-markdown"
import { RefreshDataButton } from "../../Buttons/RefreshDataButton/RefreshDataButton";
import { EventInformation } from "./EventInformation";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { Separator } from "@twilio-paste/core";
import { secondsToHms } from "../../../core/utils";
import { Role } from "@mmc/conferencing-booking-client";

export const ConferenceTab: FC = () => {
	const { conference, booking } = useBookingContext();
	const { appState } = useAppState();

	return (
		<Box position="relative" display="flex" flexDirection="column" flexWrap="nowrap" flex="1 1 auto" overflow="auto" height="calc(100vh - 255px)">
			<Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" padding="space40">
				<Box display="flex" flexDirection="column" alignItems='baseline' columnGap="space20">
					<Heading as="h3" variant="heading30" marginBottom="space0">{booking?.event.name}</Heading>
					<Text as="p">{conference && new Date(conference?.startDate).toLocaleString(undefined, { timeZoneName: "short" })} ({booking && secondsToHms(booking?.event?.duration)})</Text>
				</Box>
				<Box marginRight="space40">
					<RefreshDataButton />
				</Box>
			</Box>
			{(appState.participantType === Role.Admin || appState.participantType === Role.Host) &&
			<Box display="flex" padding="space40">
				<EventInformation />
			</Box>}
			<Separator orientation="horizontal" />
			<Box marginY="space60" padding="space40">
				{conference?.openingScript &&  <Text as="h3">Opening Script: </Text>}
				{conference?.openingScript && <ReactMarkdown>{conference?.openingScript}</ReactMarkdown>}
			</Box>
		</Box>
	);
};