
import { InnerContainer, OuterContainer } from "./NetworkQualityLevel.styles";
import { useParticipantNetworkQualityLevel } from "../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel";
import { FC } from "react";
import { ParticipantProps } from "../../types/twilio-video";

const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];

export const NetworkQualityLevel: FC<ParticipantProps> = ({ participant }: ParticipantProps) => {
	const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

	if (networkQualityLevel === null) return null;

	return (
		<OuterContainer>
			<InnerContainer>
				{BARS_ARRAY.map(level => (
					<div
						key={level}
						style={{
							height: `${STEP * (level + 1)}px`,
							background: networkQualityLevel > level ? "white" : "rgba(255, 255, 255, 0.2)"
						}}
					/>
				))}
			</InnerContainer>
		</OuterContainer>
	);
}
