import { MainParticipantInfo } from "../MainParticipantInfo/MainParticipantInfo";
import { ParticipantTracks } from "../ParticipantTracks/ParticipantTracks";
import { useMainParticipant } from "../../../hooks/useMainParticipant/useMainParticipant";
import { useSelectedParticipant } from "../../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import { useScreenShareParticipant } from "../../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { FC } from "react";

export const MainParticipant: FC = () => {
	const mainParticipant = useMainParticipant();
	const { room } = useVideoContext();
	const { localParticipant } = room!;
	const [selectedParticipant] = useSelectedParticipant();
	const screenShareParticipant = useScreenShareParticipant();

	const videoPriority =
		(mainParticipant === selectedParticipant ||
			mainParticipant === screenShareParticipant) &&
			mainParticipant !== localParticipant
			? "high"
			: null;

	return (
		/* audio is disabled for this participant component because this participant's audio
		   is already being rendered in the <ParticipantStrip /> component.  */
		<MainParticipantInfo participant={mainParticipant}>
			<ParticipantTracks
				participant={mainParticipant}
				videoOnly
				enableScreenShare={mainParticipant !== localParticipant}
				videoPriority={videoPriority}
				isLocalParticipant={mainParticipant === localParticipant}
			/>
		</MainParticipantInfo>);
}
