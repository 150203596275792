import { useEffect, useState } from "react";
import { Paginator, SyncMapItem } from "twilio-sync";
import { useSyncContext } from "../useSyncContext/useSyncContext";

export const useSpeakersMap = (): SyncMapItem[] => {
	const { speakersMap } = useSyncContext();
	const [speakers, setSpeakers] = useState<SyncMapItem[]>([]);

	const pageHandler =(paginator: Paginator<SyncMapItem>): any => {
		setSpeakers(paginator.items);
		return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
	};

	const getDuplicateSpeakers: (speakers: SyncMapItem[]) => SyncMapItem[] = (speakers) => {
		const objectCount: Record<string, number> = {};
		const duplicates: SyncMapItem[] = [];

		for (const viewer of speakers) {
			if (objectCount[viewer.key]) {
				if (objectCount[viewer.key] === 1) {
					duplicates.push(viewer);
				}
				objectCount[viewer.key] = objectCount[viewer.key] + 1;
			} else {
				objectCount[viewer.key] = 1;
			}
		}

		return duplicates;
	};

	useEffect(() => {
		if (speakersMap) {
			// Sets the list on load. Limiting to first 100 speakers who are raising their hand
			speakersMap.getItems({ pageSize: 100 }).then(pageHandler);

			const handleItemAdded = (args: any) => {
				console.log("SpeakersMap - Item Added", args, args?.item?.data);

				setSpeakers(prevSpeakers => [args.item, ...prevSpeakers]);

				const duplicateSpeakers = getDuplicateSpeakers([args.item, ...speakers]);

				if (duplicateSpeakers.length) {
					console.error("SpeakersMap - Duplicate speakers detected", duplicateSpeakers.map(viewer => viewer.key));
				}
			};

			const handleItemRemoved = (args: any) => {
				console.log("SpeakersMap - Item Removed", args.key);

				setSpeakers(prevSpeakers => prevSpeakers.filter(i => i.key !== args.key));
			};

			const handleItemUpdated = (args: any) => {
				try {
					console.log("SpeakersMap - Item Updated", args);

					setSpeakers(prevSpeakers => [args.item, ...prevSpeakers.filter(i => i.key !== args.item.key)]);
				} catch (error) {
					console.error(error);
				}

			};

			speakersMap.on("itemAdded", handleItemAdded);
			speakersMap.on("itemRemoved", handleItemRemoved);
			speakersMap.on("itemUpdated", handleItemUpdated);

			return () => {
				speakersMap.off("itemAdded", handleItemAdded);
				speakersMap.off("itemRemoved", handleItemRemoved);
				speakersMap.off("itemUpdated", handleItemUpdated);
			};
		}
		// TODO: Fix linting - pageHandler should be wrapped with useCallback and added to the list of dependencies
		//  speakers as well should be added in the deps, but doing all this things causes infinitive re-rendering as it creates circle dependencies
		// eslint-disable-next-line react-hooks/exhaustive-deps -- including pageHandler and speakers in the list of dependencies causes infinite re-rendering
	}, [speakersMap]);

	return speakers;
}
