import { Badge } from "@twilio-paste/core/badge";
import { Box } from "@twilio-paste/core/box";
import { FC } from "react";
import { Booking } from "@mmc/conferencing-booking-client";

interface OptionsType {
  booking: Booking | undefined;
}

export const OptionsInfo: FC<OptionsType> = ({ booking }) => {
	return (
		<Box as="section" aria-labelledby="options-info-heading">
			<Box
				display="flex"
				flexDirection="row"
				flexWrap="wrap"
				flex="9"
				columnGap="space30"
				rowGap="space30"
				role="region"
				aria-label="Conference Options"
			>
				{booking?.event.isHighAlert && (
					<Badge as="span" variant="info" role="status" aria-label="High Alert">
            High Alert
					</Badge>
				)}
				{booking?.options?.hasQaSession && (
					<Badge as="span" variant="info" role="status" aria-label="Q&A Session">
            Q&amp;A Session
					</Badge>
				)}
				{booking?.options?.hasVideo && (
					<Badge as="span" variant="info" role="status" aria-label="Video">
            Video
					</Badge>
				)}
				{booking?.options?.language && (
					<Badge as="span" variant="info" role="status" aria-label="Language">
						{booking.options.language}
					</Badge>
				)}
			</Box>
		</Box>
	);
};