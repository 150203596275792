import { useUID } from "@twilio-paste/core/dist/uid-library";
import { FC, useEffect, useRef } from "react";
import { AudioTrack as IAudioTrack } from "twilio-video";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useConferenceControlContext } from "../../hooks/useConferenceControlContext/useConferenceControlContext";

interface AudioTrackProps {
  track: IAudioTrack;
}

export const AudioTrack: FC<AudioTrackProps> = ({ track }: AudioTrackProps) => {
	const { activeSinkId } = useAppState();
	const audioEl = useRef<HTMLAudioElement>();
	const uid = useUID();
	// Hooks updating the playback bot state and playback timer.
	const { setIsPlaybackBotPlaying } = useConferenceControlContext();


	useEffect(() => {
		audioEl.current = track.attach();
		audioEl.current.setAttribute("data-cy-audio-track-name", track.name);
		audioEl.current.setAttribute("key", uid);
		if (track.name.startsWith("audio-playback")) {
			// For updating the playback bot state.
			setIsPlaybackBotPlaying(true);

			audioEl.current && (audioEl.current.ontimeupdate = () => {
				if (!audioEl.current) {
					return;
				}
				// This function is called every 250ms, to update the playback timer which shows the current playback time.
				// audioEl.current.addEventListener("timeupdate", () => {
				// 	setPlaybackTimer(audioEl.current?.currentTime);
				// })
			});
		}
		document.body.appendChild(audioEl.current);
		return () =>
			track.detach().forEach(el => {
				el.remove();
				// For updating the playback bot state.
				setIsPlaybackBotPlaying(false);

				// This addresses a Chrome issue where the number of WebMediaPlayers is limited.
				// See: https://github.com/twilio/twilio-video.js/issues/1528
				el.srcObject = null;
			});
		// TODO: Improvements - Find better solution for setIsPlaybackBotPlaying, avoiding adding to the dependencies as it is useless as method will never be changed
	}, [track, uid, setIsPlaybackBotPlaying]);

	useEffect(() => {
		audioEl.current?.setSinkId?.(activeSinkId);
	}, [activeSinkId]);

	return null;
}
