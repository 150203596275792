import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { UnsupportedBrowserWarning } from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import { ThemeProvider } from "./components/ThemeProvider/ThemeProvider";
import { ReportWebVitals } from "./core/ReportWebVitals";
import { Router } from "./core/Router";
import { initialiseSentry } from "./core/sentry";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

initialiseSentry();

// Find the root element
const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

// Create a root
const root = createRoot(rootElement);

// Render the app using the root.render method
root.render(
	<StrictMode>
		<ThemeProvider>
			<I18nextProvider i18n={i18n} defaultNS={"translation"}>
				<UnsupportedBrowserWarning>
					<Router />
				</UnsupportedBrowserWarning>
			</I18nextProvider>
		</ThemeProvider>
	</StrictMode>
);

// eslint-disable-next-line no-console
ReportWebVitals(console.log);
