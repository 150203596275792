import { useContext } from "react";
import { ConferenceControlContext } from "../../components/ConferenceControlProvider/ConferenceControlProvider";
import { ConferenceControlContextType } from "../../types/ciptex-sdk";

export const useConferenceControlContext = (): ConferenceControlContextType => {
	const context = useContext(ConferenceControlContext);
	if (!context) {
		throw new Error("useConferenceContext must be used within a ConferenceControlProvider");
	}
	return context;
}