import { ChatInput } from "./ChatInput/ChatInput";
import { MessageList } from "./MessageList/MessageList";
import { useChatContext } from "../../../hooks/useChatContext/useChatContext";
import { Box } from "@twilio-paste/box";
import { FC } from "react";

export const ChatTab: FC = () => {
	const { isChatPanelFocus, messages, conversation } = useChatContext();

	return (
		<Box position="relative" display="flex" flexDirection="column" flexWrap="nowrap" justifyContent="flex-end" overflow="hidden" >
			<MessageList messages={messages} />
			<Box paddingX="space40" marginY="space20">
				<ChatInput
					conversation={conversation!}
					isChatPanelFocus={isChatPanelFocus}
				/>
			</Box>
		</Box>
	);
}
