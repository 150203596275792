import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@twilio-paste/tabs";
import { ConferenceTab } from "./ConferenceTab/ConferenceTab";
import { ParticipantTab } from "./ParticipantTab/ParticipantTab";
import { useUID } from "react-uid";
import { ChatTab } from "./ChatTab/ChatTab";
import { Box } from "@twilio-paste/box";
import { FC } from "react";
import { Separator, useTabState } from "@twilio-paste/core";
import { PreRecordsTab } from "./PreRecordsTab/PreRecordsTab";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Text } from "@twilio-paste/core/text";
import { useTranslation } from "react-i18next";
import { Role } from "@mmc/conferencing-booking-client";

export const RightPanel: FC = () => {
	const { t } = useTranslation();
	const selectedId = useUID();
	const { appState } = useAppState();

	const useButtonClickTabState = () => {
		const tabStateL = useTabState();
		return {
			...tabStateL,
			baseId: "panel-tabs"
		};
	};

	const { ...tabState } = useButtonClickTabState();


	return (
		<Box height="100%" display="flex" flex={1}  minWidth="60vw">
			<Tabs variant="fitted" selectedId={selectedId} baseId="panel-tabs" element="CIPTEX-TABS" state={tabState}>
				<TabList aria-label="Host Options" >
					<Tab id={selectedId}>{t("conference-ui.conf-tab.conf")}</Tab>
					<Tab >{t("conference-ui.paticipants-tab.participants")}</Tab>
					{(appState.participantType === Role.Admin || appState.participantType === Role.Host) &&  <Tab >{t("conference-ui.pre-records-tab.pre-records")}</Tab>
					}
				</TabList>
				<TabPanels element="CIPTEX-TAB-PANEL">
					<TabPanel element="CIPTEX-TAB">
						<ConferenceTab />
					</TabPanel>
					<TabPanel element="CIPTEX-TAB">
						<ParticipantTab />
					</TabPanel>
					{(appState.participantType === Role.Admin || appState.participantType === Role.Host) &&  <TabPanel element="CIPTEX-TAB">
						<PreRecordsTab />
					</TabPanel>}
				</TabPanels>
			</Tabs>
			<Separator orientation="vertical" verticalSpacing="space0" />
			<Box display="flex" height="100%" flexDirection="column" flexWrap="nowrap" width="300px">
				<Box display="flex" justifyContent="center" alignContent="center" paddingY="space30" borderBottomColor="colorBorderWeak" borderBottomStyle="solid" borderBottomWidth="borderWidth10" height="39px" marginBottom="space0"><Text as="p" fontSize="fontSize40" fontWeight="fontWeightSemibold" color="colorTextDecorative10" marginTop="space20">{t("conference-ui.chat-tab.chat")}</Text></Box>
				<ChatTab />
			</Box>
		</Box>
	);
};