import { createContext, FC, useCallback, useState } from "react";
import { ReactElementProps } from "../../types";
import { PhoneNumberProviderContextType } from "../../types/ciptex-sdk";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { PhoneNumberClient } from "../../clients/phoneNumberClient";
import { Configuration, PhoneNumber } from "@mmc/conferencing-phonenumber-client";
import { PHONE_NUMBER_API_URL } from "../../constants";

export const PhoneNumberProviderContext = createContext<PhoneNumberProviderContextType>(null!);

export const PhoneNumberProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const { host, user, notified } = useAppState();
	const [phoneNumberClient, setPhoneNumberClient] = useState<PhoneNumberClient | undefined>(() => {
		const token = host?.token || user?.token || notified?.token;

		if (token) {
			const client = new PhoneNumberClient(new Configuration( {
				apiKey: `Bearer ${token}`,
				basePath: PHONE_NUMBER_API_URL
			}));
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.phoneNumberClient = client;

			return client;
		}

		return undefined;
	});

	const connect = useCallback(
		(token: string) => {
			try {
				const client = new PhoneNumberClient(new Configuration( {
					apiKey: `Bearer ${token}`,
					basePath: PHONE_NUMBER_API_URL
				}));
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				window.phoneNumberClient = client;
				setPhoneNumberClient(client);
			} catch (error: any) {
				console.error(error)
			}
		}, []);

	const list = useCallback(async (tags: string): Promise<PhoneNumber[]> => {
		if (!phoneNumberClient) {
			console.error("[CIPTEX] PhoneNumberProvider.list - PhoneNumberClient not initialised");
			return [];
		}
		return phoneNumberClient.phonenumber.listPhoneNumber({
			pageSize: 500,
			lastKey: undefined,
			tag: tags
		});
	}, [phoneNumberClient]);

	return <PhoneNumberProviderContext.Provider value={{ connect, list }}>{children}</PhoneNumberProviderContext.Provider>;
}