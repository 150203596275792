import { Room } from "twilio-video";
import { useEffect } from "react";
import { Callback } from "../../../types";

export const useHandleTrackPublicationFailed = (room: Room | null, onError: Callback): void => {
	useEffect(() => {
		if (room) {
			room.localParticipant.on("trackPublicationFailed", onError);
			return () => {
				room.localParticipant.off("trackPublicationFailed", onError);
			};
		}
	}, [room, onError]);
}
