import { createContext, FC, useCallback, useEffect, useState } from "react";
import { ReactElementProps } from "../../types";
import { BookingContextType } from "../../types/ciptex-sdk";
import { useAppState } from "../../hooks/useAppState/useAppState";
import {
	Booking,
	Conference,
	Configuration,
	Participant, PinPostRequest,
	Role,
	TelephonyPin,
	TelephonyPinDetail
} from "@mmc/conferencing-booking-client";
import { useNavigate } from "react-router-dom";
import { BookingClient } from "../../clients/bookingClient";
import { BOOKING_API_URL } from "../../constants";

enum SessionStateKeys {
	Booking = "notified_provider[booking]",
	Conference = "notified_provider[conference]",
	TelephonyPin = "notified_provider[telephonyPin]",
}

function loadFromSessionStorage<T>(key: string): T|undefined {
	const raw = sessionStorage.getItem(key);

	if (!raw) {
		return undefined;
	}

	try {
		return JSON.parse(raw) as T;
	} catch (e) {
		return undefined;
	}
}

export const BookingContext = createContext<BookingContextType>(null!);

export const BookingProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const { host, user, notified } = useAppState();
	const history = useNavigate();

	const [bookingClient, setBookingClient] = useState<BookingClient | undefined>(() => {
		const token = host?.token || user?.token || notified?.token;

		if (token) {
			const client = new BookingClient(new Configuration( {
				apiKey: `Bearer ${token}`,
				basePath: BOOKING_API_URL
			}));
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.notifiedClient = client;

			return client;
		}

		return undefined;
	});

	const [booking, setBooking] = useState<Booking | undefined>(loadFromSessionStorage<Booking>(SessionStateKeys.Booking));
	const [isLoading, setIsLoading ] = useState<boolean>(false);
	const [conference, setConference] = useState<Conference | undefined>(loadFromSessionStorage<Conference>(SessionStateKeys.Conference));
	const [telephonyPin, setTelephonyPin] = useState<TelephonyPin | undefined>(loadFromSessionStorage<TelephonyPin>(SessionStateKeys.TelephonyPin));
	const { appState, appDispatch } = useAppState();

	const getData = async () => {
		if (!appState.bookingId || !appState.conferenceId) {
			console.warn("BookingProvider - Not fetching data as missing booking and/or conference ID");
			return;
		}

		setIsLoading(true);
		const promise: [Promise<Booking>?, Promise<Conference>?] = [
			bookingClient?.booking.bookingBookingIdGet({ bookingId: appState.bookingId } ),
			bookingClient?.conference.conferenceConferenceIdGet({ conferenceId: appState.conferenceId })
		];
		const [b, c] = await Promise.all(promise);

		if (b) {
			setBooking(b);
		}

		if (c) {
			setConference(c);
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getData();

		(async () => {
			const isEligibleRole = (role: Role): boolean => {
				return role === Role.Admin || role === Role.Host || role === Role.Speaker;
			};
			const hasRoleThatRequiresPinGeneration = appState.participantType ? isEligibleRole(appState.participantType) : false;
			const doesntHavePin = !telephonyPin;
			const shouldGeneratePin = hasRoleThatRequiresPinGeneration && doesntHavePin;

			if (shouldGeneratePin) {
				/**
				 * When generating a PIN, for speakers we need to provide a Notified ID, whilst for hosts and admins we need to provide a Host ID.
				 *
				 * A caveat to this is for "external hosts", who have the role of Host but where we need to provide a Notified ID instead of a Host ID.
				 *
				 * Regular Hosts have an ID starting with "HI" whilst external hosts have ID's starting with "XI".
				 */
				const isExternalHost = appState.participantType === Role.Host && appState.participantId.startsWith("XI");
				const isRegularHostOrAdmin = (appState.participantType === Role.Host || appState.participantType === Role.Admin);
				const isSpeaker = appState.participantType === Role.Speaker;

				let idKeyName: string|null = null;

				if (isExternalHost || isSpeaker) {
					idKeyName = "notifiedId";
				} else if (isRegularHostOrAdmin) {
					idKeyName = "hostId";
				}

				try {
					const telephonyPinDetails: TelephonyPinDetail = {
						bookingId: appState.bookingId,
						conferenceId: appState.conferenceId,
						identity: appState.participantIdentity,
						notifiedId: idKeyName === "notifiedId" ? appState.participantId : undefined,
						hostId:  idKeyName === "hostId" ? appState.participantId : undefined
					}

					const pinPostRequest: PinPostRequest = {
						telephonyPinDetail: telephonyPinDetails
					}

					const telephonyPin = await bookingClient?.hostauth.pinPost(pinPostRequest);

					if (telephonyPin?.pin) {
						appDispatch({ type: "set-participant-pin", participantPin: telephonyPin.pin });
						setTelephonyPin(telephonyPin);
					}
				} catch (err: any) {
					// Speaker verified but outside of allowed access window
					if (err?.response?.status === 401) {
						history("/no-access", { replace: true });
						appDispatch({ type: "reset-state" });
						return;
					}

					throw err;
				}
			}
		})();
		// TODO: Fix linting - getData() should be wrapped in useCallback and added to the list of dependencies, or other solution should be found to fix the linter issue
		// eslint-disable-next-line react-hooks/exhaustive-deps -- to include getData() in the list of dependencies first it should be wrapped in useCallback
	}, [ appState.participantType, appState.bookingId, appState.conferenceId, appState.participantIdentity, appState.participantId, bookingClient?.hostauth, telephonyPin, history, appDispatch ]);

	useEffect(() => {
		if (booking) {
			sessionStorage.setItem(SessionStateKeys.Booking, JSON.stringify(booking));
		}
	}, [booking]);

	useEffect(() => {
		if (conference) {
			sessionStorage.setItem(SessionStateKeys.Conference, JSON.stringify(conference));
		}
	}, [conference]);

	useEffect(() => {
		if (telephonyPin) {
			sessionStorage.setItem(SessionStateKeys.TelephonyPin, JSON.stringify(telephonyPin));
		}
	}, [telephonyPin]);

	const connect = useCallback(async (token: string) => {
		try {
			const client = new BookingClient(new Configuration( {
				apiKey: `Bearer ${token}`,
				basePath: BOOKING_API_URL
			}));
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.notifiedClient = client;
			setBookingClient(client);

			if (appState.bookingId && appState.conferenceId && appState.participantId && appState.participantIdentity) {
				getData();

				const promise: [Promise<TelephonyPin>?] = [];

				if (appState.participantType === Role.Admin || appState.participantType === Role.Host || appState.participantType === Role.Speaker) {
					const pinPostRequest: PinPostRequest = {
						telephonyPinDetail: {
							bookingId: appState.bookingId,
							conferenceId: appState.conferenceId,
							identity: appState.participantIdentity,
							notifiedId: appState.participantType === Role.Speaker ? appState.participantId : undefined,
							hostId: appState.participantType === Role.Host ? appState.participantId : undefined
						} as TelephonyPinDetail
					}

					promise.push(bookingClient?.hostauth.pinPost(pinPostRequest));
				}

				const [p] = await Promise.all(promise);

				if (p) {
					appDispatch({ type: "set-participant-pin", participantPin: p?.pin });
					setTelephonyPin(p);
				}
			}
		} catch (error: any) {
			console.error(error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- to include getData() in the list of dependencies first it should be wrapped in useCallback
	}, [ appState.bookingId, appState.conferenceId, appState.participantIdentity, appState.participantId, appState.participantType, bookingClient?.hostauth, appDispatch ]);

	const getParticipant = useCallback(async (bookingId: string, participantId: string): Promise<Participant> => {
		const participants = await bookingClient?.participant.bookingBookingIdParticipantParticipantIdGet({
			bookingId: bookingId,
			participantId: participantId
		});
		if (participants) {
			return participants;
		}
		else {
			throw new Error("No Participants");
		}
	}, [bookingClient]);

	return <BookingContext.Provider value={{ connect, isLoading, booking, conference, telephonyPin, getData, getParticipant }}>{children}</BookingContext.Provider>;
}
