import { FC, PropsWithChildren } from "react";
import { Button } from "@twilio-paste/core/button";
import { Paragraph } from "@twilio-paste/core/paragraph";
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalFooterActions,
	ModalHeader,
	ModalHeading
} from "@twilio-paste/core/modal";

// TODO: Add Version Back in
// import { version as appVersion } from "../../../package.json";
import Video from "twilio-video";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Badge } from "@twilio-paste/core/badge";
import { Box } from "@twilio-paste/core/box";
import { useBookingContext } from "../../hooks/useBookingContext/useBookingContext";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { useChatContext } from "../../hooks/useChatContext/useChatContext";
import { splitIdentity } from "../../core/utils";
import { COMMIT_SHA } from "../../constants";
import { useTranslation } from "react-i18next";
import { Role } from "@mmc/conferencing-booking-client";

interface AboutDialogProps {
	open: boolean;
	onClose(): void;
}

export const AboutDialog: FC<AboutDialogProps> = ({ open, onClose }: PropsWithChildren<AboutDialogProps>) => {
	const { roomType } = useAppState();
	const { booking, conference } = useBookingContext();
	const { room } = useVideoContext();
	const { conversation } = useChatContext();
	const { appState } = useAppState();
	const { t } = useTranslation();

	const notifiedId = appState.participantType === Role.Speaker ? appState.participantId : undefined;
	const hostIdWithEmail = (appState.participantType === Role.Host || appState.participantType === Role.Admin) ? appState.participantId : undefined;
	const hostId = hostIdWithEmail ? splitIdentity(hostIdWithEmail) : undefined;

	// const [open, setIsOpen] = React.useState(false);
	// const handleOpen = () => setIsOpen(true);
	// const onClose = () => setIsOpen(false);

	return (
		<Modal ariaLabelledby="" isOpen={open} onDismiss={onClose} size="wide">
			<ModalHeader>
				<ModalHeading as="h3">{t("conference-ui.about-modal.title")}</ModalHeading>
			</ModalHeader>
			<ModalBody>
				{notifiedId && <Paragraph>
					<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
						<Box width="160px">NotifiedId:</Box> <Badge as="span" variant="new">{notifiedId}</Badge>
					</Box>
				</Paragraph>}
				{hostId && <Paragraph>
					<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
						<Box width="160px">HostId:</Box> <Badge as="span" variant="new">{hostId}</Badge>
					</Box>
				</Paragraph>}
				{booking && <>
					<Paragraph>
						<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
							<Box width="160px">AccountSid:</Box> <Badge as="span" variant="new">{booking?.accountSid}</Badge>
						</Box>
					</Paragraph>
					<Paragraph>
						<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
							<Box width="160px">BookingId:</Box> <Badge as="span" variant="new">{booking?.bookingId}</Badge>
						</Box>
					</Paragraph>
				</>}
				{conference && <Paragraph>
					<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
						<Box width="160px">ConferenceId:</Box> <Badge as="span" variant="new">{conference?.conferenceId}</Badge>
					</Box>
				</Paragraph>}
				{room && <Paragraph>
					<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
						<Box width="160px">RoomSid:</Box> <Badge as="span" variant="new">{room.sid}</Badge>
					</Box>
				</Paragraph>}
				{conversation && <Paragraph>
					<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
						<Box width="160px">ConversationSid:</Box> <Badge as="span" variant="new">{conversation.sid}</Badge>
					</Box>
				</Paragraph>}
				<Paragraph>
					<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
						<Box width="160px">Browser supported:</Box> <Badge as="span" variant="new">{String(Video.isSupported)}</Badge>
					</Box>
				</Paragraph>
				<Paragraph>
					<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
						<Box width="160px">SDK Version:</Box> <Badge as="span" variant="new">{Video.version}</Badge>
					</Box>
				</Paragraph>
				{COMMIT_SHA && <Paragraph>
					<Box display="flex" alignItems="center" flexDirection="row" columnGap="space30">
						<Box width="160px">Commit SHA:</Box> <Badge as="span" variant="new">{COMMIT_SHA}</Badge>
					</Box>
				</Paragraph>}

				{/* <Paragraph>App Version: {appVersion}</Paragraph> */}

				{roomType && (
					<Paragraph>Room Type: {roomType}</Paragraph>
				)}
			</ModalBody>
			<ModalFooter>
				<ModalFooterActions>
					<Button onClick={onClose} autoFocus variant="primary">{t("general.ok")}</Button>
				</ModalFooterActions>
			</ModalFooter>
		</Modal>
	);
}
