import { Spinner } from "@twilio-paste/spinner";
import { Flex } from "@twilio-paste/flex";
import { FC } from "react";

export const Loading: FC = () => {
	return (
		<Flex hAlignContent="center" vAlignContent="center" height="300px">
			<Spinner size="sizeIcon90"  decorative={false} title="Loading..." />
		</Flex>
	);
};
