import { Paragraph, Box, Button } from "@twilio-paste/core";
import { Dispatch, FC, SetStateAction } from "react";
import { appStateTypes } from "../../../core/appReducer";
import { ToggleAudioButton } from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import { ToggleVideoButton } from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import { LocalVideoPreview } from "./LocalVideoPreview/LocalVideoPreview";
import { useTranslation } from "react-i18next";
import { Role } from "@mmc/conferencing-booking-client";

interface DeviceAudioTabType {
    state: appStateTypes,
    isAcquiringLocalTracks: boolean,
	ENABLE_VIDEO: boolean,
	setDeviceSettingsOpen: Dispatch<SetStateAction<boolean>>,
	connect: (webrtc?: boolean | undefined) => void,
	isLoadingConferenceDetails: boolean
}

export const DeviceAudioTab: FC<DeviceAudioTabType> = ({ state, isAcquiringLocalTracks, ENABLE_VIDEO, setDeviceSettingsOpen, connect, isLoadingConferenceDetails }: DeviceAudioTabType) => {
	const { t } = useTranslation();
	const { roomSid } = state;

	const shouldDisableButton = () => {
		if (isAcquiringLocalTracks) {
			return true;
		}

		// If we'll be starting the room (as opposed to joining) we need to wait for conference details
		// to finish loading. This only applies to hosts and admins, as speakers cannot start the room.

		switch (state.participantType) {
		case Role.Admin:
		case Role.Host:
			// For admins and hosts, disable the button if the conference has not finished loading,
			// and if we don't yet know the state of the room.
			return isLoadingConferenceDetails;

		default:
			return false;
		}
	};

	const getJoinButtonText = (): string => {
		if (isLoadingConferenceDetails) {
			return t("choose-your-adventure-page.please-wait");
		}

		if (state.participantType === Role.Admin || state.participantType === Role.Host) {
			if (roomSid) {
				// We already have a room SID, suggesting the room was already running and we've reloaded the page.
				// In this case, show the join text.
				return t("choose-your-adventure-page.join-conference");
			}

			return t("choose-your-adventure-page.create-event-button");
		}

		return t("choose-your-adventure-page.join-conference");
	};

	return (<>
		<Paragraph>{t("choose-your-adventure-page.tab1.sub-title")}</Paragraph>
		<Box display="flex" flex={1} flexDirection={["column", "row", "row"]} columnGap="space60" justifyContent="space-between" alignContent="space-between" marginY="space80">
			<Box width={["100%", "50%", "50%"]} marginBottom={["space80", "space0", "space0"]}>
				<LocalVideoPreview identity={state.participantIdentity} />
			</Box>
			<Box display="flex" flex={1} flexDirection="column" alignSelf="end" justifySelf="end">
				<Box>
					<ToggleAudioButton disabled={isAcquiringLocalTracks} />
				</Box>
				<Box>
					{ENABLE_VIDEO && <ToggleVideoButton disabled={isAcquiringLocalTracks} />}
				</Box>
			</Box>
		</Box>
		<Box display="flex" flexDirection="row" alignContent="space-between" justifyContent="space-between">
			<Button variant="secondary" onClick={() => setDeviceSettingsOpen(true)} disabled={isAcquiringLocalTracks}>{t("choose-your-adventure-page.tab1.audio-settings-modal.modal-button")}</Button>
			<Button variant="primary" data-cy-join-now onClick={() => connect()} disabled={shouldDisableButton()}>
				{getJoinButtonText()}
			</Button>
		</Box>
	</>);
}
