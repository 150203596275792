import { FC, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuGroup, MenuSeparator, useMenuState } from "@twilio-paste/menu";

import { AboutDialog } from "../../AboutDialog/AboutDialog";
import { DeviceSelectionDialog } from "../../DeviceSelectionDialog/DeviceSelectionDialog";
import { ProductSettingsIcon } from "@twilio-paste/icons/esm/ProductSettingsIcon";
import { BackgroundSelectionDialog } from "../../BackgroundSelectionDialog/BackgroundSelectionDialog";
import { ENABLE_VIDEO } from "../.././../constants";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../PreJoinScreens/LanguageSelector";
import { ConferenceSettingsDialog } from "../../ConferenceSettingsDialog/ConferenceSettingsDialog";
import { Role } from "@mmc/conferencing-booking-client";

export const SettingsButton: FC = () => {
	const { t } = useTranslation();
	const [aboutOpen, setAboutOpen] = useState(false);
	const [conferenceSettingsOpen, setConferenceSettingsOpen] = useState(false);
	const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
	const [backgroundSettingsOpen, setBackgroundSettingsOpen] = useState(false);
	const { appState } = useAppState();
	const menu = useMenuState();

	const isHostOrAdmin = appState.participantType === Role.Admin || appState.participantType === Role.Host;
	const canAccessAboutDialog = isHostOrAdmin;
	const canViewConferenceSettings = isHostOrAdmin;

	return (
		<>
			<MenuButton {...menu} variant="secondary">
				<ProductSettingsIcon decorative={false} title={t("conference-ui-bottom-bar.settings")} />
			</MenuButton>

			<Menu {...menu} aria-label="Settings Actions">
				{/* Language selection */}
				<MenuGroup label={t("conference-ui-bottom-bar.menu-section-language")}>
					<MenuItem {...menu}><LanguageSelector showLabel={false} /></MenuItem>
				</MenuGroup>

				<MenuSeparator />

				{isHostOrAdmin && <>
					<MenuGroup label={t("conference-ui-bottom-bar.menu-section-host-tools")}>
						{/* About (only hosts and admins) */}
						{canAccessAboutDialog && <MenuItem {...menu} onClick={() => setAboutOpen(true)}>{t("conference-ui-bottom-bar.settings-about")}</MenuItem>}

						{/* Conference settings (only hosts and admins) */}
						{canViewConferenceSettings && <MenuItem {...menu} onClick={() => setConferenceSettingsOpen(true)}>{t("conference-ui-bottom-bar.settings-conference")}</MenuItem>}
					</MenuGroup>

					<MenuSeparator />
				</>}

				{/* Device settings */}
				<MenuItem {...menu} onClick={() => setDeviceSettingsOpen(true)}>{t("conference-ui-bottom-bar.settings-audio")}</MenuItem>

				{/* Video background settings */}
				{ENABLE_VIDEO && <MenuItem {...menu} onClick={() => setBackgroundSettingsOpen(true)}>Video Background Settings</MenuItem>}
			</Menu>

			{/* About dialog */}
			{canAccessAboutDialog && <AboutDialog open={aboutOpen} onClose={ () => setAboutOpen(false) } />}

			{/* Conference settings dialog */}
			{canViewConferenceSettings && <ConferenceSettingsDialog open={conferenceSettingsOpen} onClose={ () => setConferenceSettingsOpen(false) } />}

			{/* Device selection dialog */}
			<DeviceSelectionDialog open={deviceSettingsOpen} onClose={() => { setDeviceSettingsOpen(false) }} />

			{/* Video background dialog */}
			{ENABLE_VIDEO && <BackgroundSelectionDialog open={backgroundSettingsOpen} onClose={() => { setBackgroundSettingsOpen(false) }} />}
		</>
	);
}
