import { useTrack } from "../../../hooks/useTrack/useTrack";
import { AudioTrack } from "../../AudioTrack/AudioTrack";
import { VideoTrack } from "../../VideoTrack/VideoTrack";
import { DataTrack } from "../../DataTrack/DataTrack";

import { IVideoTrack } from "../../../types/twilio-video";
import { AudioTrack as IAudioTrack, DataTrack as IDataTrack, LocalTrackPublication, Participant, RemoteTrackPublication, Track } from "twilio-video";
import { FC } from "react";

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocalParticipant?: boolean;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
}

export const Publication: FC<PublicationProps> = ({ publication, isLocalParticipant, videoOnly, videoPriority }: PublicationProps) => {
	const track = useTrack(publication);

	if (!track) return null;

	switch (track.kind) {
	case "video":
		return (
			<VideoTrack
				track={track as IVideoTrack}
				priority={videoPriority}
				isLocal={track.name.includes("camera") && isLocalParticipant}
			/>
		);
	case "audio":
		return videoOnly ? null : <AudioTrack track={track as IAudioTrack} />;
	case "data":
		// We use videoOnly here because, like audio tracks, we only ever want one data track rendered at a time.
		return videoOnly ? null : <DataTrack track={track as IDataTrack} />
	default:
		return null;
	}
}
