import { FC } from "react";
import { Popover, PopoverBadgeButton, PopoverContainer } from "@twilio-paste/core";
import { InformationIcon } from "@twilio-paste/icons/esm/InformationIcon";
import { Text } from "@twilio-paste/core/text";

interface PopOverMakerType {
  title: string | number;
  description: string;
}

export const PopOverMaker: FC<PopOverMakerType> = ({ title, description }) => {
	return (
		<PopoverContainer baseId={`popover-${title}`}>
			<PopoverBadgeButton variant="info" aria-label={`Information about ${title}`}>
				<InformationIcon decorative={false} title="Information" />
				{title}
			</PopoverBadgeButton>
			<Popover aria-label={`Popover description for ${title}`} role="tooltip">
				<Text as="p">{description}</Text>
			</Popover>
		</PopoverContainer>
	);
};