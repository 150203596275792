import { Box } from "@twilio-paste/box";
import { FC } from "react";
import { MediaPlayer } from "../MediaPlayer/MediaPlayer";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { Role } from "@mmc/conferencing-booking-client";

export const PreRecordsTab: FC = () => {
	const { appState } = useAppState();
	return (
		<Box position="relative" display="flex" flexDirection="column" flexWrap="nowrap" flex="1 1 auto" overflow="auto" height="calc(100vh - 255px)" padding="space40">
			{(appState.participantType === Role.Admin || appState.participantType === Role.Host) && <MediaPlayer />}
		</Box>
	);
};