import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@twilio-paste/box";
import { useParticipants } from "../../../hooks/useParticipants/useParticipants";
import { ParticipantListItem } from "./ParticipantListItem/ParticipantListItem";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { useViewersMap } from "../../../hooks/useViewersMap/useViewersMap";
import { SyncMapItem } from "twilio-sync";
import { RemoteParticipant } from "twilio-video/tsdef/RemoteParticipant";
import { ViewerListItem } from "./ViewerListItem/ViewerListItem";
import { useSyncContext } from "../../../hooks/useSyncContext/useSyncContext";
import { List } from "react-movable";
import { Heading } from "@twilio-paste/heading";
import { Text } from "@twilio-paste/text";
import { isPhone, splitIdentity, stripPhone } from "../../../core/utils";
import { useToasterContext } from "../../../hooks/useToasterContext/useToasterContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { LocalParticipant, Participant } from "twilio-video";
import { useSpeakersMap } from "../../../hooks/useSpeakersMap/useSpeakersMap";
import { RemoteParticipantCounterProvider } from "./RemoteParticipantCounter";
import { useTranslation } from "react-i18next";
import { PLAYBACKBOT_IDENTITY } from "../../../constants";
import { Role } from "@mmc/conferencing-booking-client";

const usePrevious = <T, >(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
};

const getDuplicateParticipants: (participants: SyncMapItem[]) => SyncMapItem[] = (participants) => {
	const objectCount: Record<string, number> = {};
	const duplicates: SyncMapItem[] = [];

	for (const participant of participants) {
		if (objectCount[participant.key]) {
			if (objectCount[participant.key] === 1) {
				duplicates.push(participant);
			}
			objectCount[participant.key] = objectCount[participant.key] + 1;
		} else {
			objectCount[participant.key] = 1;
		}
	}

	return duplicates;
};

export const ParticipantTab: FC = () => {
	const { t } = useTranslation();
	const { participants } = useParticipants();
	const previousParticipants = usePrevious(participants);
	const { toaster } = useToasterContext();
	const viewersMap: SyncMapItem[] = useViewersMap();
	const speakersMap: SyncMapItem[] = useSpeakersMap();
	const { updateViewersMap } = useSyncContext();
	const { room } = useVideoContext();
	const { appState } = useAppState();
	const [onStageCount, setOnStageCount] = useState(0);

	const sortParticipants = (a: Participant, b: Participant) => {
		const nameA = splitIdentity(a.identity) || "";
		const nameB = splitIdentity(b.identity) || "";
		return nameA.localeCompare(nameB);
	};

	const sortRaisedHandViewers = (array: SyncMapItem[]) => {
		array.sort((a: any, b: any) => {
			const bothHaveSortOrder = a?.data?.sortOrder && b?.data?.sortOrder;

			if (bothHaveSortOrder) {
				return a.data.sortOrder - b.data.sortOrder;
			}

			return -1;
		});
	};

	const getRoleLabel = (participant: Participant) => {
		const isViewerPromotedToStage = Boolean(participant.identity.match(/-PI/));
		const isHostOrAdmin = participant.identity.startsWith("HI") || participant.identity.startsWith("XI");
		return isViewerPromotedToStage ? "participant" : isHostOrAdmin ? "host" : "speaker";
	}

	const filterByRole = (participant: Participant, targetRole: string) => {
		const roleLabel = getRoleLabel(participant);
		return roleLabel === targetRole ? participant : null;
	}

	const sortParticipantsTypes = (participant: Participant) => filterByRole(participant, "participant");
	const sortSpeakerTypes = (participant: Participant) => filterByRole(participant, "speaker");
	const sortHostTypes = (participant: Participant) => filterByRole(participant, "host");

	const raisedHandViewers = viewersMap
		.filter(viewer => (viewer.data as any).raiseHand === true);

	const nonRaisedHandViewers = viewersMap
		.filter(viewer => !(viewer.data as any).raiseHand)
		.sort((a, b) => {
			const nameA = (a?.data as any)?.name || "";
			const nameB = (b?.data as any)?.name || "";

			return nameA.localeCompare(nameB);
		});

	sortRaisedHandViewers(raisedHandViewers);

	const handleDragEnd = async ({ oldIndex, newIndex }: any): Promise<void> => {
		const item = raisedHandViewers?.[oldIndex];
		const hasBeenDraggedToBottom = newIndex === (raisedHandViewers.length - 1);
		const hasBeenDraggedToTop = newIndex === 0;
		const hasBeenDraggedUp = newIndex < oldIndex;

		console.log(`ParticipantTab - handleDragEnd - Old Index: ${oldIndex}`, `New Index: ${newIndex}`);

		const currentItemAtIndex: any = raisedHandViewers?.[newIndex];
		const itemAbove: any = raisedHandViewers?.[newIndex - 1];
		const itemBelow: any = raisedHandViewers?.[newIndex + 1];

		let sortOrder: number;

		if (hasBeenDraggedToTop) {
			sortOrder = (currentItemAtIndex?.data?.sortOrder / 2) || 1;
		} else if (hasBeenDraggedToBottom) {
			sortOrder = new Date().getTime();
		} else {
			// Dragged between items
			const sortOrderOfOldItemAtIndex = currentItemAtIndex?.data?.sortOrder;
			const sortOrderOfItemAbove = itemAbove?.data?.sortOrder;
			const sortOrderOfItemBelow = itemBelow?.data?.sortOrder;

			if (hasBeenDraggedUp) {
				// Has been dragged up, so need to check sort order of item above
				sortOrder = (sortOrderOfItemAbove + sortOrderOfOldItemAtIndex) / 2;
			} else {
				// Has been dragged down, so need to check sort order of item below
				sortOrder = (sortOrderOfItemBelow + sortOrderOfOldItemAtIndex) / 2;
			}
		}

		if (item) {
			(item as any).data.sortOrder = sortOrder;
			sortRaisedHandViewers(raisedHandViewers);

			const duplicates = getDuplicateParticipants(raisedHandViewers);

			if (duplicates.length) {
				console.error("ParticipantsTab - handleDragEnd - Duplicates in Q&A queue detected", duplicates.map(i => i.key));
			}

			await updateViewersMap(item, sortOrder);
		}
	}

	useEffect(() => {
		const isAdminOrHost = appState.participantType === Role.Admin || appState.participantType === Role.Host;
		const newParticipants = participants.filter(participant => (previousParticipants || []).some(p => p.sid === participant.sid) === false);
		const removedParticipants = (previousParticipants || []).filter(previousParticipant => participants.some(p => p.sid === previousParticipant.sid) === false);

		if (isAdminOrHost) {
			const getDisplayName = (identity: string) => splitIdentity(stripPhone(identity));

			// On a participant coming to the stage
			newParticipants.forEach(participant => {
				// Avoid duplicate toasts
				toaster.pop("participant-joined");

				toaster.push({
					id: "participant-joined",
					message: t("conference-ui.toasters.brought-to-stage", { user: getDisplayName(participant.identity) }),
					variant: "neutral",
					dismissAfter: 2000
				});

				// If we have an existing toast where the user left the stage, remove it
				// One scenario where this is useful is if someone reloads the page- they'll
				// be removed from the stage briefly and then join the stage again, which can
				// look confusing as both leave and join toasts will show.
				toaster.pop( "participant-left");
			});

			// On a participant leaving the stage
			removedParticipants.forEach(participant => {
				// Avoid duplicate toasts
				toaster.pop( "participant-left");

				toaster.push({
					id: "participant-left",
					message: t("conference-ui.toasters.left-the-stage", { user: getDisplayName(participant.identity) }),
					variant: "neutral",
					dismissAfter: 10000
				});

				toaster.pop( "participant-joined");
			});
		}
		// TODO: Make sure it is not affects current flow - Not Sure about toaster and t, make sure adding them to useEffect deps will not cause any issues, if so, add eslint ignore and remove them from deps
	}, [participants.length, appState.participantType, participants, previousParticipants, toaster, t]);

	useEffect(() => {
		if (!participants) {
			setOnStageCount(0);
			return;
		}

		// When showing the count of people on stage, we need to exclude the playback bot,
		// and also account for the local participant (not present in the participants array).
		const totalParticipants = participants.filter(p => !p.identity.startsWith(PLAYBACKBOT_IDENTITY)).length;

		setOnStageCount(totalParticipants + 1);
	}, [participants.length, participants]);

	function getExtraParticipantData(participant: RemoteParticipant | LocalParticipant): { displayName: string, institution?: string } {
		const { identity } = participant;

		const syncDoc = speakersMap.find(doc => doc.key === identity);
		const data = syncDoc ? syncDoc.data as any : null;
		let displayName: string | undefined = identity;

		if (data?.name) {
			displayName = `${data?.name} (${splitIdentity(stripPhone(identity))})`;
		}

		return {
			displayName,
			institution: data?.institution
		};
	}

	return (
		<Box position="relative" display="flex" flexDirection="column" flexWrap="nowrap" flex="1 1 auto" overflow="auto" padding="space40" height="calc(100vh - 265px)">
			<RemoteParticipantCounterProvider>
				<Heading as="h5" variant="heading50">
					{t("conference-ui.paticipants-tab.stage")} ({onStageCount})
				</Heading>

				<Box display="flex" borderStyle="solid" borderWidth="borderWidth10" borderColor="colorBorderDecorative10Weaker" paddingX="space40" paddingY="space20" backgroundColor="colorBackgroundDecorative10Weakest">
					<Box width="25%"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-name")}</Text></Box>
					<Box width="25%" />
					<Box width="25%"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-institution-name")}</Text></Box>
					<Box width="25%" textAlign="right"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-actions")}</Text></Box>
				</Box>

				{room?.localParticipant && <ParticipantListItem
					isLocal={true}
					participant={room.localParticipant}
					displayName={getExtraParticipantData(room.localParticipant).displayName}
					isPhone={isPhone(room.localParticipant.identity)}
					key={room.localParticipant.sid} />}

				{participants.filter(sortHostTypes).sort(sortParticipants).map((participant: RemoteParticipant) => (<ParticipantListItem
					isLocal={false}
					isPhone={isPhone(participant.identity)}
					participant={participant}
					displayName={getExtraParticipantData(participant)?.displayName}
					institution={getExtraParticipantData(participant)?.institution}
					key={participant.sid} />
				))}

				{participants.filter(sortSpeakerTypes).sort(sortParticipants).map((participant: RemoteParticipant) => (<ParticipantListItem
					isLocal={false}
					isPhone={isPhone(participant.identity)}
					participant={participant}
					displayName={getExtraParticipantData(participant)?.displayName}
					institution={getExtraParticipantData(participant)?.institution}
					key={participant.sid} />
				))}

				{participants.filter(sortParticipantsTypes).sort(sortParticipants).map((participant: RemoteParticipant) => (<ParticipantListItem
					isLocal={false}
					isPhone={isPhone(participant.identity)}
					participant={participant}
					displayName={getExtraParticipantData(participant)?.displayName}
					institution={getExtraParticipantData(participant)?.institution}
					key={participant.sid} />
				))}

				<Box marginBottom="space30" marginTop="space40">
					<Heading as="h5" variant="heading50">
						{t("conference-ui.paticipants-tab.qa-queue")} ({raisedHandViewers.length})
					</Heading>
				</Box>
				{raisedHandViewers.length !== 0 ? (
					<Box display="flex" justifyContent="space-between" borderStyle="solid" borderWidth="borderWidth10" borderColor="colorBorderDecorative10Weaker" paddingX="space40" paddingY="space20" backgroundColor="colorBackgroundDecorative10Weakest">
						<Box width="25%"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-name")}</Text></Box>
						<Box width="25%"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-email")}</Text></Box>
						<Box width="25%"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-institution-name")}</Text></Box>
						<Box width="25%" textAlign="right"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-actions")}</Text></Box>
					</Box>) : null}
				{raisedHandViewers && <List
					values={raisedHandViewers}
					onChange={handleDragEnd}
					renderList={({ children, props, isDragged }) => <div style={{ cursor: isDragged ? "grabbing" : undefined }} {...props}>{children}</div>}
					renderItem={({ props, value, isDragged }) => <div style={{ cursor: isDragged ? "grabbing" : "grab" }} {...props} key={(value.data as any)?.userIdentity}>
						<ViewerListItem key={value?.key} item={value} draggable={true} />
					</div>}
				/>}
				<Box marginBottom="space30" marginTop="space40">
					<Heading as="h5" variant="heading50">
						{t("conference-ui.paticipants-tab.participants")} ({nonRaisedHandViewers.length})
					</Heading>
				</Box>
				{nonRaisedHandViewers.length !== 0 ? (
					<Box display="flex" borderStyle="solid" borderWidth="borderWidth10" borderColor="colorBorderDecorative10Weaker" paddingX="space40" paddingY="space20" backgroundColor="colorBackgroundDecorative10Weakest">
						<Box width="25%"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-name")}</Text></Box>
						<Box width="25%"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-email")}</Text></Box>
						<Box width="25%"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-institution-name")}</Text></Box>
						<Box width="25%" textAlign="right"><Text as="h1" fontSize="fontSize20">{t("conference-ui.paticipants-tab.table-head-actions")}</Text></Box>
					</Box>) : null}
				{nonRaisedHandViewers?.map((value: any) => <ViewerListItem key={value?.key} item={value} draggable={false} />)}
			</RemoteParticipantCounterProvider>
		</Box>
	);
}
