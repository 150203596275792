import { FC } from "react";
import Video from "twilio-video";
import { Text } from "@twilio-paste/text";
import { Anchor, Flex } from "@twilio-paste/core";
import { Card } from "@twilio-paste/card";
import { ReactElementProps } from "../../types";

export const UnsupportedBrowserWarning: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	if (!Video.isSupported) {
		return (
			<Flex vertical hAlignContent="center">
				<Card padding={["space80", "space80", "space180"]}>
					<Text
						textAlign="center"
						as="h3"
						fontSize="fontSize50"
						marginBottom="space50"
					>
            Browser or context not supported
					</Text>
					<Text textAlign="center" as="p">
            Please open this application in one of the{" "}
						<Anchor
							href="https://www.twilio.com/docs/video/javascript#supported-browsers"
							target="_blank"
							rel="noopener"
							showExternal
						>
              supported browsers
						</Anchor>
            .
						<br />
            If you are using a supported browser, please ensure that this app is
            served over a{" "}
						<Anchor
							href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts"
							target="_blank"
							rel="noopener"
							showExternal
						>
              secure context
						</Anchor>{" "}
            (e.g. https or localhost).
					</Text>
				</Card>
			</Flex>
		);
	}

	return children;
}
