import {
	BookingApi,
	ConferenceApi,
	Configuration,
	HostApi,
	HostAuthApi,
	ParticipantApi
} from "@mmc/conferencing-booking-client";

export class BookingClient {
	readonly booking: BookingApi;
	readonly conference: ConferenceApi;
	readonly host: HostApi;
	readonly hostauth: HostAuthApi;
	readonly participant: ParticipantApi;
	constructor(config: Configuration) {
		this.booking = new BookingApi(config);
		this.conference = new ConferenceApi(config);
		this.host = new HostApi(config);
		this.hostauth = new HostAuthApi(config);
		this.participant = new ParticipantApi(config);
	}
}
