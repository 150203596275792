import { FC } from "react";
import { Player } from "../Player/Player";
import { PreJoinScreens } from "../PreJoinScreens/PreJoinScreens";
import { useHeight } from "../../hooks/useHeight/useHeight";
import { usePlayerContext } from "../../hooks/usePlayerContext/usePlayerContext";
import { RoomState, useRoomState } from "../../hooks/useRoomState/useRoomState";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Box } from "@twilio-paste/box"
import { Room } from "../Room/Room";

export const ConferenceContainer: FC = () => {
	const roomState: RoomState = useRoomState();
	const { player } = usePlayerContext();
	const { appState } = useAppState();

	// Here we would like the height of the main container to be the height of the viewport.
	// On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
	// not the viewport. This looks bad when the mobile browsers location bar is open.
	// We will dynamically set the height with 'window.innerHeight', which means that this
	// will look good on mobile browsers even after the location bar opens or closes.
	const height = useHeight();

	return (
		<Box height={height}>
			{((roomState === RoomState.Disconnected && !player) || appState.hasSpeakerInvite) && <PreJoinScreens />}
			{(roomState !== RoomState.Disconnected) && <Room />}
			{(player && !appState.hasSpeakerInvite) && <Player />}
		</Box>
	);
}