import * as Sentry from "@sentry/react";
import { browserTracingIntegration, replayIntegration, captureConsoleIntegration } from "@sentry/react";
import {
	SENTRY_ENVIRONMENT,
	SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
	SENTRY_REPLAY_SESSION_SAMPLE_RATE,
	SENTRY_TRACE_SAMPLE_RATE,
	SENTRY_SAMPLE_RATE,
	COMMIT_SHA,
	SENTRY_TARGET
} from "../constants";

export function initialiseSentry() {
	console.debug("Sentry config:", {
		environment: SENTRY_ENVIRONMENT,
		sampleRate: SENTRY_SAMPLE_RATE,
		tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
		replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
		replaysOnErrorSampleRate: SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
	});

	Sentry.init({
		dsn: SENTRY_TARGET,
		environment: SENTRY_ENVIRONMENT,
		release: COMMIT_SHA,
		integrations: [
			browserTracingIntegration(),
			replayIntegration({
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false
			}),
			captureConsoleIntegration({
				levels: ["error", "warn"]
			})
		],
		sampleRate: SENTRY_SAMPLE_RATE,

		// Performance Monitoring
		tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,

		// Session Replay
		replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
		replaysOnErrorSampleRate: SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
	});
}
