import { Configuration, ParticipantApi, RoomApi } from "@mmc/conferencing-conference-control-client";

export class ConferenceControlClient {
	readonly participant: ParticipantApi;
	readonly room: RoomApi;

	constructor(config: Configuration) {
		this.participant = new ParticipantApi(config);
		this.room = new RoomApi(config);
	}
}
