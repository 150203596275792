import { Dispatch, ReactElement } from "react";
import { appActionTypes, appStateTypes } from "./core/appReducer";
import { ConferenceControlClient } from "./clients/conferenceControlClient";
import { Role } from "@mmc/conferencing-booking-client";

declare global {
  interface Window {
    conferenceControlClient: ConferenceControlClient;
  }

  interface HTMLMediaElement {
    setSinkId?(sinkId: string): Promise<undefined>;
  }

  // Helps create a union type with TwilioError
  interface Error {
    code: undefined;
  }
}

export interface ButtonProps {
  disabled?: boolean;
  menuButton?: boolean;
}

export interface DialogProps {
	open: boolean;
	onClose: () => void;
}

export interface JoinScreenProps {
  state: appStateTypes;
  dispatch: Dispatch<appActionTypes>;
  connect: (webrtc?: boolean) => void;
}

export interface ReactElementProps {
	children: ReactElement;
}

export interface NotifiedUser {
  firstName: string;
  lastName: string;
  email: string;
}

export interface NotifiedToken extends NotifiedUser {
  token: string;
  role: Role;
  notifiedId: string;
}

export type Callback = (...args: any[]) => void;

export enum Priority {
	NoQA = -1,
	Standard = 0,
	Priority1 = 1,
	Priority2 = 2,
	Priority3 = 3,
	Priority4 = 4,
	Priority5 = 5,
}

