import { Room, TwilioError } from "twilio-video";
import { useEffect } from "react";
import { Callback } from "../../../types";
import { useToasterContext } from "../../../hooks/useToasterContext/useToasterContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const useHandleRoomDisconnection = (
	room: Room | null,
	onError: Callback,
	removeLocalAudioTrack: () => void,
	removeLocalVideoTrack: () => void,
	isSharingScreen: boolean,
	toggleScreenShare: () => void
): void => {
	const { toaster } = useToasterContext();
	const { t } = useTranslation();
	const history = useNavigate();
	const { appDispatch } = useAppState();

	useEffect(() => {
		if (room) {
			const onDisconnected = (_: Room, error: TwilioError) => {
				if (error?.code === 53118) {
					console.log("[CIPTEX] The event has been ended by the host");
					toaster.push({
						message: t("conference-ui.toasters.event-ended-by-host"),
						variant: "neutral",
						dismissAfter: 4000
					});

					history("/ended", { replace: true });
					appDispatch({ type: "reset-state" });
				} else if (error) {
					console.error(error);
					onError(error);
				}

				removeLocalAudioTrack();
				removeLocalVideoTrack();
				if (isSharingScreen) {
					toggleScreenShare();
				}
			};

			room.on("disconnected", onDisconnected);
			return () => {
				room.off("disconnected", onDisconnected);
			};
		}
		// TODO: Make sure it is not affects current flow - Not Sure about toaster and t, make sure adding them to useEffect deps will not cause any issues, if so, add eslint ignore and remove them from deps
	}, [
		room,
		onError,
		removeLocalAudioTrack,
		removeLocalVideoTrack,
		isSharingScreen,
		toggleScreenShare,
		appDispatch,
		history,
		toaster,
		t
	]);
}
