import { FC, ReactNode } from "react";
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from "twilio-video";
import { AudioLevelIndicator } from "../../AudioLevelIndicator/AudioLevelIndicator";
import { NetworkQualityLevel } from "../../NetworkQualityLevel/NetworkQualityLevel";
import { useIsTrackSwitchedOff } from "../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import { useParticipantIsReconnecting } from "../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import { usePublications } from "../../../hooks/usePublications/usePublications";
import { useScreenShareParticipant } from "../../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import { useTrack } from "../../../hooks/useTrack/useTrack";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";
import { Flex } from "@twilio-paste/flex";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { isPhone, splitIdentity, stripPhone } from "../../../core/utils";
import { PLAYBACKBOT_IDENTITY } from "../../../constants";
import { useTranslation } from "react-i18next";

interface MainParticipantInfoProps {
	participant: Participant;
	children: ReactNode;
}

export const MainParticipantInfo: FC<MainParticipantInfoProps> = ({ participant, children }: MainParticipantInfoProps) => {
	const { room } = useVideoContext();
	const { t } = useTranslation();
	const { localParticipant } = room!;
	const isLocal = localParticipant === participant;

	const screenShareParticipant = useScreenShareParticipant();
	const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

	const publications = usePublications(participant);

	const videoPublication = publications.find(p => p.trackName.includes("camera"));
	const screenSharePublication = publications.find(p => p.trackName.includes("video-composer-presentation"));
	const playbackPublication = publications.find(p => p.trackName.includes("video-playback"));

	const videoTrack = useTrack(screenSharePublication || videoPublication || playbackPublication);
	const isVideoEnabled = Boolean(videoTrack);

	const audioPublication = publications.find(p => p.kind === "audio");
	const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;

	const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
	const isParticipantReconnecting = useParticipantIsReconnecting(participant);

	const displayName = isPhone(participant.identity)
		? splitIdentity(stripPhone(participant.identity))
		: `${splitIdentity(participant.identity)} ${isLocal ? " (You)" : ""} ${screenSharePublication ? " - Screen" : ""}`

	return (
		<Box data-cy-main-participant data-cy-participant={isPhone(participant.identity) ? splitIdentity(stripPhone(participant.identity)) : splitIdentity(participant.identity)} position="relative" height="100%" display="flex" alignItems="center" style={{ gridArea: isRemoteParticipantScreenSharing ? "1 / 1 / 2 / 3" : "auto" }}>
			{!playbackPublication && <Box position="absolute" top={2} zIndex="zIndex10" style={{ background: "rgba(0, 0, 0, 0.5)", color: "white", padding: "0.18em 0.3em" }}>
				<Flex vAlignContent="center">
					{(!isPhone(participant.identity) && !participant.identity.startsWith(PLAYBACKBOT_IDENTITY)) && <NetworkQualityLevel participant={participant} />}
					<AudioLevelIndicator audioTrack={audioTrack} />
					<Text as="p" color="colorTextBrandInverse">{displayName}</Text>
				</Flex>
			</Box>}
			<Box position="absolute" top={0} bottom={0} width="100%" height="100%" overflow="hidden" >
				{(!isVideoEnabled || isVideoSwitchedOff) && (
					<Box display="flex" alignItems="center" justifyContent="center" minWidth="220px" minHeight="220px" backgroundColor="colorBackgroundDecorative10Weakest" borderColor="colorBorder" borderWidth="borderWidth20" borderStyle="solid">
						<Box backgroundColor="colorBackgroundBody" borderRadius="borderRadiusCircle" padding="space10">
							<UserIcon color="colorTextIcon" size="sizeIcon100" decorative={false} title={displayName} />
						</Box>
					</Box>
				)}
				{isParticipantReconnecting && (
					<div>
						<Text as="p" color="colorTextBrandInverse">
							{t("conference-ui.video-panel.reconnecting")}
						</Text>
					</div>
				)}
				{children}
			</Box>
		</Box>
	);
}
