import { FC, PropsWithChildren } from "react";
import { Button } from "@twilio-paste/core/button";
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalFooterActions,
	ModalHeader,
	ModalHeading
} from "@twilio-paste/core/modal";
import { useTranslation } from "react-i18next";
import { Switch, Text } from "@twilio-paste/core";
import { useSyncContext } from "../../hooks/useSyncContext/useSyncContext";
import { ConferenceSettings } from "../../core/appReducer";

interface ConferenceSettingsDialogProps {
	open: boolean;
	onClose(): void;
}

export const ConferenceSettingsDialog: FC<ConferenceSettingsDialogProps> = ({ open, onClose }: PropsWithChildren<ConferenceSettingsDialogProps>) => {
	const { t } = useTranslation();
	const { conferenceSettingsDocument } = useSyncContext();

	const conferenceSettings: ConferenceSettings = conferenceSettingsDocument?.data as ConferenceSettings || null;

	return (
		<Modal ariaLabelledby="" isOpen={open} onDismiss={onClose} size="wide">
			<ModalHeader>
				<ModalHeading as="h3">{t("conference-ui.conference-settings-modal.title")}</ModalHeading>
			</ModalHeader>

			<ModalBody>
				{/* Automatically swap participants on stage */}
				<Switch
					value="automaticallySwapParticipantsOnStage"
					disabled={!conferenceSettings}
					checked={conferenceSettings?.automaticallySwapParticipantsOnStage}
					onChange={() => {
						const newValue = !(conferenceSettings?.automaticallySwapParticipantsOnStage);

						console.log(`[ConferenceSettingsDialog] Setting automaticallySwapParticipantsOnStage to: ${newValue}`);

						conferenceSettingsDocument?.update({
							automaticallySwapParticipantsOnStage: newValue
						});
					}}
					helpText={
						<Text as="span" color="currentColor">
							{t("conference-ui.conference-settings-modal.auto-swap-participants-on-stage-help-text")}
						</Text>
					}
				>
					{t("conference-ui.conference-settings-modal.auto-swap-participants-on-stage-title")}
				</Switch>
			</ModalBody>

			<ModalFooter>
				<ModalFooterActions>
					<Button onClick={onClose} autoFocus variant="primary">{t("general.close")}</Button>
				</ModalFooterActions>
			</ModalFooter>
		</Modal>
	);
}
