import { FC, createContext, useCallback, useState } from "react";
import { Player as TwilioPlayer } from "@twilio/live-player-sdk";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { ReactElementProps } from "../../types";
import { PlayerContextType } from "../../types/twilio-player";

TwilioPlayer.setLogLevel(TwilioPlayer.LogLevel.Error);

export const PlayerContext = createContext<PlayerContextType>(null!);

export const PlayerProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const { onError } = useVideoContext();
	const [player, setPlayer] = useState<TwilioPlayer>();

	const connect = useCallback(
		async (token: string) => {
			const { protocol, host } = window.location;

			try {
				const newPlayer = await TwilioPlayer.connect(token, {
					playerWasmAssetsPath: `${protocol}//${host}/player`
				});
				setPlayer(newPlayer);
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				window.twilioPlayer = newPlayer;
			} catch (e) {
				console.error(e);
				onError(new Error("There was a problem connecting to the Twilio Live Stream."));
			}
		},
		[onError]
	);

	const disconnect = () => {
		if (player) {
			setPlayer(undefined);
			if (player.state !== TwilioPlayer.State.Ended) {
				player.disconnect();
			}
		}
	};

	return <PlayerContext.Provider value={{ connect, disconnect, player }}>{children}</PlayerContext.Provider>;
};
