import { useMemo } from "react";

export const useSanitizedId = (id: unknown, type: string) => {
	return useMemo(() => {
		if (typeof id !== "string" || id === "") {
			return null;
		}

		if(type === "booking") {
			const bookingIdPattern = /^BI[0-9a-fA-F]{32}$/;
			if (!bookingIdPattern.test(id)) {
				throw new Error(`Invalid bookingId format: "${id}"`);
			}
			return id;
		}

		const sanitizedId = encodeURIComponent(id.replace(/[^a-zA-Z0-9-_]/g, ""));
		if (id !== sanitizedId) {
			throw new Error(`Sanitization altered id - id "${id}", sanitizedId "${sanitizedId}"`);
		}

		return sanitizedId;
	}, [id, type]);
};
