import { useEffect, useState } from "react";
import { useVideoContext } from "../useVideoContext/useVideoContext";

export enum RoomState {
	Disconnected = "disconnected",
	Connected = "connected",
	Reconnecting = "reconnecting",
	Reconnected = "reconnected"
}

export const useRoomState = (): RoomState => {
	const { room } = useVideoContext();
	const [state, setState] = useState<RoomState>(RoomState.Disconnected);

	useEffect(() => {
		if (room) {
			const setRoomState = () => setState(room.state as RoomState);
			setRoomState();
			room
				.on(RoomState.Disconnected, setRoomState)
				.on(RoomState.Reconnected, setRoomState)
				.on(RoomState.Reconnecting, setRoomState);
			return () => {
				room
					.off(RoomState.Disconnected, setRoomState)
					.off(RoomState.Reconnected, setRoomState)
					.off(RoomState.Reconnecting, setRoomState);
			};
		}
	}, [room]);

	return state;
}
