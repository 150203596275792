/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useState } from "react";
import { ACCOUNT_SID, BOOKING_API_URL } from "../../constants";
import { HostAuth } from "../../types/ciptex-sdk";
import { NotifiedToken, NotifiedUser } from "../../types";
import { BookingClient } from "../../clients/bookingClient";
import { Configuration, Host, ParticipantVerify, Role } from "@mmc/conferencing-booking-client";

enum SessionStateKeys {
	UserAuth = "notified_auth[user]",
	HostAuth = "notified_auth[host]",
	NotifiedAuth = "notified_auth[notified]"
}

export enum AuthError {
	UnknownError = "UnknownError",
	BlankResponse = "BlankResponse",
	PasscodeMissing = "PasscodeMissing",
	PasscodeIncorrect = "PasscodeIncorrect",
	PasscodeExpired = "PasscodeExpired",
	ConferenceFinished = "ConferenceFinished"
}

const getErrorType = (message: string): AuthError => {
	switch (message?.toLowerCase()) {
	case "passcode incorrect":
	case "invalid pin":
		return AuthError.PasscodeIncorrect;
	case "passcode expired":
		return AuthError.PasscodeExpired;
	case "this conference has already finished":
		return AuthError.ConferenceFinished;
	default:
		return AuthError.UnknownError;
	}
}

function loadFromSessionStorage(key: string): any|null {
	const raw = sessionStorage.getItem(key);

	if (!raw) {
		return null;
	}

	try {
		return JSON.parse(raw);
	} catch (e) {
		return null;
	}
}

export const useCiptexAuth = () => {
	const [user, setUser] = useState<ParticipantVerify & { pwd: string } | null>(loadFromSessionStorage(SessionStateKeys.UserAuth));
	const [host, setHost] = useState<HostAuth | null>(loadFromSessionStorage(SessionStateKeys.HostAuth));
	const [notified, setNotified] = useState<NotifiedToken | null>(loadFromSessionStorage(SessionStateKeys.NotifiedAuth));
	const [isAuthReady, setIsAuthReady] = useState(false);

	const authNotified = useCallback(async (notifiedId: string, token: string, params: NotifiedUser) => {
		if(token && notifiedId) {
			const isExternalHost = notifiedId && notifiedId.startsWith("XI");

			setNotified({
				...params,
				token,
				role: isExternalHost ? Role.Host : Role.Speaker,
				notifiedId
			});
		}
	}, []);

	const authHost = useCallback(async (hostId: string, token: string) => {
		if(token && hostId)
		{
			const clientauthed = new BookingClient(new Configuration( {
				apiKey: `Bearer ${token}`,
				basePath: BOOKING_API_URL
			}));
			const hostL: Host = await clientauthed.host.hostHostIdGet({ hostId:hostId });
			setHost({
				...hostL,
				token
			});
		}
		else
		{
			throw new Error("NO HOST ID???");
		}
	}, []);

	const authViewer = useCallback(async (pwd: string) => {
		try {
			const client = new BookingClient(new Configuration( {
				basePath: BOOKING_API_URL
			}));

			const participantVerify: ParticipantVerify = await client.participant.verifyAccountSidPost({
				accountSid: ACCOUNT_SID,
				participantVerifyInput: { pin: pwd }
			});
			if (participantVerify) {
				setUser({ ...participantVerify, pwd });
			} else {
				throw new Error(AuthError.BlankResponse);
			}
		} catch (error: any) {
			const responseJson = await error.response.json();
			throw new Error(getErrorType(responseJson.message || error.body?.message || error.message));
		}
	}, []);

	const signOut = useCallback((): Promise<void> => {
		setUser(null);
		setHost(null);
		setNotified(null);
		return Promise.resolve();
	}, []);

	useEffect(() => {
		sessionStorage.setItem("notified_auth[user]", JSON.stringify(user) || "");
	}, [user]);

	useEffect(() => {
		sessionStorage.setItem("notified_auth[host]", JSON.stringify(host) || "");
	}, [host]);

	useEffect(() => {
		sessionStorage.setItem("notified_auth[notified]", JSON.stringify(notified) || "");
	}, [host, notified]);

	return { user, host, notified, isAuthReady, authNotified, authHost, authViewer, signOut };
}
