import { ParticipantList } from "./ParticipantList/ParticipantList";
import { MainParticipant } from "./MainParticipant/MainParticipant";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { TogglePanelButton } from "../Buttons/TogglePanelButton/TogglePanelButton";
import { Box } from "@twilio-paste/box";
import { Separator } from "@twilio-paste/separator";
import { FC } from "react";
import { Role } from "@mmc/conferencing-booking-client";

export const LeftPanel: FC = () => {
	const { appState } = useAppState();
	return (
		<Box position="relative" display="flex" flexDirection="column" flexWrap="nowrap" height="100%">
			<Box position="relative" display="flex" alignItems="center" >
				{/* <CallPanelDuration /> */}
				{(!appState.isParticipantWindowOpen && (appState.participantType === Role.Admin || appState.participantType === Role.Host || appState.participantType === Role.Speaker)) && <Box position="relative" top="0" right="0" marginX="space40">
					<TogglePanelButton />
				</Box>}
			</Box>
			<Box height="fill-available">
				<MainParticipant />
			</Box>
			<Separator orientation="horizontal" verticalSpacing="space0" />
			<ParticipantList />
		</Box>
	);
};
