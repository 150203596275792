import { Box } from "@twilio-paste/box";
import { AudioLevelIndicator } from "../../../AudioLevelIndicator/AudioLevelIndicator";
import { usePublications } from "../../../../hooks/usePublications/usePublications";
import { useTrack } from "../../../../hooks/useTrack/useTrack";
import { Participant as IParticipant, LocalAudioTrack, RemoteAudioTrack } from "twilio-video";
import { NetworkQualityLevel } from "../../../NetworkQualityLevel/NetworkQualityLevel";
import { FC, useState } from "react";
import { Button } from "@twilio-paste/core/dist/button";
import { useVideoContext } from "../../../../hooks/useVideoContext/useVideoContext";
import { PHONEOUT_PARTICIPANT_PREFIX, PHONE_PARTICIPANT_PREFIX, PLAYBACKBOT_IDENTITY } from "../../../../constants";
import { useConferenceControlContext } from "../../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useToasterContext } from "../../../../hooks/useToasterContext/useToasterContext";
import { splitIdentity, stripPhone } from "../../../../core/utils";
import { useTranslation } from "react-i18next";

interface ParticipantListItemProps {
	participant: IParticipant;
	isLocal: boolean;
	isPhone: boolean;
}

export const ParticipantIndicators: FC<ParticipantListItemProps> = ({ participant, isLocal, isPhone }: ParticipantListItemProps) => {
	const { t } = useTranslation();
	const publications = usePublications(participant);
	const audioPublication = publications.find(p => p.kind === "audio");
	const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
	const { room } = useVideoContext();
	const { muteTelephony } = useConferenceControlContext();
	const [loading, setLoading] = useState(false);
	const { toaster } = useToasterContext();

	const toggleMuteSpeaker = async () => {
		setLoading(true);

		const shouldMute = audioTrack !== null;

		try {
			if (participant.identity.startsWith(PHONE_PARTICIPANT_PREFIX || PHONEOUT_PARTICIPANT_PREFIX)) {
				await muteTelephony(participant.identity, shouldMute);
			} else {
				const [localDataTrackPublication] = [...room!.localParticipant.dataTracks.values()];
				// eslint-disable-next-line camelcase
				const messageString = JSON.stringify({ message_type: "mute", to_participant_identity: participant.identity });
				// Here we convert the message from stringified JSON to an ArrayBuffer. Sending/receiving ArrayBuffers
				// in the DataTracks helps with interoperability with the iOS Twilio Live App.
				const messageBuffer = new TextEncoder().encode(messageString).buffer;
				localDataTrackPublication.track.send(messageBuffer);
			}

			setLoading(false);
		} catch (err) {
			console.error(`Error toggling mute state for "${participant.identity}"`, err);
			setLoading(false);

			const identity = splitIdentity(stripPhone(participant.identity));

			toaster.push({
				message: shouldMute
					? t("conference-ui.toasters.mute-error", { user: identity })
					: t("conference-ui.toasters.unmute-error", { user: identity }),
				variant: "error",
				dismissAfter: 5000
			});
		}
	};

	const isPlaybackBot = participant.identity.startsWith(PLAYBACKBOT_IDENTITY);

	return (
		<Box display="flex" flexDirection="row" height="100%" columnGap="space30" alignItems="center">
			{(!isPhone && !isPlaybackBot) && <NetworkQualityLevel participant={participant} />}
			{(isLocal || isPlaybackBot) ?
				<AudioLevelIndicator audioTrack={audioTrack} isPhone={isPhone} color="black" /> :
				<Button onClick={toggleMuteSpeaker} variant="secondary" size="icon_small" loading={loading}>
					<AudioLevelIndicator audioTrack={audioTrack} color="black" />
				</Button>
			}
		</Box >
	);
}