import { Button } from "@twilio-paste/core/dist/button";
import { ButtonProps } from "../../../types";
import { FC } from "react";
import { LoadingIcon } from "@twilio-paste/icons/esm/LoadingIcon";
import { useBookingContext } from "../../../hooks/useBookingContext/useBookingContext";
import { useTranslation } from "react-i18next";

export const RefreshDataButton: FC<ButtonProps> = (props: ButtonProps) => {
	const { getData, isLoading } = useBookingContext();
	const { t } = useTranslation();

	return (
		<Button onClick={getData} variant={props.menuButton ? "reset" : "secondary"} data-cy-disconnect loading={isLoading}>
			<LoadingIcon decorative={false} title={t("conference-ui.conf-tab.refresh")} />
		</Button>
	)
};
