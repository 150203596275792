import { FC, ReactNode } from "react";
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from "twilio-video";

import { AudioLevelIndicator } from "../../AudioLevelIndicator/AudioLevelIndicator";
import { NetworkQualityLevel } from "../../NetworkQualityLevel/NetworkQualityLevel";
import { ScreenShareIcon } from "@twilio-paste/icons/esm/ScreenShareIcon";

import { useIsTrackSwitchedOff } from "../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import { usePublications } from "../../../hooks/usePublications/usePublications";
import { useTrack } from "../../../hooks/useTrack/useTrack";
import { useParticipantIsReconnecting } from "../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import { Tooltip } from "@twilio-paste/tooltip";
import { PinIcon } from "@twilio-paste/icons/esm/PinIcon";
import { Box } from "@twilio-paste/box";
import { Flex } from "@twilio-paste/flex";
import { Text } from "@twilio-paste/text";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { isPhone, splitIdentity, stripPhone } from "../../../core/utils";
import { useTranslation } from "react-i18next";

interface ParticipantInfoProps {
	participant: Participant;
	children: ReactNode;
	onClick?: () => void;
	isSelected?: boolean;
	isLocalParticipant?: boolean;
	hideParticipant?: boolean;
}

export const ParticipantInfo: FC<ParticipantInfoProps> = ({
	participant,
	isSelected,
	children,
	isLocalParticipant,
	hideParticipant
}: ParticipantInfoProps) => {
	const publications = usePublications(participant);
	const { t } = useTranslation();

	const audioPublication = publications.find(p => p.kind === "audio");
	const videoPublication = publications.find(p => p.trackName.includes("camera"));

	const isVideoEnabled = Boolean(videoPublication);
	const isScreenShareEnabled = publications.find(p => p.trackName.includes("video-composer-presentation"));

	const videoTrack = useTrack(videoPublication);
	const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

	const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
	const isParticipantReconnecting = useParticipantIsReconnecting(participant);

	const displayName = isPhone(participant.identity)
		? splitIdentity(stripPhone(participant.identity))
		: `${splitIdentity(participant.identity)} ${isLocalParticipant && " (" + t("conference-ui.paticipants-tab.tag-you") + ")"}`

	return (
		<Box hidden={hideParticipant} data-cy-participant={isPhone(participant.identity) ? splitIdentity(stripPhone(participant.identity)) : splitIdentity(participant.identity)} position="relative" marginX="space10" minWidth="280px">
			<Box position="absolute" width="100%" bottom={0} zIndex="zIndex10" style={{ background: "rgba(0, 0, 0, 0.5)", color: "white", padding: "0.18em 0.3em" }}>
				<Flex vAlignContent="center">
					{!isPhone(participant.identity) && <NetworkQualityLevel participant={participant} />}
					{isScreenShareEnabled && (
						<ScreenShareIcon decorative={true} />
					)}
					<AudioLevelIndicator audioTrack={audioTrack} />
					<Text as="p" color="colorTextBrandInverse">{displayName}</Text>
					<div>{isSelected &&
						<Tooltip text={t("conference-ui.video-panel.unpin-prompt")} placement="top">
							<PinIcon decorative={true}  />
						</Tooltip>}
					</div>
				</Flex>
			</Box>
			<Box position="absolute" top={0} bottom={0} width="100%" height="100%" overflow="hidden" backgroundColor="colorBackgroundDecorative10Weakest" borderColor="colorBorder" borderWidth="borderWidth20" borderStyle="solid" >
				{(!isVideoEnabled || isVideoSwitchedOff) && (
					<Flex hAlignContent="center" vAlignContent="center" height="103px">
						<Box backgroundColor="colorBackgroundBody" borderRadius="borderRadiusCircle" padding="space10">
							<UserIcon color="colorTextIcon" size="sizeIcon90" decorative={false} title={displayName} />
						</Box>
					</Flex>
				)}
				{isParticipantReconnecting && (
					<div>
						<Text as="p" color="colorTextBrandInverse">
							{t("conference-ui.video-panel.reconnecting")}
						</Text>
					</div>
				)}
				{children}
			</Box>
		</Box>
	);
}
