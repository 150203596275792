import { createRef, FC, ReactElement, useEffect, useState } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Box } from "@twilio-paste/core/dist/box";
import { PanelContainer } from "./PanelContainer/PanelContainer";
import { LEFT_MIN_WIDTH, RIGHT_MIN_WIDTH } from "../../constants";
import { Role } from "@mmc/conferencing-booking-client";


export const SplitterContainer: FC<{ children: ReactElement[], videoWindowState: boolean}> = ({ children, videoWindowState }: { children: ReactElement[], videoWindowState: boolean}) => {
	const { appState, appDispatch } = useAppState();
	const [leftWidth, setLeftWidth] = useState<undefined | number>(undefined);
	const [separatorXPosition, setSeparatorXPosition] = useState<undefined | number>(undefined);
	const [dragging, setDragging] = useState(false);
	const splitPaneRef = createRef<HTMLDivElement>();
	const splitterRef = createRef<HTMLDivElement>();
	const rightRef = createRef<HTMLDivElement>();

	useEffect(() => {
		if(rightRef.current && splitterRef.current)
		{
			rightRef.current.style.display = appState.isParticipantWindowOpen ? "flex" : "none";
			splitterRef.current.style.display = appState.isParticipantWindowOpen ? "flex" : "none";
			setLeftWidth(appState.isParticipantWindowOpen ? window.screen.width - RIGHT_MIN_WIDTH : window.screen.width);
		}
		// TODO: Fix linting - Find better solution for splitterRef, rightRef as it is detected by eslint as a required useEffect dependency, but useRef should be tested first
		// eslint-disable-next-line react-hooks/exhaustive-deps -- splitterRef, rightRef: suggested solution is to replace "createRef" with "useRef"
	}, [appState.isParticipantWindowOpen]);

	const onMouseDown = (e: React.MouseEvent) => {
		setSeparatorXPosition(e.clientX);
		setDragging(true);
	};

	const onTouchStart = (e: React.TouchEvent) => {
		setSeparatorXPosition(e.touches[0].clientX);
		setDragging(true);
	};

	const onMove = (clientX: number) => {
		if (dragging && leftWidth && separatorXPosition && rightRef.current) {
			const newLeftWidth = leftWidth + clientX - separatorXPosition;

			if (newLeftWidth < LEFT_MIN_WIDTH) {
				setLeftWidth(LEFT_MIN_WIDTH);
				return;
			}

			if((window.screen.width - RIGHT_MIN_WIDTH) < RIGHT_MIN_WIDTH)
			{
				setLeftWidth(window.screen.width - RIGHT_MIN_WIDTH);
				return;
			}

			setSeparatorXPosition(clientX);

			if (splitPaneRef.current) {
				const splitPaneWidth = splitPaneRef.current.clientWidth;

				if (newLeftWidth > splitPaneWidth) {
					setLeftWidth(splitPaneWidth);
					return;
				}
			}

			setLeftWidth(newLeftWidth);
		}
	};

	const onMouseMove = (e: MouseEvent) => {
		e.preventDefault();
		onMove(e.clientX);
	};

	const onTouchMove = (e: TouchEvent) => {
		onMove(e.touches[0].clientX);
	};

	const onMouseUp = () => {
		setDragging(false);
	};

	useEffect(() => {
		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("touchmove", onTouchMove);
		document.addEventListener("mouseup", onMouseUp);

		return () => {
			document.removeEventListener("mousemove", onMouseMove);
			document.removeEventListener("touchmove", onTouchMove);
			document.removeEventListener("mouseup", onMouseUp);
		};
	});

	useEffect(() => {
		if(appState.participantType !== Role.Viewer)
		{
			setLeftWidth(window.screen.width - RIGHT_MIN_WIDTH);
		}
		else
		{
			setLeftWidth(window.screen.width);
		}
	}, [appState.participantType]);

	if(appState.participantType === Role.Viewer)
	{
		appDispatch({ type: "set-is-participant-window-open", isParticipantWindowOpen: false });
	}

	useEffect(() => {
		const divider = document.querySelector<HTMLDivElement>(".divider");

		if (!divider){
			return;
		}

		if (!videoWindowState) {
			divider.style.border = "none";
		} else {
			divider.style.border = "1px solid #eaeaea";
		}
	}, [videoWindowState]);



	return (
		<Box height="100%" display="flex" flexDirection="row" alignItems="flex-start" ref={splitPaneRef}>
			{/* to get the panel slider working again remove the value of the width and replace it with leftWidth variable */}
			<PanelContainer width={470} setWidth={setLeftWidth} display={videoWindowState ? "": "none"}>
				{children[0]}
			</PanelContainer>
			{appState.participantType !== Role.Viewer && <div ref={splitterRef} className="divider-hitbox" onMouseDown={onMouseDown} onTouchStart={onTouchStart} onTouchEnd={onMouseUp}>
				<div className="divider" />
			</div>}
			{appState.participantType !== Role.Viewer && <div ref={rightRef} style={{ height: "100%", display: "flex", flex: "1" }}>
				{children[1]}
			</div>}
		</Box>
	);
};
