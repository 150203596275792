import { FC, useState } from "react";
import { Button } from "@twilio-paste/button";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon"
import { ButtonProps } from "../../../types";
import { useConferenceControlContext } from "../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { usePlayerContext } from "../../../hooks/usePlayerContext/usePlayerContext";
import { useSyncContext } from "../../../hooks/useSyncContext/useSyncContext";
import { Modal, ModalHeader, ModalHeading, ModalBody, Paragraph, ModalFooter, ModalFooterActions } from "@twilio-paste/core";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { useChatContext } from "../../../hooks/useChatContext/useChatContext";
import { useTranslation } from "react-i18next";

export const LeaveEventButton: FC<ButtonProps> = (props: ButtonProps) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);
	const modalHeadingID = useUID();
	const { room } = useVideoContext();
	const { appState, appDispatch } = useAppState();
	const { switchToViewer } = useConferenceControlContext();
	const { connect: playerConnect } = usePlayerContext();
	const { connect: syncConnect, registerViewerDocument, disconnect: syncDisconnect } = useSyncContext();
	const { disconnect: chatDisconnect } = useChatContext();

	const changeToViewer = async (): Promise<void> => {
		const { token } = await switchToViewer();
		await playerConnect(token);
		syncConnect(token);
		registerViewerDocument(`user-${appState.participantId}`);
		appDispatch({ type: "set-is-temp-speaker", isTempSpeaker: false });
		room!.emit("setPreventAutomaticJoinStreamAsViewer");
		room!.disconnect();
	}

	const disconnect = async (): Promise<void> => {
			room!.emit("setPreventAutomaticJoinStreamAsViewer");
			room!.disconnect();
			syncDisconnect();
			chatDisconnect();
			appDispatch({ type: "reset-state" });
	}

	return (
		<>
			<Button
				onClick={handleOpen}
				variant={props.menuButton ? "destructive_secondary" : "destructive"}
				data-cy-disconnect>
				<CloseIcon decorative={true} /> {t("conference-ui-bottom-bar.leave-conf-button")}
			</Button>
			<Modal ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={handleClose} size="default">
				<ModalHeader>
					<ModalHeading as="h3" id={modalHeadingID}>
						{t("conference-ui-bottom-bar.leave-conf-button")}
					</ModalHeading>
				</ModalHeader>
				<ModalBody>
					<Paragraph>{t("conference-ui-bottom-bar.leave-conf-modal-text")}</Paragraph>
				</ModalBody>
				<ModalFooter>
					<ModalFooterActions>
						<Button variant="secondary" onClick={handleClose}>
							{t("conference-ui-bottom-bar.cancel-button")}
						</Button>
						<Button variant="destructive" onClick={appState.isTempSpeaker ? changeToViewer : disconnect} >{t("conference-ui-bottom-bar.leave-conf-button")}</Button>
					</ModalFooterActions>
				</ModalFooter>
			</Modal>
		</>

	);
}
