import { Box, Button, Table, TBody, Td, Th, THead, Tr, Text, SkeletonLoader, Badge, Anchor } from "@twilio-paste/core";
import {FC, useEffect, useMemo, useState} from "react";
import { appStateTypes } from "../../../core/appReducer";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { useBookingContext } from "../../../hooks/useBookingContext/useBookingContext";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useTranslation } from "react-i18next";
import { PhoneNumber } from "@mmc/conferencing-phonenumber-client";
import { Role } from "@mmc/conferencing-booking-client";


interface DialInTabType {
    phoneNumbers: PhoneNumber[],
    connect: (webrtc?: boolean | undefined) => void,
    state: appStateTypes,
	isLoadingConferenceDetails: boolean
}

interface PhoneNumberDisplayOverride {
	[phoneNumberSid: string]: string;
}

interface PhoneNumberDisplay {
	key: string;
	label: string;
	displayValue: string;
	isLocal: boolean;
	isoCode: string;
}

export const DialInTab: FC<DialInTabType> = ({ phoneNumbers, connect, state, isLoadingConferenceDetails }: DialInTabType) => {
	const { appState } = useAppState();
	const { t } = useTranslation();
	const { telephonyPin } = useBookingContext();

	// TODO: Should be moved to configurations??
	// useMemo Fix for "To fix this, wrap the initialization of 'phoneNumberDisplayOverrides' in its own useMemo() Hook   react-hooks/exhaustive-deps"
	const phoneNumberDisplayOverrides: PhoneNumberDisplayOverride = useMemo(() => ({
		// Override Taiwan display format (see ticket #23014)
		"PN3e71e31c697e12f4f6b6ed9eebb8c74b": "00801856952",

		// China number
		"PN378f44ecb7034324d361954e86d0b479": "10 8003 2002 06"
	}), []);

	const [phoneNumbersToDisplay, setPhoneNumbersToDisplay] = useState<PhoneNumberDisplay[]>();
	const [usTollFreeNumber, setUsTollFreeNumber] = useState<string | null>();

	useEffect(() => {
		const getLastItem = (str: string) => {
			const arr = str.split(" ");
			return arr[arr.length - 1];
		}

		const displayReadyPhoneNumbers: PhoneNumberDisplay[] = [...phoneNumbers]
			// Sort phone numbers by country name
			.sort((a, b) => {
				if (!a?.meta?.countryName || !b?.meta?.countryName) {
					return 0;
				}

				return a.meta.countryName > b.meta.countryName ? 1 : -1;
			})
			// Filter out US Toll free number(s)
			.filter((phoneNumber: any) => !phoneNumber.tags.includes("US-TOLLFREE"))
			// Parse into display format
			.map(phoneNumber => {
				const phoneNumberDisplay: PhoneNumberDisplay = {
					key: phoneNumber.phoneNumberSid,
					label: phoneNumber?.meta?.countryName || "",
					displayValue: parsePhoneNumber(phoneNumber.phoneNumber)?.number?.international || "",
					isLocal: getLastItem(phoneNumber.label || "").toLowerCase() === "local",
					isoCode: phoneNumber?.meta?.isoCountry || ""
				};

				// If a specific display override exists for this phone number, use that, else parse into standardised display format
				if (phoneNumberDisplayOverrides[phoneNumber.phoneNumberSid]) {
					phoneNumberDisplay.displayValue = phoneNumberDisplayOverrides[phoneNumber.phoneNumberSid];
				}

				return phoneNumberDisplay;
			})

		setPhoneNumbersToDisplay(displayReadyPhoneNumbers);
	}, [phoneNumbers, setPhoneNumbersToDisplay, phoneNumberDisplayOverrides]);

	useEffect(() => {
		const tollFreeNumber = phoneNumbers.find((x) => x.tags?.includes("US-TOLLFREE"));

		if (!tollFreeNumber) {
			setUsTollFreeNumber(null);
			return;
		}

		let displayValue = parsePhoneNumber(tollFreeNumber.phoneNumber).number?.international || "";

		// If a specific display override exists for this phone number, use that, else parse into standardised display format
		if (phoneNumberDisplayOverrides[tollFreeNumber.phoneNumberSid]) {
			displayValue = phoneNumberDisplayOverrides[tollFreeNumber.phoneNumberSid];
		}

		setUsTollFreeNumber(displayValue);
	}, [phoneNumbers, setUsTollFreeNumber, phoneNumberDisplayOverrides])

	const shouldDisableButton = () => {
		// If we'll be starting the room (as opposed to joining) we need to wait for conference details
		// to finish loading. For now we're just checking if the user's role is a host or admin (these are
		// the only roles that can start the room) but we should also check if the room has already been started.

		switch (state.participantType) {
		case Role.Admin:
		case Role.Host:
			// For admins and hosts, disable the button if the conference has not finished loading
			return isLoadingConferenceDetails;

		default:
			return false;
		}
	};

	return (<Box display="flex" flexDirection="column" alignContent="space-between" justifyContent="space-between" height="100%" rowGap="space60">
		<Box overflow="auto" display="flex" flex="1" flexDirection="column">
			{phoneNumbers ? (usTollFreeNumber && <Text as="p">
				<Text as="span" fontWeight={"fontWeightSemibold"}>
					{t("choose-your-adventure-page.tab2.us-toll-free-number")}
				</Text>
				<Anchor href={`tel:${usTollFreeNumber},,,${state.participantType === Role.Viewer ? `${appState.participantPin}` : `${telephonyPin?.pin}`}#`}>{usTollFreeNumber}</Anchor>
			</Text>) : <SkeletonLoader width="250px" height="17px"/>}
			{(appState.participantPin || telephonyPin?.pin) ?
				<Text as="p" marginY={"space40"} fontWeight="fontWeightSemibold">
					<Text as="span" fontWeight={"fontWeightSemibold"}>
						{t("choose-your-adventure-page.tab2.your-pin")}
					</Text>
					{state.participantType === Role.Viewer ? ` ${appState.participantPin}` : ` ${telephonyPin?.pin}`}
				</Text> : <SkeletonLoader width="250px" height="17px"/>}

			<Text as="p" marginBottom={"space40"}>{t("choose-your-adventure-page.tab2.pin-description")}</Text>

			{state.participantType !== Role.Viewer && <Box marginY="space50" display="flex" flexDirection="row" alignContent="end" justifyContent="end">
				<Button variant="primary" data-cy-join-now onClick={() => connect(false)} disabled={shouldDisableButton()}>
					{isLoadingConferenceDetails ?
						t("choose-your-adventure-page.please-wait") :
						state.participantType === Role.Admin || state.participantType === Role.Host ? t("choose-your-adventure-page.create-event-button") : t("choose-your-adventure-page.join-conference")
					}
				</Button>
			</Box>}

			<Text as="p" marginBottom={"space40"}>{t("choose-your-adventure-page.tab2.numbers-description")}</Text>

			<Box display="flex" flexDirection={"column"} columnGap="space60" justifyContent="space-between" alignContent="space-between" marginY="space80" width="100%">
				{phoneNumbersToDisplay ? <Table width="100%" striped>
					<THead>
						<Tr>
							<Th width="35%">{t("choose-your-adventure-page.tab2.table-head-country")}</Th>
							<Th width="65%" textAlign="right">{t("choose-your-adventure-page.tab2.table-head-numbers")}</Th>
						</Tr>
					</THead>
					<TBody>
						{phoneNumbersToDisplay.map((phoneNumber) =>
							<Tr key={phoneNumber.key}>
								<Td >
									<Box display="flex" justifyContent="start" alignItems="center" columnGap="space30">
										{t(`country-list.${phoneNumber.isoCode}`)}{phoneNumber.isLocal ?
											<Badge as="span" variant="decorative40">{t("choose-your-adventure-page.tab2.local")}</Badge> :
											<Badge as="span" variant="decorative20">{t("choose-your-adventure-page.tab2.toll-free")}</Badge>}

									</Box>
									{phoneNumber.key === "PN378f44ecb7034324d361954e86d0b479" ? (
										<strong style={{ fontSize: "12px" }}>{t("choose-your-adventure-page.tab2.china-note")}</strong>
									) : null}
								</Td>
								<Td textAlign="right">
									<Anchor href={`tel:${phoneNumber.displayValue},,,${state.participantType === Role.Viewer ? `${appState.participantPin}` : `${telephonyPin?.pin}`}#`}>{phoneNumber.displayValue}</Anchor>
								</Td>
							</Tr>)}
					</TBody>
				</Table> : <SkeletonLoader width="250px" height="17px"/>}
			</Box>
		</Box>
	</Box>);

}
