import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_AUDIO_INPUT_KEY, SELECTED_VIDEO_INPUT_KEY, ENABLE_VIDEO } from "../../../constants";
import { getDeviceInfo, isPermissionDenied } from "../../../core/utils";
import { useCallback, useState } from "react";
import Video, { CreateLocalTrackOptions, LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const useLocalTracks = () => {
	const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
	const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
	const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);
	const { appState } = useAppState();

	const getLocalAudioTrack = useCallback(async (deviceId?: string) => {
		const options: CreateLocalTrackOptions = {};

		if (deviceId) {
			options.deviceId = { exact: deviceId };
		}

		const newTrack = await Video.createLocalAudioTrack(options);
		setAudioTrack(newTrack);
		return newTrack;
	}, []);

	const getLocalVideoTrack = useCallback(async () => {
		const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

		const { videoInputDevices } = await getDeviceInfo();

		const hasSelectedVideoDevice = videoInputDevices.some(
			device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
		);

		const options: CreateLocalTrackOptions = {
			// eslint-disable-next-line @typescript-eslint/ban-types
			...(DEFAULT_VIDEO_CONSTRAINTS as {}),
			name: `camera-${Date.now()}`,
			...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } })
		};

		return Video.createLocalVideoTrack(options).then(newTrack => {
			setVideoTrack(newTrack);
			return newTrack;
		});
	}, []);

	const removeLocalAudioTrack = useCallback(() => {
		if (audioTrack) {
			audioTrack.stop();
			setAudioTrack(undefined);
		}
	}, [audioTrack]);

	const removeLocalVideoTrack = useCallback(() => {
		if (videoTrack) {
			videoTrack.stop();
			setVideoTrack(undefined);
		}
	}, [videoTrack]);

	const getAudioAndVideoTracks = useCallback(async () => {
		const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();

		if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
		if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();

		setIsAcquiringLocalTracks(true);

		const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
		const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

		const hasSelectedAudioDevice = audioInputDevices.some(
			device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
		);
		const hasSelectedVideoDevice = videoInputDevices.some(
			device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
		);

		// Temp workaround for missing permissions in PermissionName with Typescript 4.X
		const cameraPermission = "camera" as PermissionName;
		const micPermission = "microphone" as PermissionName;

		// In Chrome, it is possible to deny permissions to only audio or only video.
		// If that has happened, then we don't want to attempt to acquire the device.
		const isCameraPermissionDenied = await isPermissionDenied(cameraPermission);
		const isMicrophonePermissionDenied = await isPermissionDenied(micPermission);

		const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
		const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;

		const localTrackConstraints = {
			video: (ENABLE_VIDEO && shouldAcquireVideo) && {
				// eslint-disable-next-line @typescript-eslint/ban-types
				...(DEFAULT_VIDEO_CONSTRAINTS as {}),
				name: `camera-${Date.now()}`,
				...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } })
			},
			audio: shouldAcquireAudio &&
        (hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId! } } : hasAudioInputDevices)
		};

		return Video.createLocalTracks(localTrackConstraints)
			.then(tracks => {
				const newVideoTrack = tracks.find(track => track.kind === "video") as LocalVideoTrack;
				const newAudioTrack = tracks.find(track => track.kind === "audio") as LocalAudioTrack;
				if (ENABLE_VIDEO && newVideoTrack) {
					setVideoTrack(newVideoTrack);
					// Save the deviceId so it can be picked up by the VideoInputList component. This only matters
					// in cases where the user's video is disabled.
					window.localStorage.setItem(
						SELECTED_VIDEO_INPUT_KEY,
						newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? ""
					);
				}
				if (newAudioTrack) {
					// Audio track defaults to enabled, but if we're reloading the page we need to ensure the correct
					// initial status (i.e. disable it if the user was previously muted)
					if (appState.isMuted === true) {
						newAudioTrack.disable();
					}

					setAudioTrack(newAudioTrack);
				}

				// These custom errors will be picked up by the MediaErrorSnackbar component.
				if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
					const error = new Error();
					error.name = "NotAllowedError";
					throw error;
				}

				if (isCameraPermissionDenied) {
					throw new Error("CameraPermissionsDenied");
				}

				if (isMicrophonePermissionDenied) {
					throw new Error("MicrophonePermissionsDenied");
				}
			})
			.finally(() => setIsAcquiringLocalTracks(false));
	}, [audioTrack, videoTrack, isAcquiringLocalTracks, appState.isMuted]);

	const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
  )[];

	return {
		localTracks,
		getLocalVideoTrack,
		getLocalAudioTrack,
		isAcquiringLocalTracks,
		removeLocalAudioTrack,
		removeLocalVideoTrack,
		getAudioAndVideoTracks
	};
}
