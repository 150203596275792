import { useEffect, useState } from "react";

type WindowSize = {
    width: number;
    height: number;
}

export const useWindowSize= (): WindowSize => {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight
		});
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		// Clean up the event listener on unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return windowSize;
}