import { useContext } from "react";
import { PlayerContext } from "../../components/PlayerProvider/PlayerProvider";
import { PlayerContextType } from "../../types/twilio-player";

export const usePlayerContext = (): PlayerContextType => {
	const context = useContext(PlayerContext);
	if (!context) {
		throw new Error("usePlayerContext must be used within a PlayerProvider");
	}
	return context;
}
