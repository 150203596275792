import { Participant } from "../Participant/Participant";
import { useMainParticipant } from "../../../hooks/useMainParticipant/useMainParticipant";
import { useParticipants } from "../../../hooks/useParticipants/useParticipants";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { useSelectedParticipant } from "../../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import { useScreenShareParticipant } from "../../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import { Box } from "@twilio-paste/box";
import { FC } from "react";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { ConnectionType } from "../../../core/appReducer";

export const ParticipantList: FC = () => {
	const { room } = useVideoContext();
	const { localParticipant } = room!;
	const { participants } = useParticipants();
	const [selectedParticipant, setSelectedParticipant] =
    useSelectedParticipant();
	const screenShareParticipant = useScreenShareParticipant();
	const mainParticipant = useMainParticipant();
	const { appState } = useAppState();
	// const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

	if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

	return (
		<Box position="relative" display="flex" flexWrap="nowrap" height="170px" maxHeight="200px" overflowX="auto">
			<Participant participant={localParticipant} isLocalParticipant={true} />
			{participants.map((participant) => {
				const isSelected = participant === selectedParticipant;
				const hideParticipant = participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
				return (
					<Participant
						key={participant.sid}
						participant={participant}
						isSelected={participant === selectedParticipant}
						onClick={() => setSelectedParticipant(participant)}
						hideParticipant={hideParticipant}
						videoOnly={appState.connectionType !== ConnectionType.WebRTC}
					/>
				);
			})}
		</Box>
	);
}