
import { Text } from "@twilio-paste/text";
import { Box } from "@twilio-paste/box";
import { FC } from "react";
import { useBookingContext } from "../../../hooks/useBookingContext/useBookingContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { ProcessNeutralIcon } from "@twilio-paste/icons/esm/ProcessNeutralIcon";
import { ProcessSuccessIcon } from "@twilio-paste/icons/esm/ProcessSuccessIcon";
import { useTranslation } from "react-i18next";

export const ConferenceAlert: FC = () => {
	const { t } = useTranslation();
	const { conference } = useBookingContext();
	const { appState } = useAppState();
	return (
		<Box width="100%">
			{(conference?.conferenceType === "mainRoom") && appState.isRoomOpen ?
				<Box display="flex" alignItems="center" columnGap="space10">
					<ProcessSuccessIcon
						color="colorTextIconError"
						decorative={false}
						title="warning"
						size="sizeIcon80" />
					<Box>
						<Text as="p" role="alert" aria-live="polite" fontSize="fontSize40">
							<b>{t("conference-ui-top-bar.title-live")}</b>
						</Text>
						<Text as="p" role="alert" aria-live="polite" fontSize="fontSize20">
							{t("conference-ui-top-bar.sub-title-live")}
						</Text>
					</Box>
				</Box>
				:
				<Box display="flex" alignItems="center" columnGap="space10">
					<ProcessNeutralIcon
						color="colorTextIconNeutral"
						decorative={false}
						title="neutral"
						size="sizeIcon80"/>
					<Box>
						<Text as="p" role="alert" aria-live="polite" fontSize="fontSize30">
							<b>{t("conference-ui-top-bar.title-pre-call")}</b>
						</Text>
						<Text as="p" role="alert" aria-live="polite" fontSize="fontSize20">
							{t("conference-ui-top-bar.sub-title-pre-call")}
						</Text>
					</Box>
				</Box>}
		</Box>
	);
};