import { useContext } from "react";
import { ConferencePlaybackContext } from "../../components/ConferencePlaybackProvider/ConferencePlaybackProvider";
import { ConferencePlaybackContextType } from "../../types/ciptex-sdk";

export const useConferencePlaybackContext = (): ConferencePlaybackContextType => {
	const context = useContext(ConferencePlaybackContext);
	if (!context) {
		throw new Error("useConferenceContext must be used within a ConferencePlaybackProvider");
	}
	return context;
}