import { FC } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useConnectionOptions } from "../../hooks/useConnectionOptions/useConnectionOptions";
import { VideoProvider } from "../VideoProvider";
import { PlayerProvider } from "../PlayerProvider/PlayerProvider";
import { ErrorDialog } from "../ErrorDialog/ErrorDialog";
import { ChatProvider } from "../ChatProvider/ChatProvider";
import { SyncProvider } from "../SyncProvider/SyncProvider";
import { ReactElementProps } from "../../types";
import { ConferenceControlProvider } from "../ConferenceControlProvider/ConferenceControlProvider";
import { ConferencePlaybackProvider } from "../ConferencePlaybackProvider/ConferencePlaybackProvider";
export const ConferenceProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const { error, setError } = useAppState();
	const connectionOptions = useConnectionOptions();

	return (
		<VideoProvider options={connectionOptions} onError={setError}>
			<ErrorDialog dismissError={() => setError(null)} error={error} />
			<PlayerProvider>
				<ChatProvider>
					<SyncProvider>
						<ConferenceControlProvider>
							<ConferencePlaybackProvider>
								{children}
							</ConferencePlaybackProvider>
						</ConferenceControlProvider>
					</SyncProvider>
				</ChatProvider>
			</PlayerProvider>
		</VideoProvider>
	);
}