import { useEffect, useState } from "react";
import { Player as TwilioPlayer } from "@twilio/live-player-sdk";
import { usePlayerContext } from "../usePlayerContext/usePlayerContext";
import { useConferenceControlContext } from "../useConferenceControlContext/useConferenceControlContext";
import { CiptexViewerUpdatePlayerStatusEnum } from "@mmc/conferencing-conference-control-client/src/models/CiptexViewerUpdate";

export const usePlayerState = (): TwilioPlayer.State | undefined => {
	const { player } = usePlayerContext();
	const { viewerUpdate } = useConferenceControlContext();
	const [state, setState] = useState<TwilioPlayer.State>();

	useEffect(() => {
		if (player) {
			const setPlayerState = () => {
				viewerUpdate(player.state as unknown as CiptexViewerUpdatePlayerStatusEnum)
				setState(player.state as TwilioPlayer.State);
			}
			setPlayerState();

			player.on(TwilioPlayer.Event.StateChanged, setPlayerState);

			return () => {
				player.off(TwilioPlayer.Event.StateChanged, setPlayerState);
			};
		}
	}, [player, viewerUpdate]);

	return state;
}
