import { LocalVideoTrack, Room } from "twilio-video";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ENABLE_VIDEO, SELECTED_BACKGROUND_SETTINGS_KEY } from "../../../constants";
import { GaussianBlurBackgroundProcessor, ImageFit, VirtualBackgroundProcessor, isSupported } from "@twilio/video-processors";
import { Thumbnail } from "../../BackgroundThumbnail/BackgroundThumbnail";

export interface BackgroundSettings {
	type: Thumbnail;
	index?: number;
}

const imageNames: string[] = [];

const images: any[] = [];

const rawImagePaths: any[] = [];

const imageElements = new Map();

const getImage = (index: number): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		if (imageElements.has(index)) {
			return resolve(imageElements.get(index));
		}
		const img = new Image();
		img.onload = () => {
			imageElements.set(index, img);
			resolve(img);
		};
		img.onerror = reject;
		img.src = rawImagePaths[index];
	});
};

export const backgroundConfig = {
	imageNames,
	images
};

const virtualBackgroundAssets = "/virtualbackground";
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

export const useBackgroundSettings = (videoTrack: LocalVideoTrack | undefined, room?: Room | null):
	readonly [BackgroundSettings, Dispatch<SetStateAction<BackgroundSettings>>] => {
	const [backgroundSettings, setBackgroundSettings] = useState<BackgroundSettings>(() => {
		const localStorageSettings = window.localStorage.getItem(SELECTED_BACKGROUND_SETTINGS_KEY);
		return localStorageSettings && ENABLE_VIDEO ? JSON.parse(localStorageSettings) : { type: "none", index: 0 };
	});

	const removeProcessor = useCallback(() => {
		if (videoTrack && videoTrack.processor) {
			videoTrack.removeProcessor(videoTrack.processor);
		}
	}, [videoTrack]);

	const addProcessor = useCallback(
		(processor: GaussianBlurBackgroundProcessor | VirtualBackgroundProcessor) => {
			if (!videoTrack || videoTrack.processor === processor) {
				return;
			}
			removeProcessor();
			videoTrack.addProcessor(processor);
		},
		[videoTrack, removeProcessor]
	);

	useEffect(() => {
		if (!isSupported || !ENABLE_VIDEO) {
			return;
		}
		// make sure localParticipant has joined room before applying video processors
		// this ensures that the video processors are not applied on the LocalVideoPreview
		const handleProcessorChange = async () => {
			if (!blurProcessor) {
				blurProcessor = new GaussianBlurBackgroundProcessor({
					assetsPath: virtualBackgroundAssets
				});
				await blurProcessor.loadModel();
			}
			if (!virtualBackgroundProcessor) {
				virtualBackgroundProcessor = new VirtualBackgroundProcessor({
					assetsPath: virtualBackgroundAssets,
					backgroundImage: await getImage(0),
					fitType: ImageFit.Cover
				});
				await virtualBackgroundProcessor.loadModel();
			}
			if (!room?.localParticipant) {
				return;
			}

			if (backgroundSettings.type === "blur") {
				addProcessor(blurProcessor);
			} else if (backgroundSettings.type === "image" && typeof backgroundSettings.index === "number") {
				virtualBackgroundProcessor.backgroundImage = await getImage(backgroundSettings.index);
				addProcessor(virtualBackgroundProcessor);
			} else {
				removeProcessor();
			}
		};
		handleProcessorChange();
		window.localStorage.setItem(SELECTED_BACKGROUND_SETTINGS_KEY, JSON.stringify(backgroundSettings));
	}, [backgroundSettings, videoTrack, room, addProcessor, removeProcessor]);

	return [backgroundSettings, setBackgroundSettings] as const;
}
