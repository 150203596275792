import { useEffect, useState } from "react";
import { Paginator, SyncMapItem } from "twilio-sync";
import { useSyncContext } from "../useSyncContext/useSyncContext";

export const useViewersMap = (): SyncMapItem[] => {
	const { viewersMap } = useSyncContext();
	const [viewers, setViewers] = useState<SyncMapItem[]>([]);

	const pageHandler = (paginator: Paginator<SyncMapItem>): any => {
		setViewers(paginator.items);
		return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
	};

	const getDuplicateViewers: (viewers: SyncMapItem[]) => SyncMapItem[] = (viewers) => {
		const objectCount: Record<string, number> = {};
		const duplicates: SyncMapItem[] = [];

		for (const viewer of viewers) {
			if (objectCount[viewer.key]) {
				if (objectCount[viewer.key] === 1) {
					duplicates.push(viewer);
				}
				objectCount[viewer.key] = objectCount[viewer.key] + 1;
			} else {
				objectCount[viewer.key] = 1;
			}
		}

		return duplicates;
	};

	useEffect(() => {
		if (viewersMap) {
			// Sets the list on load. Limiting to first 100 viewers who are raising their hand
			viewersMap.getItems({ pageSize: 100 }).then(pageHandler);

			const handleItemAdded = (args: any) => {
				console.log("ViewersMap - Item Added", args, args?.item?.data);

				setViewers(prevViewers => [args.item, ...prevViewers]);

				const duplicateViewers = getDuplicateViewers([args.item, ...viewers]);

				if (duplicateViewers.length) {
					console.error("ViewersMap - Duplicate viewers detected", duplicateViewers.map(viewer => viewer.key));
				}
			};

			const handleItemRemoved = (args: any) => {
				console.log("ViewersMap - Item Removed", args.key);

				setViewers(prevViewers => prevViewers.filter(i => i.key !== args.key));
			};

			const handleItemUpdated = (args: any) => {
				try {
					console.log("ViewersMap - Item Updated", args, args?.item?.data);

					setViewers(prevViewers => [args.item, ...prevViewers.filter(i => i.key !== args.item.key)]);
				} catch (error) {
					console.error(error);
				}

			};

			viewersMap.on("itemAdded", handleItemAdded);
			viewersMap.on("itemRemoved", handleItemRemoved);
			viewersMap.on("itemUpdated", handleItemUpdated);

			return () => {
				viewersMap.off("itemAdded", handleItemAdded);
				viewersMap.off("itemRemoved", handleItemRemoved);
				viewersMap.off("itemUpdated", handleItemUpdated);
			};
		}
		// TODO: Fix linting - pageHandler should be wrapped with useCallback and added to the list of dependencies
		//  viewers as well should be added in the deps, but doing all this things causes infinitive re-rendering as it creates circle dependencies
		// eslint-disable-next-line react-hooks/exhaustive-deps -- including pageHandler and viewers in the list of dependencies causes infinite re-rendering
	}, [viewersMap]);

	return viewers;
}
