import { ChangeEvent, FormEvent, FC, useEffect } from "react";
import { ActivePreJoinScreen } from "../../../core/appReducer";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { Heading } from "@twilio-paste/heading";
import { Button } from "@twilio-paste/button";
import { Input } from "@twilio-paste/input";
import { Label } from "@twilio-paste/label";
import { HelpText } from "@twilio-paste/help-text";
import { Flex } from "@twilio-paste/flex";
import { Box } from "@twilio-paste/box";
import { JoinScreenProps } from "../../../types";
import { Role } from "@mmc/conferencing-booking-client";

export const ParticipantNameScreen: FC<JoinScreenProps> = ({ state, dispatch, connect }: JoinScreenProps) => {
	const { appState } = useAppState();

	useEffect(() => {
		if (appState.participantIdentity && appState.participantIdentity !== "") {
			dispatch({ type: "set-active-prejoin-screen", activePreJoinScreen: ActivePreJoinScreen.DeviceSelectionScreen });
		}
		// TODO: Linter Suggestion - before adding to deps If 'dispatch' changes too often, find the parent component that defines it and wrap that definition in useCallback  react-hooks/exhaustive-deps
	}, [appState.participantIdentity, dispatch]);

	const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch({ type: "set-participant-identity", participantIdentity: event.target.value });
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (appState.participantType === Role.Viewer) {
			connect();
		}
		else {
			dispatch({ type: "set-active-prejoin-screen", activePreJoinScreen: ActivePreJoinScreen.DeviceSelectionScreen });
		}
	};

	return (
		<form onSubmit={handleSubmit} style={{ width: "100%", height: "100%" }}>
			<Flex width="100%" vertical height="100%">
				<Heading as="h4" variant="heading40">{"What's your full name?"}</Heading>
				<Flex grow shrink vAlignContent="center" width="100%">
					<Box width="100%">
						<Label htmlFor="input-user-name" required>Full Name</Label>
						<Input
							aria-describedby="full_name_help_text"
							id="input-user-name"
							type="text"
							required
							value={state.participantIdentity}
							onChange={handleNameChange}
						/>
						<HelpText id="full_name_help_text">Whats your full name?</HelpText>
					</Box>
				</Flex>
				<Flex width="100%" hAlignContent="right">
					<Button variant="primary" type="submit" disabled={!state.participantIdentity}>Continue</Button>
				</Flex>
			</Flex>
		</form>
	);
}
