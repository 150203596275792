import { isPlainObject } from "is-plain-object";
import { IDENTITY_SEPERATOR, PHONEOUT_PARTICIPANT_PREFIX, PHONE_PARTICIPANT_PREFIX } from "../constants";
import { DeviceInfo } from "../types/twilio-video";

export const isMobile: boolean = (() => {
	if (typeof navigator === "undefined" || typeof navigator.userAgent !== "string") {
		return false;
	}
	return /Mobile/.test(navigator.userAgent);
})();

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
	if (!isPlainObject(obj)) return obj;

	const target: { [name: string]: any } = {};

	for (const key in obj) {
		const val = obj[key];
		if (typeof val !== "undefined") {
			target[key] = removeUndefineds(val);
		}
	}

	return target as T;
}

export const isPhone = (identity: string): boolean => identity.startsWith(`${PHONE_PARTICIPANT_PREFIX}-`) || identity.startsWith(`${PHONEOUT_PARTICIPANT_PREFIX}-`);

export const stripPhone = (identity: string): string => identity.replace(`${PHONE_PARTICIPANT_PREFIX}-`, "").replace(`${PHONEOUT_PARTICIPANT_PREFIX}-`, "");

export const splitIdentity = (identity: string): string => {
	const split = identity.split(IDENTITY_SEPERATOR);
	return split.length > 1 ? split[1] : split[0];
}

export const getDeviceInfo = async (): Promise<DeviceInfo> => {
	const devices = await navigator.mediaDevices.enumerateDevices();

	const res: DeviceInfo = {
		audioInputDevices: devices.filter(device => device.kind === "audioinput"),
		videoInputDevices: devices.filter(device => device.kind === "videoinput"),
		audioOutputDevices: devices.filter(device => device.kind === "audiooutput"),
		hasAudioInputDevices: devices.some(device => device.kind === "audioinput"),
		hasVideoInputDevices: devices.some(device => device.kind === "videoinput")
	};

	return res;
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export const isPermissionDenied = async (name: PermissionName): Promise<boolean> => {
	if (navigator.permissions) {
		try {
			const result = await navigator.permissions.query({ name });
			return result.state === "denied";
		} catch {
			return false;
		}
	} else {
		return false;
	}
}

export const secondsToHms = (d: number) => {
	d = Number(d);
	const h = Math.floor(d / 3600);
	const m = Math.floor(d % 3600 / 60);

	const hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
	const mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
	return hDisplay + (mDisplay && hDisplay ? ", " : "") + mDisplay;
};

// seconds to timestamp
export const secondsToTimestamp = (seconds: number) => {
	const date = new Date(0);
	date.setSeconds(seconds);
	const timeString = date.toISOString().substr(11, 8);
	return timeString;
}

