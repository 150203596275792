import { FC, useState, useCallback } from "react";
import { Button } from "@twilio-paste/button";
import { useVideoContext } from "../../../hooks/useVideoContext/useVideoContext";
import { useConferenceControlContext } from "../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon"
import { ButtonProps } from "../../../types";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from "@twilio-paste/core/modal";
import { Paragraph } from "@twilio-paste/core/paragraph";
import { Input } from "@twilio-paste/core/input";
import { HelpText, Label } from "@twilio-paste/core";
import { useChatContext } from "../../../hooks/useChatContext/useChatContext";
import { useSyncContext } from "../../../hooks/useSyncContext/useSyncContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const EndEventButton: FC<ButtonProps> = (props: ButtonProps) => {
	const { t } = useTranslation();
	const { room } = useVideoContext();
	const { disconnect: chatDisconnect } = useChatContext();
	const { disconnect: syncDisconnect } = useSyncContext();
	const { appState, appDispatch } = useAppState();
	const { endEvent } = useConferenceControlContext();
	const [error, setError] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);
	const [text, setText] = useState("");
	const modalHeadingID = useUID();
	const history = useNavigate();

	const disconnect = async (): Promise<void> => {
			room!.emit("setPreventAutomaticJoinStreamAsViewer");
			room!.disconnect();
			chatDisconnect();
			syncDisconnect();

			appDispatch({ type: "reset-state" });
			appDispatch({ type: "set-is-loading", isLoading: true });

			history("/ended", { replace: true });

			await endEvent();
			appDispatch({ type: "reset-state" });
	}

	const requiredTextInput = t("conference-ui-bottom-bar.end-conf-text-input");

	const checkString = useCallback(() => {
		if (text.toUpperCase() === requiredTextInput.toUpperCase()) {
			setError(false);
			return disconnect();
		}
		return setError(true);
		// TODO: Fix linting - disconnect() should be wrapped in useCallback and added to the list of dependencies, or other solution should be found to fix the linter issue
		// eslint-disable-next-line react-hooks/exhaustive-deps -- to include disconnect() in the list of dependencies first it should be wrapped in useCallback
	}, [requiredTextInput, text]);

	return (
		<><Button
			onClick={handleOpen}
			variant={props.menuButton ? "destructive_secondary" : "destructive"}
			disabled={appState.isRoomOpen}
			data-cy-disconnect>
			<CloseIcon decorative={true} /> {t("conference-ui-bottom-bar.end-conf-button")}
		</Button><Modal ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={handleClose} size="default">
			<ModalHeader>
				<ModalHeading as="h3" id={modalHeadingID}>
					{t("conference-ui-bottom-bar.end-conf-button")}
				</ModalHeading>
			</ModalHeader>
			<ModalBody>
				<Paragraph>{t("conference-ui-bottom-bar.end-conf-modal-text")}</Paragraph>
				<Label htmlFor="end_check" required>{t("conference-ui-bottom-bar.end-conf-modal-sub-text", { prompt: requiredTextInput })}</Label>
				<Input aria-describedby="end_check" id="end_check" name="end_check" type="text" onChange={(e) => setText(e.target.value)} placeholder={requiredTextInput}  required/>
				{error && <HelpText id="end_check_help_text" variant="error">{t("conference-ui-bottom-bar.end-conf-modal-error", { prompt: requiredTextInput })}</HelpText>}
			</ModalBody>
			<ModalFooter>
				<ModalFooterActions>
					<Button variant="secondary" onClick={handleClose}>
						{t("conference-ui-bottom-bar.cancel-button")}
					</Button>
					<Button variant="destructive" onClick={checkString} >{t("conference-ui-bottom-bar.end-conf-button")}</Button>
				</ModalFooterActions>
			</ModalFooter>
		</Modal></>
	);
}
