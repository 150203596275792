import { useEffect, useState } from "react";
import { Paginator, SyncMapItem } from "twilio-sync";
import { useSyncContext } from "../useSyncContext/useSyncContext";

export const useSpecialMap = (): SyncMapItem[] => {
	const { specialMap } = useSyncContext();
	const [specials, setSpecials] = useState<SyncMapItem[]>([]);

	const pageHandler = (paginator: Paginator<SyncMapItem>): any => {
		setSpecials(paginator.items);
		return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
	};

	useEffect(() => {
		if (specialMap) {
			specialMap.getItems({ pageSize: 100 }).then(pageHandler);

			const handleItemAdded = (args: any) => {
				setSpecials(prevSpecials => [args.item, ...prevSpecials]);
			};

			const handleItemRemoved = (args: any) => {
				setSpecials(prevSpecials => prevSpecials.filter(i => i.key !== args.key));
			};

			const handleItemUpdated = (args: any) => {
				try {
					setSpecials(prevSpecials => [args.item, ...prevSpecials.filter(i => i.key !== args.item.key)]);
				} catch (error) {
					console.error(error);
				}

			};

			specialMap.on("itemAdded", handleItemAdded);
			specialMap.on("itemRemoved", handleItemRemoved);
			specialMap.on("itemUpdated", handleItemUpdated);

			return () => {
				specialMap.off("itemAdded", handleItemAdded);
				specialMap.off("itemRemoved", handleItemRemoved);
				specialMap.off("itemUpdated", handleItemUpdated);
			};
		}
		// TODO: Fix linting - pageHandler should be wrapped in useCallback and added to the list of dependencies, or other solution should be found to fix the linter issue
		// eslint-disable-next-line react-hooks/exhaustive-deps -- to include pageHandler() in the list of dependencies first it should be wrapped in useCallback
	}, [specialMap]);

	return specials;
}
