import { Anchor } from "@twilio-paste/core/anchor";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { FC } from "react";
import { Booking } from "@mmc/conferencing-booking-client";

interface LeaderType {
  booking: Booking | undefined;
}

export const LeaderInfo: FC<LeaderType> = ({ booking }) => {
	return (
		<Box as="section" aria-labelledby="leader-info-heading">
			<Box
				display="flex"
				flexDirection="row"
				flexWrap="wrap"
				flex="9"
				columnGap="space30"
				rowGap="space30"
				alignItems="center"
				role="region"
				aria-label="Leader Details"
			>
				{booking?.leader?.firstName && (
					<Text as="p" role="definition" aria-label="First Name">
						{booking?.leader?.firstName}
					</Text>
				)}
				{booking?.leader?.lastName && (
					<Text as="p" role="definition" aria-label="Last Name">
						{booking?.leader?.lastName}
					</Text>
				)}
				{booking?.leader?.phone && (
					<Text as="p" role="definition" aria-label="Phone Number">
						{booking?.leader?.phone}
					</Text>
				)}
				{booking?.leader?.company && (
					<Text as="p" role="definition" aria-label="Company">
						{booking?.leader?.company}
					</Text>
				)}
				{booking?.leader?.email && (
					<Anchor
						target="_blank"
						href={`mailto:${booking?.leader?.email}`}
						aria-label="Email Address"
					>
						{booking?.leader?.email}
					</Anchor>
				)}
			</Box>
		</Box>
	);
};