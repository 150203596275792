import { FC, useEffect } from "react";
import { ReactElementProps } from "../../types";
import { useAppState } from "../../hooks/useAppState/useAppState";
import * as Sentry from "@sentry/react";
import { SENTRY_ENVIRONMENT } from "../../constants";

const originalConsoleError = console.error;
console.error = (...args) => {
	SENTRY_ENVIRONMENT === "local" ? originalConsoleError(...args) : Sentry.captureException(args[0]);
};

export const SentryProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const { appState } = useAppState();

	useEffect(() => {
		if (!appState) {
			return;
		}

		console.log("SentryProvider - Updating User, context, and tags from app state");

		Sentry.setUser({
			id: appState.participantId,
			role: appState.participantType
		});

		Sentry.setTags({
			bookingId: appState.bookingId,
			conferenceId: appState.conferenceId,
			accountSid: appState.accountSid,
			roomSid: appState.roomSid,
			conversationSid: appState.conversationSid
		});

		Sentry.setContext("room-status", {
			open: appState.isRoomOpen,
			streaming: appState.isStreamStreaming
		});
	}, [appState]);

	return <>{children}</>;
}