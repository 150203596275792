import { Anchor } from "@twilio-paste/anchor";
import linkify from "linkify-it";
import { LocalParticipant, RemoteParticipant } from "./TextMessage.styles";
import { Box } from "@twilio-paste/box";
import { FC } from "react";


interface TextMessageProps {
  body: string;
  isLocalParticipant: boolean;
}

const addLinks = (text: string): string | string[] => {
	const matches = linkify().match(text);
	if (!matches) return text;

	const results = [];
	let lastIndex = 0;

	matches.forEach(({ index, url, text, lastIndex: lastIndexLocal }: any, i: any) => {
		results.push(text.slice(lastIndex, index));
		results.push(
			<Anchor target="_blank" showExternal={true} rel="noreferrer" href={url} key={i}>
				{text}
			</Anchor>
		);
		lastIndex = lastIndexLocal;
	});

	results.push(text.slice(lastIndex, text.length));

	return results;
}

export const TextMessage: FC<TextMessageProps> = ({ body, isLocalParticipant }: TextMessageProps) => {

	return (
		<Box>
			<Box display="flex" justifyContent="flex-end">
				{isLocalParticipant && <LocalParticipant>
					<Box>{addLinks(body)}</Box>
				</LocalParticipant>}
			</Box>
			<Box>
				{!isLocalParticipant && <RemoteParticipant>
					<Box>{addLinks(body)}</Box>
				</RemoteParticipant>}
			</Box>
		</Box>
	);
}
