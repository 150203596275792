import { Navigate, useLocation } from "react-router-dom";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { ReactElementProps } from "../../types";

export const PrivateRoute = ({ children }: ReactElementProps) => {
	const { appState, isAuthReady } = useAppState();
	const { pathname } = useLocation();

	if ((!appState.conferenceId || !appState.participantId) && !isAuthReady) {
		return null;
	}

	return (appState.conferenceId && appState.participantId) ? children : <Navigate to={ `/login?redirect=${pathname}` } />;
}
