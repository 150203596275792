// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
// eslint-disable-next-line default-param-last
export const enhanceMessage = (message = "", code?: number): string => {
	switch (code) {
	case 20101: // Invalid token error
		return message + ". Please make sure you are using the correct credentials.";
	default:
		return message;
	}
}