import { AvatarIcon } from "../../../../icons/AvatarIcon";
import { LocalAudioLevelIndicator } from "../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator";
import { LocalVideoTrack } from "twilio-video";
import { VideoTrack } from "../../../VideoTrack/VideoTrack";
import { useVideoContext } from "../../../../hooks/useVideoContext/useVideoContext";
import { Box } from "@twilio-paste/box";
import { Flex } from "@twilio-paste/flex";
import { ENABLE_VIDEO } from "../../../../constants";
import { FC } from "react";

interface LocalVideoPreviewProps {
	identity: string;
}

export const LocalVideoPreview: FC<LocalVideoPreviewProps> = ({ identity }: LocalVideoPreviewProps) => {
	const { localTracks } = useVideoContext();

	const videoTrack = localTracks.find(track => track.name.includes("camera")) as LocalVideoTrack;

	return (
		<Box position="relative" width="100%" height="100%">
			{ENABLE_VIDEO && videoTrack ? (<VideoTrack track={videoTrack} isLocal />) : (<Box>
				{!ENABLE_VIDEO ? <Box></Box> :
					<Flex vAlignContent="center" hAlignContent="center" width="100%" height="100%" paddingY="space100">
						<AvatarIcon />
					</Flex>}
			</Box>)}
			< Box position="absolute" bottom={1} zIndex="zIndex10" style={{ background: "rgba(46, 46, 46)", color: "white", padding: "0.3em 0.5em 0.3em 0.3em", borderRadius: "5px" }}>
				<Flex vAlignContent="center">
					<LocalAudioLevelIndicator />
					<span>{identity}</span>
				</Flex>
			</Box>
		</Box >
	);
}
