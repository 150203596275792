import { FC, useEffect, useState } from "react";
import { Select, Option } from "@twilio-paste/core";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useConferenceControlContext } from "../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { Priority } from "../../../types";
import { useTranslation } from "react-i18next";

export const CustomSelect = styled(Select)(css`
padding: 4px 4px 0.25rem 4px !important;
margin: -0.125rem 0 1px 0 !important;
line-height: 1rem !important;
`);

interface PriorityCounterProps {
   item: any;
   setPriority: (value: number) => void;
}

interface PriorityOption {
	label: string;
	value: Priority;
}

export const PriorityCounter: FC<PriorityCounterProps> = ({ item, setPriority }: PriorityCounterProps) => {
	const data = item.data as any;
	const { updatePriority } = useConferenceControlContext();
	const [selectedItem, setSelectedItem] = useState(Priority.Standard);
	const { t } = useTranslation();

	const priorityOptions: PriorityOption[] = [
		{ label: t("conference-ui.paticipants-tab.qa-priority.no-qa"), value: Priority.NoQA },
		{ label: t("conference-ui.paticipants-tab.qa-priority.standard"), value: Priority.Standard },
		{ label: t("conference-ui.paticipants-tab.qa-priority.priority-1"), value: Priority.Priority1 },
		{ label: t("conference-ui.paticipants-tab.qa-priority.priority-2"), value: Priority.Priority2 },
		{ label: t("conference-ui.paticipants-tab.qa-priority.priority-3"), value: Priority.Priority3 },
		{ label: t("conference-ui.paticipants-tab.qa-priority.priority-4"), value: Priority.Priority4 },
		{ label: t("conference-ui.paticipants-tab.qa-priority.priority-5"), value: Priority.Priority5 }
	];

	const updateViewerPriority = async (value: number) => {
		try {
			await updatePriority(item.key, value);
		} catch (err) {
			console.error(`Failed updating priority for "${data.userIdentity}"`, err);
		}
	}

	useEffect(() => {
		setSelectedItem(data.priority);
		setPriority(data.priority);
	}, [data, setPriority]);


	return (
		<CustomSelect value={selectedItem ? selectedItem.toString(): "0"} onChange={e => {
			const selectedItem  = parseInt(e.target.value);
			const value = selectedItem;
			setPriority(value);
			setSelectedItem(parseInt(e.target.value));
			updateViewerPriority(value);
		}}>
			{priorityOptions.map((option) => (
				<Option key={option.value} defaultValue={selectedItem ? selectedItem.toString(): "0"} value={option.value.toString()}>
					{option.label}
				</Option>
			))}
		</CustomSelect>
	);
}
