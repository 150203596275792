import { FC } from "react";
import { splitIdentity } from "../../../../../core/utils";
import { MessageInfoContainer } from "./MessageInfo.styles"

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
}
export const MessageInfo: FC<MessageInfoProps> = ({ author, dateCreated, isLocalParticipant }: MessageInfoProps) => {
	return (
		<MessageInfoContainer>
			<div>{isLocalParticipant ? `${splitIdentity(author)} (You)` : splitIdentity(author)}</div>
			<div>{dateCreated}</div>
		</MessageInfoContainer>
	);
}
