import { Box } from "@twilio-paste/box";
import { ToggleAudioButton } from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import { ToggleVideoButton } from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import { ToggleRaiseHandButton } from "../../Buttons/ToggleRaiseHandButton/ToggleRaiseHandButton";
import { ToggleStreamButton } from "../../Buttons/ToggleStreamButton/ToggleStreamButton";
import { EndEventButton } from "../../Buttons/EndEventButton/EndEventButton";
import { SettingsButton } from "../../Buttons/SettingsButton/SettingsButton";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { ENABLE_VIDEO } from "../../../constants";
import { FC } from "react";
import { LeaveEventButton } from "../../Buttons/LeaveEventButton/LeaveEventButton";
import { EncoderConnectionStatus } from "../../EncoderConnectionStatus/EncoderConnectionStatus";
import { Role } from "@mmc/conferencing-booking-client";

export const ConferenceMenuBar: FC = () => {
	const { appState } = useAppState();

	const canRaiseHand = appState.participantType === Role.Viewer;
	const isAdminOrHost = (appState.participantType === Role.Admin || appState.participantType === Role.Host);

	const canToggleStream = isAdminOrHost;
	const canEndEvent = isAdminOrHost;
	const canShowEncoderStatus = isAdminOrHost;

	return (
		<Box backgroundColor="colorBackgroundBody" display="flex" justifyContent="center" alignItems="center" flexDirection="row" height="fill-available" paddingY="space30" columnGap="space60" flexWrap="nowrap">
			<Box>
				<ToggleAudioButton menuButton={true} />
			</Box>
			{ENABLE_VIDEO && <Box>
				<ToggleVideoButton menuButton={true} />
			</Box>}
			{canRaiseHand && <Box>
				<ToggleRaiseHandButton menuButton={true} />
			</Box>}
			<Box>
				<SettingsButton />
			</Box>
			{canToggleStream &&
				<Box>
					<ToggleStreamButton menuButton={true} />
				</Box>}
			<Box>
				<LeaveEventButton menuButton={true} />
			</Box>
			{canEndEvent &&
			<Box>
				<EndEventButton/>
			</Box>}
			{canShowEncoderStatus &&
			<Box marginLeft="space40" paddingLeft="space40" borderLeftWidth="borderWidth10" borderLeftColor="colorBorderDecorative10Weaker" borderLeftStyle="solid">
				<EncoderConnectionStatus />
			</Box>}
		</Box>
	);
};
