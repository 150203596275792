import { useContext } from "react";
import { ChatContext } from "../../components/ChatProvider/ChatProvider";
import { ChatContextType } from "../../types/twilio-conversations";

export const useChatContext = (): ChatContextType => {
	const context = useContext(ChatContext);
	if (!context) {
		throw new Error("useChatContext must be used within a ChatProvider");
	}
	return context;
}
