import { AudioInputList } from "./AudioInputList/AudioInputList";
import { AudioOutputList } from "./AudioOutputList/AudioOutputList";
import { VideoInputList } from "./VideoInputList/VideoInputList";
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalFooterActions,
	ModalHeader,
	ModalHeading
} from "@twilio-paste/core/modal";
import { Text } from "@twilio-paste/text";
import { Button } from "@twilio-paste/button";
import { Separator } from "@twilio-paste/separator";
import { ENABLE_VIDEO } from "../../constants";
import { DialogProps } from "../../types";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const DeviceSelectionDialog: FC<DialogProps> = ({ open, onClose }: DialogProps) => {
	const { t } = useTranslation();

	return (
		<Modal ariaLabelledby={t("choose-your-adventure-page.tab1.audio-settings-modal.title")} isOpen={open} onDismiss={onClose} size="default" role="dialog">
			<ModalHeader>
				<ModalHeading as="h3">
					{t("choose-your-adventure-page.tab1.audio-settings-modal.title")}
				</ModalHeading>
			</ModalHeader>
			<ModalBody >
				{ENABLE_VIDEO && <>
					<Text as="h6" >
						{t("choose-your-adventure-page.tab1.audio-settings-modal.video-title")}
					</Text>
					<VideoInputList />
					<Separator orientation="horizontal" verticalSpacing="space50" />
				</>}
				<Text as="h6" marginBottom="space60">
					{t("choose-your-adventure-page.tab1.audio-settings-modal.audio-title")}
				</Text>
				<AudioInputList />
				<AudioOutputList />
			</ModalBody>
			<ModalFooter>
				<ModalFooterActions>
					<Button
						variant="primary"
						onClick={onClose}
					>
						{t("choose-your-adventure-page.tab1.audio-settings-modal.done-button")}
					</Button>
				</ModalFooterActions>
			</ModalFooter>
		</Modal>
	);
}
