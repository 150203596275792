import { FC, useEffect, useState } from "react";
import { Box } from "@twilio-paste/core";
import { ConnectivityOfflineIcon } from "@twilio-paste/icons/esm/ConnectivityOfflineIcon";
import { ConnectivityAvailableIcon } from "@twilio-paste/icons/esm/ConnectivityAvailableIcon";
import { useSpecialMap } from "../../hooks/useSpecialMap/useSpecialMap";
import { ID3AS_ENCODER_PARTICIPANT_PREFIX } from "../../constants";
import { Trans } from "react-i18next";
import { t } from "i18next";

const EncoderNotConnected: FC = () => (
	<Box display="flex" alignItems="center" columnGap="space30">
		<ConnectivityOfflineIcon decorative={false} title={t("conference-ui-bottom-bar.encoder-not-connected")} color="colorTextWarning" size="sizeIcon50" />
		<Trans i18nKey="conference-ui-bottom-bar.encoder-not-connected">Encoder not connected</Trans>
	</Box>
)

const EncoderConnected: FC = () => (
	<Box display="flex" alignItems="center" columnGap="space30">
		<ConnectivityAvailableIcon decorative={false} title={t("conference-ui-bottom-bar.encoder-connected")} color="colorTextSuccess" size="sizeIcon50" />
		<Trans i18nKey="conference-ui-bottom-bar.encoder-connected">Encoder connected</Trans>
	</Box>
)

export const EncoderConnectionStatus: FC = () => {
	const specialMap = useSpecialMap();
	const [isEncoderConnected, setIsEncoderConnected] = useState<boolean>(false);

	useEffect(() => {
		const encoders = specialMap.filter((p) => p.key?.startsWith(ID3AS_ENCODER_PARTICIPANT_PREFIX));

		setIsEncoderConnected(encoders.length >= 1);
	}, [specialMap]);

	if (isEncoderConnected) {
		return <EncoderConnected />;
	} else {
		return <EncoderNotConnected />;
	}
}
