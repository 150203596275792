import { FC, useCallback } from "react";
import { useAppState } from "../../../hooks/useAppState/useAppState";
import { Button } from "@twilio-paste/button";
import { ChevronDoubleLeftIcon } from "@twilio-paste/icons/esm/ChevronDoubleLeftIcon";
import { ChevronDoubleRightIcon } from "@twilio-paste/icons/esm/ChevronDoubleRightIcon";
import { ButtonProps } from "../../../types";

export const TogglePanelButton: FC<ButtonProps> = (props: ButtonProps) => {
	const { appState, appDispatch } = useAppState();

	const handlePanelToggle = useCallback(() => {
		appDispatch({ type: "set-is-participant-window-open", isParticipantWindowOpen: !appState.isParticipantWindowOpen });
	}, [appState.isParticipantWindowOpen, appDispatch]);

	return (
		<Button
			variant={props.menuButton ? "reset" : "secondary"}
			onClick={handlePanelToggle}
			data-cy-panel-toggle
		>
			{appState.isParticipantWindowOpen ? <ChevronDoubleRightIcon decorative={false} title="Close Panel" size="sizeIcon20" /> : <ChevronDoubleLeftIcon decorative={false} title="Open Panel" size="sizeIcon20" />}
		</Button>
	);
}
